import { Grid, Select, MenuItem, InputLabel } from '@mui/material';
import Table from 'components/Table';
import { ROLES, ROLES_PRECEDENCE } from 'constant/enums';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { authPostRequest } from 'Api/api';
import { style } from 'constant/constants';
import ErrorForSelect from 'components/ErrorForSelect';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { isDateExpired, tableOptionFilter, columOptions } from 'utils/utils';
import CustomButton from 'components/Button';
import Text from 'components/Text';
import SuccessError from 'components/SuccessError';
import Loader from 'components/Loader';
import ConfirmationBox from 'components/ConfirmationBox';

const columns = [
  {
    name: 'firstName',
    label: 'First Name',
    options: {
      ...columOptions,
      display: false
    }
  },

  {
    name: 'lastName',
    label: 'Last Name',
    options: {
      ...columOptions,
      display: false
    }
  },
  {
    name: 'fullName',
    label: 'Name',
    options: {
      ...columOptions,
      customBodyRender: (_, tableMeta) => tableMeta.rowData[1] + ' ' + tableMeta.rowData[0]
    }
  },

  {
    name: 'email',
    label: 'Email',
    options: columOptions
  },

  {
    name: 'role',
    label: 'Role',
    options: {
      ...columOptions,
      customBodyRender: (value) => ROLES[value]
    }
  }
];

const optionsForSelectedRows = {
  ...tableOptionFilter,
  selectableRows: true,
  selectToolbarPlacement: 'none'
};

const AssignAssessment = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsTable, setSelectedRowsTable] = useState([]);
  const [selectIndex, setSelectIndex] = useState([]);
  const [selectIndexOption, setSelectIndexOption] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [moveRows, setMoveRows] = useState([]);
  const [assessmentToBeAssigned, setAssessmentToBeAssigned] = useState({
    id: '',
    name: ''
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [targeValue, setTargetValue] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [disableAssignBtn, setDisableAssignBtn] = useState(true);
  const [disableForwardBtn, setDisableForwardBtn] = useState(false);

  const { userData, publishAssessments, allUsers } = useSelector((store) => store.auth);
  const filteredPublishedAssessments = publishAssessments.filter(
    (ass) => !isDateExpired(ass.validUntil)
  );

  //To handle assessment dropdown.
  const onChangeHandler = async (e) => {
    if (moveRows.length > 0) {
      setShowAlert(true);
      setTargetValue(e.target.value);
    } else {
      setSelectIndex([]);
      setSelectedRows([]);
      setAssessmentToBeAssigned((prevState) => {
        return { ...prevState, name: e.target.value };
      });
    }

    const id = filteredPublishedAssessments
      .filter((ass) => ass.name === e.target.value)
      .map((ass) => ass.assessmentId);

    setAssessmentToBeAssigned((prevState) => {
      return { ...prevState, id: id[0] };
    });

    handleError(e.target.name, null);
  };

  //To handle error.
  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  //To handle validation.
  const validationHandler = () => {
    let valid = true;
    if (!assessmentToBeAssigned.name) {
      handleError('name', 'Please select assessment');
      valid = false;
    }
    if (valid) {
      assignAssessment();
    }
  };

  //To confirm the alert
  const confirmHandler = () => {
    setAssessmentToBeAssigned({ ...assessmentToBeAssigned, name: targeValue });
    setSelectedRowsTable([]);
    setSelectIndexOption([]);
    setMoveRows([]);
    setShowAlert(false);
  };

  // To selection user and table index.
  const selectHandler = (
    arr,
    indexSettingFunc,
    setRowSelection,
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
  ) => {
    setRowSelection(
      rowsSelected.map((selected) => {
        return arr[selected];
      })
    );
    indexSettingFunc(rowsSelected.map((selected) => selected));
  };

  //Options first table.
  const options = {
    rowsSelected: selectIndex,
    ...optionsForSelectedRows,
    onRowSelectionChange: selectHandler.bind(this, filteredUsers, setSelectIndex, setSelectedRows)
  };

  //Options 2nd table.
  const optionsAssign = {
    ...optionsForSelectedRows,
    rowsSelected: selectIndexOption,

    onRowSelectionChange: selectHandler.bind(
      this,
      moveRows,
      setSelectIndexOption,
      setSelectedRowsTable
    )
  };

  //Forward selected users.
  const moveForward = () => {
    setMoveRows([...moveRows, ...selectedRows]);
    const updatedData =
      selectedRows.length > 0
        ? filteredUsers.filter((user) => !selectedRows.includes(user))
        : filteredUsers;
    setSelectedRows([]);
    setSelectIndex([]);
    setFilteredUsers(updatedData);
  };

  //Backward selected users.
  const moveBackward = () => {
    setFilteredUsers([...filteredUsers, ...selectedRowsTable]);
    const updatedData =
      selectedRowsTable.length > 0
        ? moveRows.filter((user) => !selectedRowsTable.includes(user))
        : moveRows;
    setSelectedRowsTable([]);
    setSelectIndexOption([]);
    setMoveRows([]);
    setMoveRows(updatedData);
  };

  useEffect(() => {
    setSelectIndex([]);
  }, [assessmentToBeAssigned.name]);

  //Filter users according to roles.
  useEffect(() => {
    if (assessmentToBeAssigned.name) {
      const selectedAssessment = filteredPublishedAssessments?.filter(
        (ass) => ass.assessmentId === assessmentToBeAssigned.id
      )[0];

      const filteredUsers = allUsers?.filter(
        (user) =>
          ROLES_PRECEDENCE[user.role] <= ROLES_PRECEDENCE[selectedAssessment.assessmentForName]
      );

      setFilteredUsers(filteredUsers);
    }
  }, [assessmentToBeAssigned.name]);

  //Enable/Disable buttons
  useEffect(() => {
    if (selectedRowsTable?.length > 0) {
      setDisableAssignBtn(false);
    } else {
      setDisableAssignBtn(true);
    }

    if (selectedRows?.length > 0) {
      setDisableForwardBtn(false);
    } else {
      setDisableForwardBtn(true);
    }
  }, [selectedRowsTable, selectedRows]);

  //Assign assessment API.
  const assignAssessment = async () => {
    setSuccessMessage([]);
    setErrorMessage([]);
    setShowAlertBar(false);

    const usersIds = selectedRowsTable.map((user) => {
      return { id: user.id };
    });
    const requestParams = {
      userId: userData.userId,
      assessmentRequest: { assessmentId: assessmentToBeAssigned.id },
      usersList: usersIds
    };

    setOpenLoader(true);
    const assessmentReq = await authPostRequest('assignAssessment', requestParams);
    setOpenLoader(false);
    if (assessmentReq.resStatus === 1) {
      setSuccessMessage(assessmentReq.success);
      setErrorMessage(assessmentReq.error);

      //To empty tables and assessment
      setAssessmentToBeAssigned({ id: '', name: '' });
      setFilteredUsers([]);
      setSelectedRowsTable([]);
      setSelectIndexOption([]);
      setMoveRows([]);
    } else {
      setErrorMessage([assessmentReq.resMessage]);
    }
    setShowAlertBar(true);
  };

  return (
    <Grid>
      <Text variant="h4" style={{ color: style.textColor }}>
        Assign Assessment
      </Text>
      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}
      {showAlert && (
        <ConfirmationBox
          openConfirmationBox={showAlert}
          setOpenConfirmationBox={setShowAlert}
          handleYes={confirmHandler}
          title="Change the selected assessment?"
          message={
            <Text>
              Are you sure you want to change the selected assessment? Changing the selected
              assessment will result in the loss of any unsaved data and user selection. Are you
              sure you want to proceed with changing the assessment?
            </Text>
          }
        />
      )}
      <form>
        <Grid sx={{ mt: '5vh' }}>
          <InputLabel
            htmlFor="demo-simple-select-label"
            sx={{
              fontFamily: style.fontFamily,
              color: style.textGreen,
              marginBottom: 1
            }}
            className="text-menu-color-green">
            Assessments <span className="text-red-600">*</span>
          </InputLabel>
          <Select
            sx={{ width: '100%', fontFamily: style.fontFamily }}
            value={assessmentToBeAssigned.name}
            name="name"
            size="small"
            error={errors.name}
            onChange={onChangeHandler}>
            {filteredPublishedAssessments.length > 0 &&
              filteredPublishedAssessments.map(({ name, id }) => {
                return (
                  <MenuItem value={name} key={id} sx={{ fontFamily: style.fontFamily }}>
                    {name}
                  </MenuItem>
                );
              })}
          </Select>
          {errors.name && <ErrorForSelect>{errors.name}</ErrorForSelect>}
        </Grid>
        <Grid container direction="row" alignItems="center" justifyContent="flex-end">
          <CustomButton
            endIcon={<AssignmentIcon />}
            onClick={validationHandler}
            disabled={disableAssignBtn}>
            Assign
          </CustomButton>
        </Grid>
        <Grid container>
          <Grid xs={5}>
            <Table columns={columns} data={filteredUsers} options={options} />
          </Grid>
          <Grid container xs={2} direction="column" justifyContent="center">
            <CustomButton
              sx={{ mx: 0 }}
              onClick={moveForward}
              disabled={disableForwardBtn}
              endIcon={<KeyboardDoubleArrowRightIcon />}>
              Forward
            </CustomButton>

            <CustomButton
              sx={{ mx: 0 }}
              onClick={moveBackward}
              disabled={disableAssignBtn}
              startIcon={<KeyboardDoubleArrowLeftIcon />}>
              Backward
            </CustomButton>
          </Grid>
          <Grid xs={5}>
            <Table data={moveRows} columns={columns} options={optionsAssign} />
          </Grid>
        </Grid>
      </form>
      {openLoader && <Loader open={openLoader} />}
    </Grid>
  );
};

export default AssignAssessment;

import { useState, useEffect } from 'react';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EmailIcon from '@mui/icons-material/Email';
import { Assignment } from '@mui/icons-material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { style } from 'constant/constants';
import { useMemo } from 'react';

const styled = { color: style.textColor };

const MenuButtons = ({
  open,
  onClose,
  menuAnchorEl,
  status,
  data,
  handleSharePopupClose,
  handerViewerPopupClose,
  handleMarkAsComplete,
  handleViewPopupClose,
  handleResendInvite
}) => {
  const [iconName, setIconName] = useState([]);

  //will remove in the next ticket
  console.log(data, 'here is the complete data');

  const MENU_ICONS = useMemo(() => {
    return [
      {
        id: 1,
        name: 'Resend invite',
        icon: <EmailIcon style={styled} />,
        onClick: handleResendInvite
      },
      {
        id: 2,
        name: 'View',
        icon: <VisibilityOutlinedIcon style={styled} />,
        onClick: handleViewPopupClose
      },
      {
        id: 3,
        name: 'Share',
        icon: <ShareOutlinedIcon style={styled} />,
        onClick: handleSharePopupClose
      },
      {
        id: 4,
        name: 'Assign reviewers',
        icon: <Assignment style={styled} />,
        onClick: handerViewerPopupClose
      },
      {
        id: 5,
        name: 'Mark as complete',
        icon: <CheckBoxOutlinedIcon style={styled} />,
        onClick: handleMarkAsComplete
      }
    ];
  }, []);

  const selectedIcons = () => {
    if (status === 'readyForReview' || status === 'inReview' || status === 'completed')
      setIconName(MENU_ICONS.filter((icon) => icon.id !== 1));

    if (status === 'notFilled') setIconName(MENU_ICONS.filter((icon) => icon.id === 1));

    //For future use
    if (status === 'completed')
      setIconName(MENU_ICONS.filter((icon) => icon.id !== 1 && icon.id !== 5));
  };

  useEffect(() => {
    selectedIcons();
  }, [open]);

  return (
    <Menu
      id="simple-menu"
      anchorEl={menuAnchorEl}
      open={open}
      onClose={onClose}
      keepMounted
      onClick={onClose}
      style={{ top: '30px' }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(1px 1px 3px rgba(0,0,0,0.09))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
      {iconName.map(({ name, icon, onClick }) => (
        <MenuItem
          key={name}
          style={{ fontFamily: style.fontFamily, color: style.textColor }}
          onClick={onClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          {name}
        </MenuItem>
      ))}
    </Menu>
  );
};

MenuButtons.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  menuAnchorEl: PropTypes.object,
  status: PropTypes.string,
  data: PropTypes.string,
  handleSharePopupClose: PropTypes.func,
  handerViewerPopupClose: PropTypes.func,
  handleMarkAsComplete: PropTypes.func,
  handleViewPopupClose: PropTypes.func,
  handleResendInvite: PropTypes.func
};

export default MenuButtons;

import Text from 'components/Text';
import Table from 'components/Table';
import { filterTable } from 'utils/utils';
import { style } from 'constant/constants';
import { useUserDashBoardHook } from './useUserDashBoardHook';

const divStyle = 'text-center mb-2 mt-1';
const textColor = { color: style.textGreen };

const UserDashboard = () => {
  const { review_columns, assigned_coloums, assignedAssessments, reviewAssessments } =
    useUserDashBoardHook();

  return (
    <div className="mx-20 my-1">
      <div className={divStyle}>
        <Text style={textColor} variant="h5">
          Assigned Assessments
        </Text>
      </div>

      <Table data={assignedAssessments} options={filterTable} columns={assigned_coloums} />
      <br></br>
      <div className={divStyle}>
        <Text style={textColor} variant="h5">
          Review Assessments
        </Text>
      </div>
      <Table data={reviewAssessments} options={filterTable} columns={review_columns} />
    </div>
  );
};

export default UserDashboard;

import { getAuthRequest } from 'Api/api';
import { useState, useEffect } from 'react';

const useGetReport = (endPoint) => {
  const [assesmentsList, setAssessmentsList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const getTotalAssessments = async () => {
    try {
      setShowLoader(true);
      const assessments = await getAuthRequest(endPoint);
      if (assessments.resStatus === 1) {
        setAssessmentsList(assessments.userAssessmentCustomResponseList);
      }
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      return err;
    }
  };

  useEffect(() => {
    getTotalAssessments();
  }, []);

  return {
    assesmentsList,
    showLoader
  };
};

export default useGetReport;

import { useParams } from 'react-router-dom';
import { strengthColor } from '../../utils/password-strength';
import { postRequest } from '../../Api/api';
import { useState } from 'react';
import { passwordValidation } from '../../utils/password-strength';
import { useNavigate } from 'react-router-dom';

export const useResetPassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState();
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState('');

  const [passwordInfo, setPasswordInfo] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const { id } = useParams();
  const navigate = useNavigate();

  const valueChangeHandler = (e, input) => {
    setMessage(input === passwordInfo.newPassword ? strengthColor(e.target.value) : '');
    setPasswordInfo({ ...passwordInfo, [e.target.name]: e.target.value });
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const oldPasswordValid = passwordValidation(passwordInfo.oldPassword, 'old password');
    handleError('oldPassword', oldPasswordValid);
    const newPasswordValid = passwordValidation(passwordInfo.newPassword, 'new password');
    handleError(
      'newPassword',
      <>
        Include special characters and numerics for strength <br />
        {newPasswordValid}
      </>
    );
    const confirmPasswordValid = passwordValidation(
      passwordInfo.confirmPassword,
      'new  password again'
    );
    handleError('confirmPassword', confirmPasswordValid);
    if (!confirmPasswordValid && passwordInfo.confirmPassword !== passwordInfo.newPassword) {
      handleError('confirmPassword', 'Passwords do not match');
    }
    if (
      oldPasswordValid === '' &&
      newPasswordValid === '' &&
      confirmPasswordValid === '' &&
      passwordInfo.confirmPassword === passwordInfo.newPassword
    ) {
      setErrors(false);
      handleResetPassword();
    }
  };

  const handleResetPassword = async () => {
    setSuccess([]);
    setErrorMessage([]);
    setShowAlertBar(false);
    try {
      setShowLoader(true);
      const data = await postRequest('resetPassword', {
        userId: id,
        oldPassword: passwordInfo.oldPassword,
        password: passwordInfo.newPassword,
        rePassword: passwordInfo.confirmPassword
      });
      setShowLoader(false);
      if (data.resStatus === 1) {
        setSuccess([data.resMessage]);
        setSuccessPopup(true);
        setPasswordInfo({ oldPassword: '', newPassword: '', confirmPassword: '' });
      } else {
        setErrorMessage([data.resMessage]);
      }
      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage(err);
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  return {
    showNewPassword,
    showOldPassword,
    showConfirmPassword,
    errorMessage,
    success,
    errors,
    showAlertBar,
    setShowAlertBar,
    handleError,
    showLoader,
    handleSubmit,
    setShowConfirmPassword,
    setShowOldPassword,
    setShowNewPassword,
    valueChangeHandler,
    handleResetPassword,
    handleClose,
    passwordInfo,
    message,
    successPopup,
    setSuccessPopup
  };
};

import { Grid } from '@mui/material';
import CategoryForm from './CategoryForm';
import PropTypes from 'prop-types';

const categories = { name: '', description: '' };

const CreateCategory = ({
  closeModal,
  setIsFormSaved,
  setSuccess,
  setError,
  setAlertBar,
  isFormSaved
}) => {
  return (
    <Grid sx={{ width: '100%' }}>
      <CategoryForm
        buttonText="Save & Add More"
        buttonClose="Save & Close"
        categories={categories}
        closeModal={closeModal}
        setIsFormSaved={setIsFormSaved}
        setSuccess={setSuccess}
        setError={setError}
        setAlertBar={setAlertBar}
        isFormSaved={isFormSaved}
      />
    </Grid>
  );
};

CreateCategory.propTypes = {
  categories: PropTypes.object,
  closeModal: PropTypes.func,
  setIsFormSaved: PropTypes.func,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  isFormSaved: PropTypes.bool
};

export default CreateCategory;

import PropTypes from 'prop-types';
import { Stack, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import Input from 'components/Input';
import CustomButton from 'components/Button';
import AddIcon from '@mui/icons-material/Add';
import Save from '@mui/icons-material/Save';
import { useSubCategoryForm } from './useSubCategoryForm';
import Loader from 'components/Loader';
import { style } from '../../constant/constants';
import SuccessError from 'components/SuccessError';
import ErrorForSelect from 'components/ErrorForSelect';
import TextEllipsize from 'utils/TextEllipsize';

const InputFeilds = [
  {
    name: 'subCategoryName',
    label: 'Sub-Category',
    type: 'text',
    placeholder: 'Enter Sub-Category',
    required: true
  },
  {
    name: 'description',
    label: 'Description',
    type: 'text',
    placeholder: 'Enter description',
    multiline: true
  }
];

const SubCategoryForm = ({
  buttonText,
  subCategory,
  handleModalState,
  buttonClose,
  setAlertBar,
  setSuccess,
  setError,
  isFormSaved,
  setIsFormSaved
}) => {
  const {
    categories,
    errorMessage,
    successMessage,
    subCategoryInfo,
    handleCatInfo,
    handleError,
    errors,
    saveAndAddMore,
    saveAndClose,
    openLoader,
    showAlertBar,
    setShowAlertBar
  } = useSubCategoryForm({
    subCategory,
    handleModalState,
    setIsFormSaved,
    setSuccess,
    setError,
    setAlertBar,
    isFormSaved
  });

  return (
    <Grid sx={{ width: '100%' }}>
      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <div className="mx-12">
        <form>
          <Stack container justifyContent="center" sx={{ mt: '2vh' }}>
            <InputLabel
              htmlFor="demo-simple-select-label"
              sx={{
                fontFamily: style.fontFamily,
                color: style.textGreen,
                marginBottom: 1
              }}
              className="text-menu-color-green">
              Category <span className="text-red-600">*</span>
            </InputLabel>

            <Select
              value={subCategoryInfo.categoryName}
              name="categoryName"
              size="small"
              onChange={handleCatInfo}
              error={errors.categoryName}>
              {categories.length > 0 &&
                categories.map(({ name, id }) => {
                  return (
                    <MenuItem value={name} key={id}>
                      <TextEllipsize value={name} />
                    </MenuItem>
                  );
                })}
            </Select>
            {errors.categoryName && <ErrorForSelect>{errors.categoryName}</ErrorForSelect>}
          </Stack>

          <Grid container alignItems="center" justifyContent="center">
            {InputFeilds.map(({ name, label, placeholder, type, required, multiline }) => (
              <Input
                sx={{ marginTop: '2vh', width: '100%', mx: '0vh' }}
                key={name}
                required={required}
                type={type}
                placeholder={placeholder}
                onChange={handleCatInfo}
                value={subCategoryInfo[name]}
                multiline={multiline}
                label={label}
                onFocus={() => handleError(name, null)}
                name={name}
                error={errors[name]}
              />
            ))}
          </Grid>

          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            {buttonClose !== 'Save' && (
              <CustomButton type="submit" endIcon={<AddIcon />} onClick={saveAndAddMore}>
                {buttonText}
              </CustomButton>
            )}

            <CustomButton
              type="button"
              endIcon={<Save />}
              buttonClose={buttonClose}
              onClick={saveAndClose}>
              {buttonClose}
            </CustomButton>
          </Stack>
        </form>
      </div>

      {openLoader && <Loader open={openLoader} />}
    </Grid>
  );
};

SubCategoryForm.propTypes = {
  buttonText: PropTypes.string,
  subCategory: PropTypes.object,
  buttonClose: PropTypes.string,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  isFormSaved: PropTypes.bool,
  setIsFormSaved: PropTypes.func,
  handleModalState: PropTypes.func
};

export default SubCategoryForm;

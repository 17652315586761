import Text from './Text';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const InvalidUser = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-orange-400">
      <SentimentDissatisfiedIcon style={{ fontSize: 64 }} size="large" />
      <br />
      <Text variant="h3">Invalid User</Text>
      <Text variant="h5">You are not having access to this assessment.</Text>
    </div>
  );
};
export default InvalidUser;

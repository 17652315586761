import { useState, useEffect } from 'react';
import Table from '../../components/Table';
import { Grid } from '@mui/material';
import Text from 'components/Text';
import AddIcon from '@mui/icons-material/Add';
import { getAuthRequest } from 'Api/api';
import CustomButton from 'components/Button';
import { style } from 'constant/constants';
import DialogBox from 'components/DialogBox';
import CRUDActions from 'components/CRUDActions';
import SuccessError from 'components/SuccessError';
import Modal from 'components/Modal';
import CreateCategory from './CreateCategory';
import UpdateCategory from './UpdateCategory';
import { authPostRequest } from 'Api/api';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';
import DeleteSpan from 'components/DeleteSpan';
import { tableOptionFilter, columOptions } from '../../utils/utils';
import TextEllipsize from '../../utils/TextEllipsize';

const isSort = {
  sort: false,
  ...columOptions
};

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);

  const { userData } = useSelector((store) => store.auth);

  //For Open Dialog for delete user
  const handleOpenDialogBox = (categoryIndex) => {
    setCategory(categories[categoryIndex]);
    setOpenDialogBox(true);
  };

  const handleDialogBoxState = () => {
    setOpenDialogBox(!openDialogBox);
  };
  const handleOpenUpdateModal = (categoryIndex) => {
    setCategory(categories[categoryIndex]);
    setOpenUpdateModal(true);
  };

  const deleteCategory = async () => {
    setSuccessMessage([]);
    setErrorMessage([]);
    setShowAlertBar(false);
    try {
      setOpenDialogBox(false);
      const requestParam = {
        categoryId: category.categoryId,
        userId: userData.userId
      };
      setOpenLoader(true);
      const del = await authPostRequest('deleteCategory', requestParam);
      setOpenLoader(false);
      if (del.resStatus === 1) {
        setSuccessMessage([del.resMessage]);
      } else {
        setErrorMessage([del.resMessage]);
      }
      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage(['Error']);
    }
  };

  const columns = [
    {
      name: 'name',
      label: 'Category',
      options: {
        ...isSort,
        customBodyRender: (value) => <TextEllipsize value={value} />
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        ...isSort,
        customBodyRender: (value) => <TextEllipsize value={value} />
      }
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        //Actions Delete, Update
        customBodyRender: (value, tableMeta) => {
          return (
            <CRUDActions
              onDelete={() => handleOpenDialogBox(tableMeta.rowIndex)}
              onEdit={() => handleOpenUpdateModal(tableMeta.rowIndex)}
            />
          );
        }
      }
    }
  ];

  const getCategories = async () => {
    try {
      setOpenLoader(true);
      const categoriesList = await getAuthRequest('getCategories');
      setOpenLoader(false);
      setCategories(categoriesList.categoriesList);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getCategories();
  }, [isFormSaved]);

  return (
    <>
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        <Text variant="h4" style={{ color: style.textColor }}>
          Categories
        </Text>
        <CustomButton type="submit" endIcon={<AddIcon />} onClick={() => setOpenAddModal(true)}>
          Add
        </CustomButton>
      </Grid>

      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}
      <Table data={categories} options={tableOptionFilter} columns={columns} />

      {openDialogBox && (
        <DialogBox
          openDialogBox={openDialogBox}
          handleDialogBoxState={handleDialogBoxState}
          deleteMessage={<DeleteSpan message="category" toBeDel={category.name} />}
          handleConfirmAction={deleteCategory}
        />
      )}

      {openAddModal && (
        <Modal open={openAddModal} setOpen={setOpenAddModal} header="Add Category">
          <CreateCategory
            closeModal={setOpenAddModal}
            isFormSaved={isFormSaved}
            setIsFormSaved={setIsFormSaved}
            setSuccess={setSuccessMessage}
            setError={setErrorMessage}
            setAlertBar={setShowAlertBar}
          />
        </Modal>
      )}

      {openUpdateModal && (
        <Modal open={openUpdateModal} setOpen={setOpenUpdateModal} header="Update Category">
          <UpdateCategory
            categories={category}
            isFormSaved={isFormSaved}
            closeModal={setOpenUpdateModal}
            setIsFormSaved={setIsFormSaved}
            setSuccess={setSuccessMessage}
            setError={setErrorMessage}
            setAlertBar={setShowAlertBar}
          />
        </Modal>
      )}

      {openLoader && <Loader open={openLoader} />}
    </>
  );
};

export default Categories;

import { Paper, Stack, Grid } from '@mui/material';
import Text from 'components/Text';
import Input from 'components/Input';
import CustomButton from 'components/Button';
import Loader from 'components/Loader';
import { stringDateFormatting } from 'utils/dateFormatter';
import { useReviewAssessmentHook } from './useReviewAssessmentHook';
import AssessmentQuestions from './AssessmentQuestions';
import { style } from 'constant/constants';
import SuccessError from 'components/SuccessError';

const textStyles = { color: style.textGreen, fontSize: 18 };

const ReviewAssessment = () => {
  const {
    reviews,
    handleReviewSubmission,
    onReviewsChange,
    handleError,
    errors,
    assessmentReviews,
    empAssessmentData,
    assessmentQuestions,
    showLoader,
    showAlertBar,
    errorMessage,
    setShowAlertBar
  } = useReviewAssessmentHook();

  return (
    <Paper
      sx={{
        p: 2,
        margin: 4,
        maxWidth: '100%',
        flexGrow: 1
      }}>
      {showAlertBar && (
        <SuccessError errorMessage={errorMessage} setShowAlertBar={setShowAlertBar} />
      )}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={4}>
          <Stack direction="column" alignItems="flex-start" sx={{ mt: '8vh' }}>
            <Text variant="h6" style={{ color: style.textGreen }}>
              Employee: {empAssessmentData.employeeName}
            </Text>
            <Text style={textStyles}>
              Submit Date: {stringDateFormatting(empAssessmentData.submittedDate)}
            </Text>
          </Stack>
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          <div className="text-center text-menu-color-teal">
            {assessmentReviews !== null && (
              <Text variant="h5" style={textStyles}>
                You have already reviewed this assessment.
              </Text>
            )}

            <Text variant="h4">{empAssessmentData.name}</Text>

            <Text>{empAssessmentData.description}</Text>

            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mr: '5vh' }}>
              <Text sx={{ mr: 15 }}>Valid Until: </Text>
              <Text sx={{ mr: 15 }}>{stringDateFormatting(empAssessmentData.validUntil)}</Text>
            </Stack>
          </div>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Text
            variant="h5"
            style={{
              ...textStyles,
              marginTop: '7vh',
              textAlign: 'right'
            }}>
            Score: {empAssessmentData.score}%
          </Text>
        </Grid>
      </Grid>
      <AssessmentQuestions assessmentQuestions={assessmentQuestions} />

      <Input
        type="text"
        readOnly={assessmentReviews !== null}
        placeholder="Please provide feedback."
        onChange={onReviewsChange}
        value={reviews}
        required={true}
        label="Reviews"
        onFocus={() => handleError('reviews', null)}
        name="reviews"
        multiline={true}
        error={errors['reviews']}
      />

      {assessmentReviews === null && (
        <CustomButton onClick={handleReviewSubmission}>Submit</CustomButton>
      )}

      {showLoader && <Loader open={showLoader} />}
    </Paper>
  );
};

export default ReviewAssessment;

import PropTypes from 'prop-types';
import ProfessionalInfoForm from './ProfessionalInfoForm';

const UpdateProfessionalInfo = ({
  handleModalState,
  prof,
  setAlertBar,
  setSuccess,
  setError,
  isFormSaved,
  setIsFormSaved
}) => {
  const info = { ...prof, startDate: new Date(prof.startDate), endDate: new Date(prof.endDate) };

  return (
    <ProfessionalInfoForm
      info={info}
      handleModalState={handleModalState}
      buttonClose="Save"
      setSuccess={setSuccess}
      setError={setError}
      setAlertBar={setAlertBar}
      isFormSaved={isFormSaved}
      setIsFormSaved={setIsFormSaved}
    />
  );
};

UpdateProfessionalInfo.propTypes = {
  handleModalState: PropTypes.func,
  prof: PropTypes.object,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  setIsFormSaved: PropTypes.func,
  isFormSaved: PropTypes.bool
};

export default UpdateProfessionalInfo;

import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Grid,
  Avatar,
  Tooltip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import OutlinedCard from '../components/OutlinedCard';
import { Navigate, Outlet, Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addAssessment, addUsers } from 'reduxStore/authSlice';
import logo from '../assets/images/auth/logo.png';
import { AppBar, Main, DrawerHeader } from './components/StyledComponents';
import { useSelector } from 'react-redux';
import AccountMenu from './components/AccountMenu';
import { style } from 'constant/constants';
import Text from '../components/Text';
import { useEffect } from 'react';
import { getAuthRequest } from 'Api/api';
import { ICONS } from './components/NavigationPane';
import { isNotAdmin } from 'utils/utils';

export const drawerWidth = 250;

const BasePage = () => {
  const { authToken } = useSelector((store) => store.auth);
  const auth = !!authToken;
  const location = useLocation();

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [assesments, setAssessments] = useState([]);
  const [users, setUsers] = useState([]);

  const { userData } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  //Get all assessments
  const getAllAssessments = async () => {
    try {
      const assessmentsList = await getAuthRequest('getAllAssessments');

      const publishAssessments = assessmentsList?.assessmentsList.filter((ass) => ass.publish);
      setAssessments(publishAssessments);
    } catch (err) {
      return err;
    }
  };

  const getAllUsers = async () => {
    try {
      const usersList = await getAuthRequest('getUsersList');

      setUsers(usersList?.usersResponses);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (isNotAdmin(userData.roles)) {
      navigate('dashboard');
    }
    getAllAssessments();
    getAllUsers();
  }, []);

  // Function to map path to tab name
  const mapPathToTabName = (path) => {
    const matchingTab = ICONS.find((item) => item.path === path);
    // Change 'DefaultTabName' to your default tab name if needed
    return matchingTab ? matchingTab.name : 'DefaultTabName';
  };

  // Enable tabs according to their path
  useEffect(() => {
    const tabName = mapPathToTabName(location.pathname);
    setSelectedItem(tabName);
  }, [location]);

  //add users to redux store
  useEffect(() => {
    dispatch(addAssessment(assesments));
  }, [assesments]);

  useEffect(() => {
    dispatch(addUsers(users));
  }, [users]);

  //handle selected item
  const handleItemSelect = (item) => {
    setSelectedItem(item);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  if (!auth) {
    return <Navigate to="/login" />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {openMenu && <AccountMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />}
      <AppBar position="fixed" open={open} style={{ backgroundColor: style.backgroundColor }}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}>
          <Grid display="flex" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}>
              <MenuIcon />
            </IconButton>
            <Text variant="h6" noWrap component="div">
              Employee Self Assessment
            </Text>
          </Grid>
          <Grid display="flex">
            <Text>{userData.fullName}</Text>
            <Avatar
              alt="Remy Sharp"
              src={userData.avatar}
              sx={{ width: 30, height: 30, marginLeft: 1 }}
              onClick={() => {
                setOpenMenu(!openMenu);
              }}
            />
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}>
        <DrawerHeader className="drop-shadow-xl">
          <img src={logo} alt="logo" width={180} height={100} />
          <IconButton onClick={handleDrawerOpen}>
            {theme.direction === 'ltr' && (
              <Tooltip title="Collapse">
                <ChevronLeftIcon />
              </Tooltip>
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {ICONS.map(({ name, icon, path }) => (
            <ListItem component={Link} to={path} key={name} disablePadding>
              <ListItemButton
                selected={selectedItem === name}
                onClick={() => handleItemSelect(name)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  primary={name}
                  className="text-menu-color-teal font-mono"
                  sx={{ font: style.fontFamily }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open} className="bg-background">
        <DrawerHeader />
        <OutlinedCard>
          <Outlet />
        </OutlinedCard>
      </Main>
    </Box>
  );
};

export default BasePage;

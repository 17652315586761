import './index.css';
import Routing from 'routes/Routing';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decodeToken } from 'utils/DecodeToken';
import { loggedOut } from 'reduxStore/authSlice';
const App = () => {
  const { authToken } = useSelector((store) => store.auth);

  const dispatch = useDispatch();
  const decodedToken = decodeToken(authToken);

  useEffect(() => {
    const date = new Date();
    const seconds = Math.floor(date.getTime() / 1000);

    //Check if then logged-in user's tokens is valid.
    if (decodedToken) {
      if (seconds > decodedToken.exp) {
        dispatch(loggedOut());
      }
    }
  }, []);

  return <Routing />;
};

export default App;

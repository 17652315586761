import Text from './Text';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const NotAvailable = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-orange-400">
      <SentimentDissatisfiedIcon style={{ fontSize: 64 }} size="large" />
      <br />
      <Text variant="h3">Assessment Unavailable</Text>
      <Text variant="h5">This assessment is no more available to fill. Please contact admin.</Text>
    </div>
  );
};
export default NotAvailable;

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { authPostRequest } from 'Api/api';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { dateFormatter } from 'utils/dateFormatter';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { style } from 'constant/constants';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { encodeObjectInQueryString } from 'utils/DecodeToken';
import { useMemo } from 'react';
import RateReviewIcon from '@mui/icons-material/RateReview';

const columns_options = { filter: true, sort: false };
const columns_optionsIDs = { filter: false, display: 'excluded' };

const columns = [
  {
    name: 'userId',
    label: 'ID',
    options: columns_optionsIDs
  },
  {
    name: 'assessmentId',
    label: 'ID',
    options: columns_optionsIDs
  },
  {
    name: 'assessmentName',
    label: 'Assessment',
    options: columns_options
  },
  {
    name: 'assignedDate',
    label: 'Assigned Date',
    options: {
      ...columns_options,
      customBodyRender: (value) => {
        return dateFormatter(value);
      }
    }
  },
  {
    name: 'assignedBy',
    label: 'Assigned By',
    options: columns_options
  },
  {
    name: 'status',
    label: 'Status',
    options: columns_options
  },
  {
    name: 'score',
    label: 'Score',
    options: columns_options
  }
];

export const useUserDashBoardHook = () => {
  const { userData } = useSelector((store) => store.auth);
  const [assignedAssessments, setAssignedAssessments] = useState([]);
  const [reviewAssessments, setReviewAssessments] = useState([]);

  const navigate = useNavigate();

  const assigned_coloums = [
    ...columns,
    {
      name: 'action',
      label: 'Action',
      options: {
        ...columns_options,
        customBodyRender: (value, tableMeta) => (
          <Tooltip
            title={tableMeta.rowData[5] === 'Assigned' ? 'Fill Assessment' : 'View Assessment'}>
            {tableMeta.rowData[5] === 'Assigned' ? (
              <NoteAltIcon
                onClick={() => toFillAssessment(tableMeta.rowData[0], tableMeta.rowData[1])}
                style={{ color: style.textColor }}
              />
            ) : (
              <VisibilityIcon
                style={{ color: style.textColor }}
                onClick={() => toFillAssessment(tableMeta.rowData[0], tableMeta.rowData[1])}
              />
            )}
          </Tooltip>
        )
      }
    }
  ];

  const review_columns = useMemo(
    () => [
      {
        name: 'userName',
        label: 'Employee',
        options: columns_options
      },
      ...columns,
      {
        name: 'action',
        label: 'Action',
        options: {
          ...columns_options,
          customBodyRender: (value, tableMeta) => (
            <Tooltip
              title={tableMeta.rowData[6] === 'Assigned' ? 'Review Assessment' : 'View Assessment'}>
              {tableMeta.rowData[6] === 'Assigned' ? (
                <RateReviewIcon
                  onClick={() => toReviewAssessment(tableMeta.rowData[1], tableMeta.rowData[2])}
                  style={{ color: style.textColor }}
                />
              ) : (
                <VisibilityIcon
                  style={{ color: style.textColor }}
                  onClick={() => toReviewAssessment(tableMeta.rowData[1], tableMeta.rowData[2])}
                />
              )}
            </Tooltip>
          )
        }
      }
    ],
    []
  );

  //   fetching the reviews of reviewer when done.
  const getUserAssessments = async () => {
    const reqParams = {
      userId: userData.userId
    };

    try {
      const userAssessments = await authPostRequest('getUserAssessmentsByUserId', reqParams);
      if (userAssessments.resStatus === 1) {
        setAssignedAssessments(userAssessments.nonAdminDashboardResponsesList);
      }
    } catch (err) {
      return err;
    }
  };

  const getUserReviewAssessments = async () => {
    const reqParams = {
      userId: userData.userId
    };

    try {
      const userReviewAssessments = await authPostRequest(
        'getReviewAssessmentsByReviewerId',
        reqParams
      );
      if (userReviewAssessments.resStatus === 1) {
        setReviewAssessments(userReviewAssessments.nonAdminDashboardResponsesList);
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getUserAssessments();
    getUserReviewAssessments();
  }, []);

  const toFillAssessment = (userId, assessmetId) => {
    const param = { userId: userId, assessment: assessmetId };
    const urlEcoding = encodeObjectInQueryString(param);
    const queryStr = btoa(urlEcoding);
    navigate(`/fillAssessment/${queryStr}`);
  };

  const toReviewAssessment = (userId, assessmetId) => {
    const param = { userId: userId, assessment: assessmetId, reviewerId: userData.userId };
    const urlEcoding = encodeObjectInQueryString(param);
    const queryStr = btoa(urlEcoding);
    navigate(`/reviewAssessment/${queryStr}`);
  };

  return {
    assigned_coloums,
    review_columns,
    reviewAssessments,
    assignedAssessments
  };
};

export const ROLES = Object.freeze({
  ROLE_SUPER_ADMIN: 'Admin',
  ROLE_ADMIN: 'Admin',
  ROLE_PRINCIPAL: 'Principal',
  ROLE_SENIOR_ASSOCIATE: 'Senior Associate',
  ROLE_ASSOCIATE: 'Associate',
  ROLE_STAFF: 'Staff'
});

export const ROLES_PRECEDENCE = Object.freeze({
  ROLE_SUPER_ADMIN: 6,
  ROLE_ADMIN: 5,
  ROLE_PRINCIPAL: 4,
  ROLE_SENIOR_ASSOCIATE: 3,
  ROLE_ASSOCIATE: 2,
  ROLE_STAFF: 1
});

export const ROLE = Object.keys(ROLES);

export const STATUS = Object.freeze({
  notFilled: 'Not Filled',
  readyForReview: 'Ready For Review',
  inReview: 'In Review',
  completed: 'Completed'
});

import { authPostRequest, getAuthRequest } from 'Api/api';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { dateFormatter } from 'utils/dateFormatter';
import { ROLES, STATUS } from 'constant/enums';
import MenuButtons from './MenuButtons';
import TextEllipsize from 'utils/TextEllipsize';

const columns_options = { filter: true, sort: true };
const columns_optionsIDs = { filter: false, display: 'excluded' };
const column = [
  {
    name: 'assessmentId',
    label: 'ID',
    options: columns_optionsIDs
  },
  {
    name: 'employeeId',
    label: 'Employee ID',
    options: columns_optionsIDs
  },
  {
    name: 'employeeName',
    label: 'Employee',
    options: columns_options
  },
  {
    name: 'assessmentName',
    label: 'Assessment',
    options: { ...columns_options, customBodyRender: (value) => <TextEllipsize value={value} /> }
  },
  {
    name: 'assessmentFor',
    label: 'Assessment For',
    options: {
      ...columns_options,
      customBodyRender: (value) => ROLES[value]
    }
  },
  {
    name: 'assignedDate',
    label: 'Assigned Date',
    options: {
      ...columns_options,
      customBodyRender: (value) => dateFormatter(value)
    }
  },
  {
    name: 'assignedBy',
    label: 'Assigned By',
    options: columns_options
  },
  {
    name: 'score',
    label: 'Score',
    options: columns_options
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      ...columns_options,
      customBodyRender: (value) => STATUS[value]
    }
  },
  {
    name: 'userAssessmentId',
    label: 'User Assessment Id',
    options: columns_optionsIDs
  }
];

export const useManageAssessment = () => {
  const [assesmentList, setAssessmentList] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [userAssessment, setUserAssessment] = useState([]);
  const [showRviewerPopup, setShowRviewerPopup] = useState(false);
  const [showViewPopup, setShowViewPopup] = useState(false);

  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertBar, setShowAlertBar] = useState(false);

  const [status, setStatus] = useState();

  const { userData } = useSelector((store) => store.auth);

  const openMenu = (event, rowData) => {
    setMenuAnchorEl(event.currentTarget);
    setStatus(rowData[8]);
    setUserAssessment(rowData);
  };

  const markAssessmentAsComplete = async () => {
    try {
      setShowLoader(true);
      const response = await authPostRequest('completeUserAssessment', {
        userAssessmentId: userAssessment[9],
        userId: userData.userId
      });
      setShowLoader(false);

      if (response.resStatus === 1) {
        setSuccessMessage([response.resMessage]);
        getAllAssessments();
      } else {
        throw new Error(response.resMessage);
      }
      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage([err]);
      setShowAlertBar(true);
    }
  };

  const resendFillAssessmentInvite = async () => {
    setSuccessMessage([]);
    setErrorMessage([]);
    setShowAlertBar(false);

    const requestParams = {
      userId: userData.userId,
      assessmentRequest: { assessmentId: userAssessment[0] },
      usersList: [{ id: userAssessment[1] }]
    };

    setShowLoader(true);
    const assignAssessment = await authPostRequest('assignAssessment', requestParams);
    setShowLoader(false);
    if (assignAssessment.resStatus === 1) {
      setSuccessMessage([assignAssessment.success[1]]);
      setErrorMessage(assignAssessment.error);
    } else {
      setErrorMessage([assignAssessment.resMessage]);
    }
    setShowAlertBar(true);
  };

  const handleSharePopupClose = () => {
    setShowSharePopup(!showSharePopup);
  };
  const handleViewerPopupClose = () => {
    setShowRviewerPopup(!showRviewerPopup);
  };
  const handleViewPopupClose = () => {
    setShowViewPopup(!showViewPopup);
  };

  const handleMarkAsComplete = () => {
    setShowAlert(true);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const confirmHandler = () => {
    markAssessmentAsComplete();
    setShowAlert(false);
  };

  const columns = [
    ...column,
    {
      name: 'actions',
      label: 'Actions',
      options: {
        ...columns_options,
        filter: false,
        sort: false,

        //Actions
        customBodyRender: (value, tableMeta) => {
          return (
            <Grid>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => openMenu(e, tableMeta.rowData)}>
                <MoreVertOutlinedIcon />
              </IconButton>
              {menuAnchorEl && (
                <MenuButtons
                  menuAnchorEl={menuAnchorEl}
                  openMenu={openMenu}
                  open={Boolean(menuAnchorEl)}
                  onClose={closeMenu}
                  status={status}
                  data={tableMeta.rowData}
                  handleSharePopupClose={handleSharePopupClose}
                  handerViewerPopupClose={handleViewerPopupClose}
                  handleMarkAsComplete={handleMarkAsComplete}
                  handleViewPopupClose={handleViewPopupClose}
                  handleResendInvite={resendFillAssessmentInvite}
                />
              )}
            </Grid>
          );
        }
      }
    }
  ];

  //To get users assessments
  const getAllAssessments = async () => {
    try {
      setShowLoader(true);
      const assesments = await getAuthRequest('getAllUsersAssessments');
      setShowLoader(false);
      if (assesments.resStatus === 1) {
        setAssessmentList(assesments.userAssessmentCustomResponseList);
      }
    } catch (err) {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getAllAssessments();
  }, []);

  return {
    assesmentList,
    successMessage,
    errorMessage,
    showAlert,
    showViewPopup,
    showAlertBar,
    showLoader,
    showSharePopup,
    setShowSharePopup,
    showRviewerPopup,
    userAssessment,
    columns,
    setShowAlertBar,
    setShowViewPopup,
    handleViewPopupClose,
    setShowAlert,
    confirmHandler,
    setShowRviewerPopup
  };
};

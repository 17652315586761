import { api, authorizedApi } from 'constant/constants';

// POST API for un-auth type
export const postRequest = async (url, params) => {
  try {
    const res = await api.post(url, params);
    return res.data;
  } catch (err) {
    return err;
  }
};

// POST API for auth type
export const authPostRequest = async (url, params) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const res = await authorizedApi(user.token)
      .post(url, params)
      .then((response) => response.data);
    return res;
  } catch (err) {
    return err;
  }
};

// GET API
export const getRequest = async (url) => {
  try {
    const res = await api.get(url).then((response) => response.data);
    return res;
  } catch (err) {
    return err;
  }
};

// GET API for auth type
export const getAuthRequest = async (url) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const res = await authorizedApi(user.token)
      .get(url)
      .then((response) => response.data);

    return res;
  } catch (err) {
    return err;
  }
};

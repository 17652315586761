import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api } from 'constant/constants';

export const userSlice = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api}/users`
  }),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => '/',
      providesTags: ['Users']
    }),
    updateUser: builder.mutation({
      query: ({ userId, UpdatedUserData }) => {
        return {
          url: `/${userId}`,
          method: 'PATCH',
          body: {
            firstName: UpdatedUserData.firstName,
            lastName: UpdatedUserData.lastName,
            email: UpdatedUserData.email
          }
        };
      },
      invalidatesTags: ['Users']
    }),
    deleteUserData: builder.mutation({
      query: (userId) => ({
        url: `/${userId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users']
    })
  })
});

export const { useGetUsersQuery, useUpdateUserMutation, useDeleteUserDataMutation } = userSlice;

export const userReducer = userSlice.reducer;

import { useState, useEffect } from 'react';
import { getAuthRequest, authPostRequest } from 'Api/api';
import { useSelector } from 'react-redux';
import { checkTextLength, formattedDateString, isStringEmpty } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';

export const useAssessmentForm = (info, checkedQuestions) => {
  const navigate = useNavigate();

  const [assesmentInfo, setAssesmentInfo] = useState(info);
  const [assesmentQuestions, setAssesmentQuestions] = useState([]);
  const [validateDate, setValidateDate] = useState(null);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [showAssessmentPreview, setShowAssessmentPreview] = useState(false);

  //   CheckBox tree states
  const [checked, setChecked] = useState(checkedQuestions);
  const [expanded, setExpanded] = useState([]);
  const { userData } = useSelector((store) => store.auth);

  const handleCheck = (checked) => {
    setChecked(checked);
    if (checked.length > 0) {
      handleError('checkedQs', '');
    }
  };

  const handleExpand = (expanded) => {
    setExpanded(expanded);
  };

  // Helper function to map the given dataset to the required format for CheckboxTree
  const mapDataToCheckboxTree = (data) => {
    return (
      data
        .map((category) => {
          const subCategoryList = category.subCategoryList.filter((subCategory) => {
            // Only include subcategories with questions
            return subCategory.questionsList.length > 0;
          });

          if (subCategoryList.length === 0) {
            // Skip categories with no subcategories that have questions
            return null;
          }

          return {
            value: `category-${category.categoryId}`,
            label: category.name,
            children: subCategoryList.map((subCategory) => {
              return {
                value: `subCategory-${subCategory.subCategoryId}`,
                label: subCategory.name,
                children: subCategory.questionsList.map((question) => {
                  return {
                    value: `questionId-${question.questionId}`,
                    label: question.question
                  };
                })
              };
            })
          };
        })
        // Filter out null entries (categories with no subcategories having questions)
        .filter(Boolean)
    );
  };

  const checkboxTreeData = mapDataToCheckboxTree(assesmentQuestions);

  const getAllAssessmentQuestions = async () => {
    try {
      const assesmentQuestion = await getAuthRequest('getAllAssessmentQuestionsInHierarchy');
      setAssesmentQuestions(assesmentQuestion?.categoriesList);
    } catch (err) {
      return err;
    }
  };

  const handleAssesmentInfo = (e) => {
    setAssesmentInfo({ ...assesmentInfo, [e.target.name]: e.target.value });
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const checkIsFormValid = () => {
    if (isStringEmpty(assesmentInfo.name)) {
      handleError('name', 'Please enter assessment name');
    }

    if (isStringEmpty(assesmentInfo.description)) {
      handleError('description', 'Please enter description');
    }

    if (!assesmentInfo.validUntil) {
      handleError('validUntil', 'Please enter valid until date');
    }

    const isAssessmentNameValid = checkTextLength(assesmentInfo.name, 100);

    if (isAssessmentNameValid) {
      handleError('name', 'Maximum 100 characters allowed.');
    }

    const isDescriptionLengthValid = checkTextLength(assesmentInfo.description, 500);

    if (isDescriptionLengthValid) {
      handleError('description', 'Maximum 500 characters allowed.');
    }

    if (!assesmentInfo.role) {
      handleError('role', 'Please select role');
    }

    if (checked.length === 0) {
      handleError('checkedQs', 'Please select at least one question');
    }

    if (
      !isStringEmpty(assesmentInfo.name) &&
      assesmentInfo.validUntil &&
      !isStringEmpty(assesmentInfo.description) &&
      !isDescriptionLengthValid &&
      !isAssessmentNameValid &&
      assesmentInfo.role &&
      checked.length > 0 &&
      validateDate === null
    ) {
      handleError({});
      return true;
    }

    return false;
  };

  const saveAssessment = async () => {
    const isFormValid = checkIsFormValid();

    if (!isFormValid) return;

    const requestParams = {
      userId: userData.userId,
      assessmentRequest: {
        assessmentId: assesmentInfo.assessmentId ? assesmentInfo.assessmentId : '',
        name: assesmentInfo.name?.trim(),
        description: assesmentInfo.description?.trim(),
        assessmentForId: assesmentInfo.role,
        publish: assesmentInfo.publish,
        validUntil: formattedDateString(assesmentInfo.validUntil)
      },
      // converting array of string into array of objects
      assessmentQuestionRequestList: checked
        .map((item) => {
          const [key, value] = item.split('-');
          if (key === 'questionId') {
            return { [key]: parseInt(value) };
          }
          return null; // Return null for items with keys other than 'questionId'
        })
        .filter((item) => item !== null) // Filter out the null items
    };

    try {
      setErrorMessage([]);
      setSuccessMessage([]);

      setOpenLoader(true);
      setShowAlertBar(false);
      const saveAssessment =
        isFormValid && (await authPostRequest('saveAssessment', requestParams));

      if (saveAssessment.resStatus === 1) {
        setSuccessMessage([saveAssessment.resMessage]);
        localStorage.setItem('successMessage', saveAssessment.resMessage);
        navigate('/assesments', {
          state: { propsToPass: { success: saveAssessment.resMessage } }
        });

        setChecked(checkedQuestions);
      } else {
        setErrorMessage([saveAssessment.resMessage]);
      }
      setOpenLoader(false);
      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage([...errorMessage, 'Error']);
    }
  };

  const [roles, setRoles] = useState([]);

  const getRoles = async () => {
    try {
      const userRoles = await getAuthRequest('getRoles');
      setRoles(userRoles);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getRoles();
    getAllAssessmentQuestions();
  }, []);

  return {
    assesmentInfo,
    setAssesmentInfo,
    handleAssesmentInfo,
    roles,
    errors,
    handleError,
    saveAssessment,
    assesmentQuestions,
    checked,
    expanded,
    handleCheck,
    handleExpand,
    checkboxTreeData,
    successMessage,
    errorMessage,
    openLoader,
    showAlertBar,
    setValidateDate,
    showAssessmentPreview,
    setShowAssessmentPreview,
    setShowAlertBar
  };
};

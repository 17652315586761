import { useState, useEffect } from 'react';
import Table from '../../components/Table';
import { Grid, IconButton, Tooltip } from '@mui/material';
import Text from 'components/Text';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogBox from '../../components/DialogBox';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import UpdateUser from './UpdateUser';
import Loader from 'components/Loader';
import { style } from 'constant/constants';
import AddIcon from '@mui/icons-material/Add';
import { getAuthRequest } from 'Api/api';
import SuccessError from 'components/SuccessError';
import CustomButton from 'components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { authPostRequest } from 'Api/api';
import { ROLES } from 'constant/enums';
import { tableOptionFilter } from 'utils/utils';
import CreateUser from './CreateUser';
import DeleteSpan from 'components/DeleteSpan';
import { addUsers } from 'reduxStore/authSlice';

const Users = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [addOpenModal, setAddOpenModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [user, setUser] = useState();
  const [users, setUsers] = useState();
  const { userData } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  //For Open Dialog for delete user
  const handleDialogOpen = (user) => {
    setUser(users[user]);
    setOpenDialog(true);
  };

  //For Open modal for update user
  const handleOpenModal = (user) => {
    setUser(users[user]);
    setOpenModal(true);
  };

  //Handle confirmation for delete
  const handleConfirm = async () => {
    try {
      const params = {
        userId: userData.userId,
        email: user.email,
        id: userData.userId
      };
      setShowLoader(true);
      setShowAlertBar(false);
      setIsDeleted(false);
      const delUser = await authPostRequest('deleteUser', params);
      setShowLoader(false);
      if (delUser.resStatus === 1) {
        setSuccessMessage([delUser.resMessage]);
        setIsDeleted(true);
      } else {
        setErrorMessage([delUser.resMessage]);
      }
      setIsFormSaved(true);

      setShowAlertBar(true);
      setOpenDialog(!openDialog);
    } catch (err) {
      setErrorMessage(['Error']);
    }
  };

  //state set from delete user
  const handleDialogState = () => {
    setOpenDialog(!openDialog);
  };

  const getUsers = async () => {
    try {
      setShowLoader(true);
      const usersList = await getAuthRequest('getUsersList');
      setShowLoader(false);

      setUsers(usersList?.usersResponses);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getUsers();
  }, [isFormSaved, isDeleted]);

  useEffect(() => {
    dispatch(addUsers(users));
  }, [users]);

  const columns = [
    {
      name: 'firstName',
      label: 'First Name',
      options: {
        filter: true,
        sort: false
      }
    },

    {
      name: 'lastName',
      label: 'Last Name',
      options: {
        filter: true,
        sort: false
      }
    },

    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false
      }
    },

    {
      name: 'role',
      label: 'Role',
      options: {
        filter: true,
        sort: false,

        customBodyRender: (value) => ROLES[value]
      }
    },

    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,

        //Actions Delete, Update, Resend Invite
        customBodyRender: (value, tableMeta) => {
          return (
            <Grid>
              {users[tableMeta.rowIndex].id !== userData.userId && (
                <>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleDialogOpen(tableMeta.rowIndex)}>
                      <DeleteForeverIcon style={{ color: '#800000' }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Update">
                    <IconButton onClick={() => handleOpenModal(tableMeta.rowIndex)}>
                      <EditIcon style={{ color: '#040273' }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}

              {!users[tableMeta.rowIndex].active && (
                <Tooltip title="Resend invite">
                  <IconButton onClick={() => sendInvite(tableMeta.rowIndex)}>
                    <EmailIcon style={{ color: '#040273' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          );
        }
      }
    }
  ];

  const sendInvite = async (rowsDeleted) => {
    const user = users[rowsDeleted];

    try {
      const requestParams = {
        userId: userData.userId,
        email: user.email
      };
      setShowLoader(true);
      setShowAlertBar(false);
      const invite = await authPostRequest('resendInvite', requestParams);

      setShowLoader(false);

      if (invite.resStatus === 1) {
        setSuccessMessage([invite.resMessage]);
      } else {
        setErrorMessage([invite.resMessage]);
      }
      setShowAlertBar(true);
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        <Text variant="h4" style={{ color: style.textColor }}>
          Users
        </Text>
        <CustomButton type="submit" endIcon={<AddIcon />} onClick={() => setAddOpenModal(true)}>
          Add
        </CustomButton>
      </Grid>

      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <Table data={users} options={tableOptionFilter} columns={columns} />

      {showLoader && <Loader open={showLoader} />}

      {openDialog && (
        <DialogBox
          handleDialogBoxState={handleDialogState}
          openDialogBox={openDialog}
          handleConfirmAction={handleConfirm}
          deleteMessage={<DeleteSpan message="user associated with email" toBeDel={user.email} />}
        />
      )}

      {openModal && (
        <UpdateUser
          openModal={openModal}
          setOpenModal={setOpenModal}
          user={user}
          setSuccess={setSuccessMessage}
          setAlertBar={setShowAlertBar}
          setIsFormSaved={setIsFormSaved}
          isFormSaved={isFormSaved}
        />
      )}

      {addOpenModal && (
        <CreateUser
          addOpenModal={addOpenModal}
          setAddOpenModal={setAddOpenModal}
          setSuccess={setSuccessMessage}
          setAlertBar={setShowAlertBar}
          setIsFormSaved={setIsFormSaved}
          isFormSaved={isFormSaved}
        />
      )}
    </>
  );
};

export default Users;

import { Rating, Grid } from '@mui/material';
import { style } from 'constant/constants';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CategoryIcon from '@mui/icons-material/Category';
import ClassIcon from '@mui/icons-material/Class';
import PropTypes from 'prop-types';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AssesmentScale from 'components/AssessmentScale';
import Text from 'components/Text';
import { stepperText } from 'utils/utils';

const categoryColor = [style.textColor, style.textGreen, '#bf9001'];

const AssessmentQuestions = ({ handleRatingChange, missingAnswer, assessmentQuestions }) => {
  const questionFunc = (question, color) => (
    <li
      style={{
        marginLeft: '30px',
        color: color,
        fontSize: '16px'
      }}
      key={question.questionId}>
      <Grid container spacing={12}>
        <Grid item xs={8} display="flex" direction="row">
          <div>
            <QuestionAnswerIcon sx={{ mr: 2 }} />
          </div>
          {question.question}
        </Grid>
        <Grid item xs={2}>
          {question.answer !== null ? (
            <Rating
              size="large"
              readOnly={question.answer !== null}
              value={parseInt(question.answer)}
              icon={<RadioButtonCheckedIcon fontSize="inherit" />}
              emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
              sx={{
                '& .MuiRating-iconFilled': {
                  color: style.textColor // Change the color as needed
                }
              }}
            />
          ) : (
            <>
              <Rating
                size="large"
                onChange={(e) => handleRatingChange(e, question.questionId)}
                sx={{
                  '& .MuiRating-iconFilled': {
                    color: style.textColor // Change the color as needed
                  },
                  // Change colors as needed
                  '& .MuiRating-iconEmpty': {
                    color: missingAnswer[question.questionId] ? 'red' : 'grey'
                  }
                }}
                icon={<RadioButtonCheckedIcon fontSize="inherit" />}
                emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
              />
            </>
          )}
        </Grid>
      </Grid>
    </li>
  );

  const subCategoryFunc = (subCategory, color) => (
    <li
      style={{
        marginLeft: '22px',
        fontSize: '18px',
        color: color
      }}
      key={subCategory.name}>
      <ClassIcon sx={{ mr: 2 }} />
      {subCategory.name}
      <ul>{subCategory.questionsList.map((question) => questionFunc(question, color))}</ul>
    </li>
  );

  const categoryFunc = (category, color) => (
    <li
      style={{
        marginLeft: '5px',
        color: color,
        fontFamily: style.fontFamily,
        fontSize: '22px'
      }}
      key={category.categoryId}>
      <CategoryIcon sx={{ mr: 2 }} />
      {category.name}
      <ul>{category.subCategoryList.map((subCategory) => subCategoryFunc(subCategory, color))}</ul>
    </li>
  );

  const nestedOrderedList = (data) => (
    <div className="mx-12">
      <AssesmentScale />

      <Text variant="p" style={{ color: style.textColor, marginTop: 10 }}>
        {stepperText}
      </Text>
      <ul style={{ marginLeft: 15, marginTop: 10 }}>
        {data.map((category) =>
          categoryFunc(category, categoryColor[category.categoryId % categoryColor.length])
        )}
      </ul>
    </div>
  );

  return <>{assessmentQuestions.length > 0 && nestedOrderedList(assessmentQuestions)}</>;
};

AssessmentQuestions.propTypes = {
  missingAnswer: PropTypes.object,
  assessmentQuestions: PropTypes.array,
  handleRatingChange: PropTypes.func
};

export default AssessmentQuestions;

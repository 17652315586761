import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

// this the minimal layout for reports but authenticated
const ReportsLayout = () => {
  const { authToken } = useSelector((store) => store.auth);
  const isAuthenticated = !!authToken;
  // TODO: better authentication may be
  if (!isAuthenticated) return <Navigate to="/login" />;
  return (
    <div style={{ padding: 16 }}>
      <Outlet />
    </div>
  );
};
export default ReportsLayout;

import AssessmentView from 'components/AssessmentView';
import SuccessMessage from 'components/SuccessMessage';
import InvalidUser from 'components/InvalidUser';
import Fill_Assessment from 'pages/assessments/FillAssessment';
import Expiry from 'components/Expiry';
import ReviewAssessment from 'pages/assessments/ReviewAssessment';
import UserDashboard from 'pages/userDashboard/UserDashBoard';
import PersonalInfo from 'pages/ProfileInfo/PersonalInfo/PersonalInfo';
import ProfessionalInfo from 'pages/ProfileInfo/ProfessionalInfo/ProfessionalInfo';
import RodgersInfo from 'pages/ProfileInfo/RodgersInfo/RodgersInfo';
import ProfileLayout from 'components/ProfileLayout';
import NotAvailable from 'components/NotAvailable';

const AssessmentsRoute = {
  path: '/',
  element: <AssessmentView />,
  children: [
    {
      path: 'fillAssessment/:queryString',
      element: <Fill_Assessment />
    },
    {
      path: 'invalid-user',
      element: <InvalidUser />
    },
    {
      path: 'success',
      element: <SuccessMessage />
    },
    {
      path: 'expiry',
      element: <Expiry />
    },
    {
      path: 'not-available',
      element: <NotAvailable />
    },
    {
      path: 'reviewAssessment/:queryString',
      element: <ReviewAssessment />
    },
    {
      path: 'dashboard',
      element: <UserDashboard />
    },
    {
      path: 'profileInfo',
      element: <ProfileLayout />,
      children: [
        {
          path: 'personalInfo',
          element: <PersonalInfo />
        },
        {
          path: 'professionalInfo',
          element: <ProfessionalInfo />
        },
        {
          path: 'rodgersInfo',
          element: <RodgersInfo />
        }
      ]
    }
  ]
};

export default AssessmentsRoute;

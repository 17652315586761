import { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import { Stack, Grid } from '@mui/material/';
import CustomButton from 'components/Button';
import AddIcon from '@mui/icons-material/Add';
import Save from '@mui/icons-material/Save';
import { authPostRequest } from 'Api/api';
import { useSelector } from 'react-redux';
import CustomDatePicker from 'components/CustomDatePicker';
import Loader from 'components/Loader';
import SuccessError from 'components/SuccessError';
import { checkTextLength, isStringEmpty } from 'utils/utils';

//Input fields
const InputFeilds = [
  {
    name: 'jobTitle',
    label: 'Job Title',
    type: 'text',
    required: true,
    placeholder: 'Enter job title'
  },
  {
    name: 'companyName',
    label: 'Company Name',
    type: 'text',
    required: true,
    placeholder: 'Enter company name'
  },
  {
    name: 'jobDescription',
    label: 'Job Description',
    type: 'text',
    placeholder: 'Enter Job Description',
    multiline: true
  }
];

const initialStateforProfileInfo = {
  companyName: '',
  jobDescription: '',
  jobTitle: '',
  startDate: new Date(),
  endDate: new Date()
};

const ProfessionalInfoForm = ({
  info,
  handleModalState,
  buttonText,
  buttonClose,
  setAlertBar,
  setSuccess,
  setError,
  setIsFormSaved,
  isFormSaved
}) => {
  const [profInfo, setProfInfo] = useState(info ? info : initialStateforProfileInfo);
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [validateDate, setValidateDate] = useState(null);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const { userData } = useSelector((store) => store.auth);

  //Error Handler for fields
  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  //Handle Proffessional info
  const handleProfInfo = (e) => {
    setProfInfo({ ...profInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    let valid = true;
    if (isStringEmpty(profInfo.jobTitle)) {
      handleError('jobTitle', 'Enter job title');
      valid = false;
    }
    const isJobTitleValid = checkTextLength(profInfo.jobTitle, 100);
    if (isJobTitleValid) {
      handleError('jobTitle', 'Maximum 100 characters allowed');
      valid = false;
    }

    if (isStringEmpty(profInfo.companyName)) {
      handleError('companyName', 'Enter company name');
      valid = false;
    }
    const isCompanyNameValid = checkTextLength(profInfo.companyName, 100);
    if (isCompanyNameValid) {
      handleError('companyName', 'Maximum 100 characters allowed.');
      valid = false;
    }
    if (profInfo.startDate instanceof Date && isNaN(profInfo.startDate.getTime())) {
      valid = false;
      setValidateDate('Please select start date');
    }

    const isTextLengthValid = checkTextLength(profInfo.jobDescription, 255);

    if (isTextLengthValid) {
      handleError('jobDescription', 'Maximum 255 characters allowed.');
      valid = false;
    }

    if (!profInfo.startDate || !profInfo.endDate) {
      valid = false;
    }

    if (validateDate !== null) {
      valid = false;
    }
    if (valid) {
      return true;
    }
    return false;
  };

  //======================= Save and add more ======================//
  const saveAndAddMore = async (e) => {
    e.preventDefault();
    setSuccess([]);
    setError([]);
    await saveProfessional();
  };

  //=============================== Save and close ====================//
  const saveAndClose = async (e) => {
    e.preventDefault();
    setSuccess([]);
    setError([]);
    setAlertBar(false);
    await saveProfessional(true); // passing true to indicate closing of dialogue after success
    setAlertBar(true);
  };

  //====================== Save API ==========================//
  const saveProfessional = async (close) => {
    setShowAlertBar(false);
    setSuccessMessage([]);
    setErrorMessage([]);
    const requestParams = {
      userId: userData.userId,
      id: userData.userId,
      jobTitle: profInfo.jobTitle?.trim(),
      companyName: profInfo.companyName?.trim(),
      jobDescription: profInfo.jobDescription?.trim(),
      startDate: profInfo.startDate,
      endDate: profInfo.endDate,
      professionalDetailId: info.professionalDetailId ? info.professionalDetailId : ''
    };
    setShowLoader(true);
    if (handleSubmit()) {
      const addProfessionalDetails = await authPostRequest(
        'saveProfessionalDetails',
        requestParams
      );
      if (addProfessionalDetails?.resStatus === 1) {
        setSuccessMessage([addProfessionalDetails.resMessage]);
        setProfInfo(initialStateforProfileInfo);
        setShowAlertBar(true);
        setShowLoader(false);
        if (close) {
          handleModalState();
          setSuccess([addProfessionalDetails.resMessage]);
        }
        setIsFormSaved(!isFormSaved);
        return true;
      } else {
        setErrorMessage([addProfessionalDetails?.resMessage]);
        return false;
      }
    } else {
      setShowLoader(false);
      return false;
    }
  };

  return (
    <Grid sx={{ width: '100%' }}>
      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <div className="mx-6">
        <form>
          {InputFeilds.map(({ name, label, placeholder, type, required, multiline }) => (
            <Input
              sx={{ marginTop: '2vh' }}
              key={name}
              required={required}
              type={type}
              placeholder={placeholder}
              onChange={handleProfInfo}
              value={profInfo[name]}
              multiline={multiline}
              label={label}
              onFocus={() => handleError(name, null)}
              name={name}
              error={errors[name]}
            />
          ))}

          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <CustomDatePicker
              maxDate={profInfo.endDate}
              disableFuture
              dateLabel="Start Date"
              name="startDate"
              onChange={(e) => setProfInfo({ ...profInfo, startDate: e })}
              validate={setValidateDate}
              value={new Date(profInfo.startDate)}
              required={true}
            />

            <CustomDatePicker
              minDate={profInfo.startDate}
              disableFuture
              dateLabel="End Date"
              name="endDate"
              onChange={(e) => setProfInfo({ ...profInfo, endDate: e })}
              validate={setValidateDate}
              value={new Date(profInfo.endDate)}
              required={true}
            />
          </Grid>

          <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mr: '5vh' }}>
            {buttonClose !== 'Save' && (
              <CustomButton type="submit" endIcon={<AddIcon />} onClick={saveAndAddMore}>
                {buttonText}
              </CustomButton>
            )}

            <CustomButton
              type="button"
              endIcon={<Save />}
              buttonClose={buttonClose}
              onClick={saveAndClose}>
              {buttonClose}
            </CustomButton>
          </Stack>
        </form>
      </div>
      {showLoader && <Loader open={showLoader} />}
    </Grid>
  );
};

ProfessionalInfoForm.propTypes = {
  info: PropTypes.object,
  handleModalState: PropTypes.func,
  buttonText: PropTypes.string,
  buttonClose: PropTypes.string,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  isFormSaved: PropTypes.bool,
  setIsFormSaved: PropTypes.func
};
export default ProfessionalInfoForm;

import { Paper, Stack, Grid } from '@mui/material';
import Text from 'components/Text';
import { useReviewAssessmentHook } from './useReviewAssessmentHook';
import Loader from 'components/Loader';
import { stringDateFormatting } from 'utils/dateFormatter';
import AssessmentQuestions from './AssessmentQuestions';
import { useRef } from 'react';
import Logo from '../../assets/images/auth/logo.png';
import CustomButton from 'components/Button';
import { style } from 'constant/constants';
import '../../assets/css/PrintStyles.css';
import { useReactToPrint } from 'react-to-print';
import DownloadIcon from '@mui/icons-material/Download';

const textStyles = { color: style.textGreen, fontSize: 18 };

const ShareAssessment = () => {
  const { assessmentQuestions, empAssessmentData, showLoader, assessmentReviews } =
    useReviewAssessmentHook();

  const pdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current
  });

  return (
    <div>
      <Paper
        sx={{
          p: 2,
          my: 5,
          mx: 25,
          maxWidth: '100%',
          flexGrow: 1
        }}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-end">
          <CustomButton onClick={handlePrint} className="text-right" endIcon={<DownloadIcon />}>
            Generate PDF
          </CustomButton>
        </Grid>

        <div ref={pdfRef}>
          <div className="text-xs uppercase tracking-normal font-normal w-full my-9">
            <div className="flex justify-between px-9">
              <div className="flex items-center font-bold">
                <img src={Logo} alt="logo" className="h-10 w-auto mr-4" />
              </div>
            </div>
          </div>

          <div className="text-center text-menu-color-teal">
            <Text variant="h4">{empAssessmentData.name}</Text>

            <Text>{empAssessmentData.description}</Text>

            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mr: '5vh' }}>
              <Text sx={{ mr: 15 }}>Valid Until: </Text>
              <Text sx={{ mr: 15 }}>{stringDateFormatting(empAssessmentData.validUntil)}</Text>
            </Stack>
          </div>

          <Stack
            direction="row"
            alignItems="right"
            justifyContent="left"
            sx={{ ml: '5vh', mt: '3vh' }}>
            <Text variant="h6" style={textStyles}>
              Employee: {empAssessmentData.employeeName}
            </Text>
          </Stack>
          <Stack
            direction="row"
            alignItems="right"
            justifyContent="left"
            sx={{ ml: '5vh', mt: '1vh' }}>
            <Text style={textStyles}>Submit Date:&nbsp;</Text>
            <Text style={{ color: style.textGreen }}>
              {stringDateFormatting(empAssessmentData.submittedDate)}
            </Text>
          </Stack>

          <Stack
            direction="row"
            alignItems="right"
            justifyContent="left"
            sx={{ ml: '5vh', mt: '3vh' }}>
            <Text variant="h5" style={textStyles}>
              Score: {empAssessmentData.score}%
            </Text>
          </Stack>

          <br />
          <AssessmentQuestions assessmentQuestions={assessmentQuestions} />

          {assessmentReviews.length > 0 && (
            <Paper sx={{ p: 4, border: `1px solid ${style.textColor}`, mt: 4, mr: 4, ml: 6 }}>
              <div className="text-menu-color-teal">
                <Text variant="h5">Reviews</Text>
              </div>
              {assessmentReviews.map((review) => {
                return (
                  <Stack key={review.reviewerId} sx={{ mx: 4, mb: 2 }}>
                    <Text style={{ color: style.textColor }}>
                      <b>Reviews:</b> {review.reviews}
                    </Text>
                    <Stack direction="row" spacing={2}>
                      <Text style={{ fontSize: 12, color: style.textColor }}>
                        Reviewer: <i>{review.reviewerName}</i>
                      </Text>
                      <Text style={{ fontSize: 12, color: style.textColor }}>
                        Review Date: <i>{stringDateFormatting(review.reviewDate)}</i>
                      </Text>
                    </Stack>
                  </Stack>
                );
              })}
            </Paper>
          )}
        </div>

        {showLoader && <Loader open={showLoader} />}
      </Paper>
    </div>
  );
};

export default ShareAssessment;

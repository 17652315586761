import { Grid, Avatar, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Input from 'components/Input';
import CustomDatePicker from 'components/CustomDatePicker';
import { authPostRequest, getAuthRequest } from 'Api/api';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import CustomDropdown from 'components/CustomDropdown';
import CustomButton from 'components/Button';
import Save from '@mui/icons-material/Save';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SuccessError from 'components/SuccessError';
import Loader from 'components/Loader';
import { usFormatPhoneNumber } from 'utils/us-phoneFormat';
import { getFilteredValueByName, isStringEmpty } from 'utils/utils';
import { updateUser } from 'reduxStore/authSlice';
import { style } from 'constant/constants';
import Text from 'components/Text';
import ErrorForSelect from 'components/ErrorForSelect';

const PersonInputFeilds = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    placeholder: 'Enter first name',
    required: true
  },

  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    placeholder: 'Enter last name',
    required: true
  },

  {
    name: 'cell',
    label: 'Cell',
    type: 'text',
    placeholder: 'Enter your phone number'
  }
];

const AddressInputFeilds = [
  {
    name: 'street1',
    label: 'Street 1',
    type: 'text',
    placeholder: 'Enter Street 1'
  },

  {
    name: 'street2',
    label: 'Street 2',
    type: 'text',
    placeholder: 'Enter Street 2'
  },
  {
    name: 'zip',
    label: 'Zip',
    type: 'number',
    placeholder: 'Enter Zip'
  }
];

const PersonalInfo = () => {
  const { userData } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const [personalInfo, setPersonalInfo] = useState({});
  const [sizeError, setSizeError] = useState();
  const [cities, setCities] = useState([]);
  const [gender, setGender] = useState([]);
  const [states, setStates] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errors, setErrors] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const [oldUrl, setOldUrl] = useState('');
  const [image, setImage] = useState();

  const getGenderList = async () => {
    try {
      const genders = await getAuthRequest('getGenderList');
      setGender(genders);
    } catch (err) {
      setErrorMessage([err]);
    }
  };

  const getUSStates = async () => {
    try {
      const usStates = await getAuthRequest('getUSStates');
      setStates(usStates);
    } catch (err) {
      setErrorMessage([err]);
    }
  };

  const getCitiesList = async (params) => {
    try {
      const citiesList = await authPostRequest('getCitiesList', params);
      setCities(citiesList);
    } catch (err) {
      setErrorMessage([err]);
    }
  };

  const getPersonalInfo = async () => {
    try {
      setOpenLoader(true);
      setIsDataReady(false);
      const personInfo = await authPostRequest('getPersonalInfo', { userId: userData.userId });
      setOpenLoader(false);
      setIsDataReady(true);

      if (personInfo.resStatus === 1) {
        setOldUrl(personInfo.avatar);
        setPersonalInfo(personInfo);
        console.log('old url', oldUrl);
      } else {
        setErrorMessage(['Error']);
      }
      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage([err]);
    }
  };

  const onValueChange = (e) => {
    if (e.target.name === 'cell') {
      const formattedNumber = usFormatPhoneNumber(e.target.value);

      if (formattedNumber === null) {
        handleError('cell', 'the length of phone number is 10');
      }
      setPersonalInfo({ ...personalInfo, [e.target.name]: formattedNumber });
    }

    if (e.target.name === 'stateName') {
      const stateId = getFilteredValueByName(states, e.target.value)[0].id;
      getCitiesList({ lookupGroupId: stateId });
    }

    setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
    handleError([e.target.name], null);
  };

  useEffect(() => {
    getPersonalInfo();
    getGenderList();
    getUSStates();
  }, []);

  useEffect(() => {
    const params = { lookupGroupId: personalInfo.state };
    getCitiesList(params);
  }, [isDataReady]);

  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const validateData = () => {
    let isValid = true;
    if (isStringEmpty(personalInfo.firstName)) {
      handleError('firstName', 'Please enter first name');
      isValid = false;
    }
    if (sizeError) {
      isValid = false;
    }

    if (personalInfo.dob instanceof Date && isNaN(personalInfo.dob.getTime())) {
      isValid = false;
    }

    // Check if personalInfo.dob is equal to the current date (ignoring the time)
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (
      personalInfo.dob instanceof Date &&
      personalInfo.dob.setHours(0, 0, 0, 0) >= today.getTime()
    ) {
      isValid = false;
    }

    if (isStringEmpty(personalInfo.lastName)) {
      handleError('lastName', 'Please enter last name');
      isValid = false;
    }
    return isValid;
  };

  const savePersonalInfo = async () => {
    setOpenLoader(true);
    const isDataValid = validateData();
    let uploadedImage = '';

    if (isDataValid) {
      if (image) {
        if (oldUrl) {
          const deleteImage = await authPostRequest('deleteFile', oldUrl);
          console.log('deleteddddd', deleteImage);
        }
        const formData = new FormData();
        formData.append('file', image);
        uploadedImage = await authPostRequest('uploadImageToS3', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        setPersonalInfo({ ...personalInfo, avatar: uploadedImage });
        setOldUrl(uploadedImage);
      }

      setShowAlertBar(false);
      setSuccessMessage([]);
      setErrorMessage([]);

      const genderId = getFilteredValueByName(gender, personalInfo.genderName)[0]?.id;
      const stateId = getFilteredValueByName(states, personalInfo.stateName)[0]?.id;
      const cityId = getFilteredValueByName(cities, personalInfo.cityName)[0]?.id;

      const requestParams = {
        userId: userData.userId,
        id: userData.userId,
        firstName: personalInfo.firstName?.trim(),
        lastName: personalInfo.lastName?.trim(),
        cell: personalInfo.cell?.trim(),
        dob: personalInfo.dob,
        gender: genderId,
        state: stateId,
        city: cityId,
        street1: personalInfo.street1?.trim(),
        street2: personalInfo.street2?.trim(),
        zip: personalInfo.zip,
        avatar: uploadedImage !== '' ? uploadedImage : personalInfo.avatar
      };

      try {
        setOpenLoader(true);
        const savedInfo = await authPostRequest('savePersonalInfo', requestParams);
        setOpenLoader(false);
        if (savedInfo.resStatus === 1) {
          setSuccessMessage([savedInfo.resMessage]);
          dispatch(updateUser(personalInfo));
        } else {
          setErrorMessage([savedInfo.resMessage]);
        }

        setShowAlertBar(true);
      } catch (err) {
        setErrorMessage([err]);
      }
    }
    setOpenLoader(false);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log('file', file);
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (
        fileExtension === 'png' ||
        fileExtension === 'img' ||
        fileExtension === 'jpg' ||
        fileExtension === 'jpeg'
      ) {
        if (file.size >= 500 * 1025) {
          setSizeError('Please select an image that is no larger than 500KB.');
        } else {
          setOpenLoader(true);
          setSizeError('');
          setOpenLoader(true);
          setImage(file);
          const fileUrl = URL.createObjectURL(file);
          console.log('url', file);
          setPersonalInfo({ ...personalInfo, avatar: fileUrl });
          setOpenLoader(false);
        }
      }
    }
  };

  const today = new Date();
  const previousDate = new Date(today);
  previousDate.setDate(today.getDate() - 1);

  return (
    <>
      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <Paper
        sx={{
          p: 2,
          margin: 4,
          maxWidth: '100%',
          flexGrow: 1,
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff')
        }}>
        <Grid container>
          <Grid item sx={{ width: 200 }} alignItems="center" justifyContent="center">
            <Avatar sx={{ width: '80%', height: '20vh' }} src={personalInfo.avatar} />

            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              ref={inputRef}
            />
            <Text style={{ color: 'grey', fontSize: '10px', fontFamily: style.fontFamily }}>
              Please upload an image in one of these formates:.png, .jpg, .bmp, .tif, or .webp
            </Text>
            {sizeError && <ErrorForSelect>{sizeError}</ErrorForSelect>}
            <CustomButton
              endIcon={<AccountCircleIcon />}
              sx={{ mt: '3vh', ml: 0, width: '80%' }}
              onClick={handleClick}>
              Add Picture
            </CustomButton>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2} className="border-l rounded-sm">
              <Grid item xs>
                {PersonInputFeilds.map(({ name, type, placeholder, label, required }) => (
                  <Stack key={name} direction="row" sx={{ marginBottom: '5vh' }}>
                    <Input
                      sx={{ width: '80%' }}
                      type={type}
                      placeholder={placeholder}
                      onChange={onValueChange}
                      value={personalInfo[name]}
                      required={required}
                      label={label}
                      onFocus={() => handleError(name, null)}
                      name={name}
                      error={errors[name]}
                    />
                  </Stack>
                ))}
                <CustomDatePicker
                  dateLabel="Date of birth"
                  name="dob"
                  onChange={(e) => setPersonalInfo({ ...personalInfo, dob: e })}
                  validate={() => {}}
                  value={new Date(personalInfo.dob)}
                  disableFuture
                  maxDate={previousDate}
                />
                <CustomDropdown
                  value={personalInfo?.genderName ?? null}
                  onChange={onValueChange}
                  list={gender}
                  name="genderName"
                  label="Gender"
                />
                <CustomDropdown
                  value={personalInfo?.stateName ?? null}
                  onChange={onValueChange}
                  list={states}
                  name="stateName"
                  label="State"
                />
                <CustomDropdown
                  value={personalInfo?.cityName ?? null}
                  onChange={onValueChange}
                  list={cities}
                  name="cityName"
                  label="City"
                  disabled={!personalInfo?.stateName}
                />
                {AddressInputFeilds.map(({ name, type, placeholder, label, required }) => (
                  <Stack key={name} direction="row" sx={{ marginBottom: '5vh' }}>
                    <Input
                      sx={{ width: '80%' }}
                      type={type}
                      placeholder={placeholder}
                      onChange={onValueChange}
                      value={personalInfo[name]}
                      required={required}
                      label={label}
                      onFocus={() => handleError(name, null)}
                      name={name}
                      error={errors[name]}
                    />
                  </Stack>
                ))}
                <CustomButton endIcon={<Save />} onClick={savePersonalInfo}>
                  Save
                </CustomButton>
                {openLoader && <Loader open={openLoader} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
export default PersonalInfo;

import { Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { authPostRequest } from 'Api/api';
import CustomButton from 'components/Button';
import Loader from 'components/Loader';
import SuccessError from 'components/SuccessError';
import Table from 'components/Table';
import Text from 'components/Text';
import { style } from 'constant/constants';
import { ROLES } from 'constant/enums';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatter } from 'utils/dateFormatter';
import { isDateExpired, tableOptionFilter, columOptions } from 'utils/utils';
import { Assignment } from '@mui/icons-material';
import ConfirmationBox from 'components/ConfirmationBox';

const options = {
  ...tableOptionFilter,
  selectableRows: true,
  selectToolbarPlacement: 'none'
};

const columns = [
  {
    name: 'firstName',
    label: 'First Name',
    options: {
      ...columOptions,
      display: false
    }
  },

  {
    name: 'lastName',
    label: 'Last Name',
    options: {
      ...columOptions,
      display: false
    }
  },

  {
    name: 'name',
    label: 'Employee',
    options: {
      ...columOptions,
      customBodyRender: (_, tableMeta) => tableMeta.rowData[1] + ' ' + tableMeta.rowData[0]
    }
  },
  {
    name: 'email',
    label: 'Email',
    options: columOptions
  },

  {
    name: 'score',
    label: 'Score',
    options: columOptions
  },

  {
    name: 'submittedDate',
    label: 'Submit Date',
    options: {
      ...columOptions,
      customBodyRender: (value) => {
        return dateFormatter(value);
      }
    }
  }
];

const columns_users = [
  {
    name: 'firstName',
    label: 'First Name',
    options: {
      ...columOptions,
      display: false
    }
  },

  {
    name: 'lastName',
    label: 'Last Name',
    options: {
      ...columOptions,
      display: false
    }
  },

  {
    name: 'name',
    label: 'Name',
    options: {
      ...columOptions,
      customBodyRender: (_, tableMeta) => tableMeta.rowData[1] + ' ' + tableMeta.rowData[0]
    }
  },

  {
    name: 'email',
    label: 'Email',
    options: columOptions
  },

  {
    name: 'role',
    label: 'Role',
    options: {
      ...columOptions,
      customBodyRender: (value) => ROLES[value]
    }
  }
];

const AssignReviewers = () => {
  const [selectedAssessment, setSelectedAssessment] = useState({
    id: '',
    name: ''
  });
  const [filteredUsers, setFilteredUsers] = useState();
  const [filledAssessmentsList, setFilledAssessmentsList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectIndex, setSelectIndex] = useState([]);
  const [selectIndexOption, setSelectIndexOption] = useState([]);
  const [selectedfilledAssessments, setSelectedFilledAssessments] = useState([]);
  const [disableAssignBtn, setDisableAssignBtn] = useState(true);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [targeValue, setTargetValue] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const { userData, publishAssessments, allUsers } = useSelector((store) => store.auth);
  const filteredPublishedAssessments = publishAssessments.filter(
    (ass) => !isDateExpired(ass.validUntil)
  );

  /**
   * @param {number} id of selected assessment
   * @returns name of selected assessment
   */
  const getNameOfSelectedAssessment = (id) => {
    const name = filteredPublishedAssessments
      .filter((ass) => ass.assessmentId === id)
      .map((ass) => ass.name);
    return name[0];
  };

  /**
   * clears states after assignment of reviewers
   * or when changing selected ass after selection is made in both tables
   * @param {boolean} clearSelectedAssessment
   */
  const clearStates = (clearSelectedAssessment) => {
    setFilledAssessmentsList([]);
    setSelectIndexOption([]);
    setSelectIndex([]);
    setSelectedUsers([]);
    if (clearSelectedAssessment) {
      setSelectedAssessment({
        id: '',
        name: ''
      });
    }
  };

  const handleAssessemntSelection = async (event) => {
    const id = event.target.value;
    if (selectIndexOption.length > 0 || selectIndex.length > 0) {
      setShowAlert(true);
      setTargetValue(event.target.value);
    } else {
      setSelectedAssessment((prevState) => {
        return { ...prevState, id: id, name: getNameOfSelectedAssessment(id) };
      });
    }
  };

  const getfilledAssessments = async () => {
    setShowAlertBar(false);
    try {
      setShowLoader(true);
      const filledAssessments = await authPostRequest('getFilledAssessments', {
        assessmentId: selectedAssessment.id
      });
      setShowLoader(false);

      if (filledAssessments.resStatus === 1) {
        setErrorMessage([]);

        setFilledAssessmentsList(filledAssessments.userAssessmentResponseList);
      } else {
        setErrorMessage([filledAssessments.resMessage]);
        setFilledAssessmentsList([]);
      }
    } catch (err) {
      setFilledAssessmentsList([]);
    }
  };

  // for disabling assign reviewrs button
  useEffect(() => {
    if (selectedUsers?.length > 0 && selectedfilledAssessments?.length > 0) {
      setDisableAssignBtn(false);
    } else {
      setDisableAssignBtn(true);
    }
  }, [selectedfilledAssessments, selectedUsers]);

  // this effect updates the users table when selected assessment type changes
  useEffect(() => {
    if (selectedAssessment.id) {
      // get filled assessments
      getfilledAssessments();
      setDisableAssignBtn(true);
    }
  }, [selectedAssessment]);

  useEffect(() => {
    // do not show users when there is no data in assessments table
    if (filledAssessmentsList?.length === 0) setFilteredUsers([]);

    if (selectedAssessment.id && filledAssessmentsList.length > 0) {
      // filterout users to whom assessment was assigned
      const reviewers = allUsers.filter(
        (userData) => !filledAssessmentsList?.some((user) => user.userId === userData.id)
      );
      setFilteredUsers(reviewers);
    }
  }, [filledAssessmentsList]);

  //To confirm the alert
  const confirmHandler = () => {
    setSelectedAssessment((prevState) => {
      return { ...prevState, id: targeValue, name: getNameOfSelectedAssessment(targeValue) };
    });
    clearStates(); // clearing states excluding selectedAssessment
    setShowAlert(false);
  };

  const handleAssignReviewers = async () => {
    setShowAlertBar(false);
    setSuccessMessage([]);
    setErrorMessage([]);
    const requestParams = {
      userId: userData.userId,
      userAssessmentsList: selectedfilledAssessments.map((filledAssessment) => {
        return { userAssessmentId: filledAssessment.user_assessmentId };
      }),
      usersList: selectedUsers.map((selectedUser) => {
        return { id: selectedUser.id };
      })
    };

    try {
      setShowLoader(true);
      const response = await authPostRequest('assignReviewer', requestParams);

      if (response.resStatus === 1) {
        setSuccessMessage(response.success);
        setErrorMessage(response.error);
        clearStates(true); // clearing states including selectedAssessment
      } else {
        throw new Error(response.resMessage);
      }
      setShowAlertBar(true);
      setShowLoader(false);
    } catch (err) {
      console.debug(err);
      setErrorMessage(err);
      setShowAlertBar(true);
    }
  };

  const rowSelectionHandler = (array, setArray, rowsSelected, indexSettingFunc) => {
    setArray(rowsSelected?.map((row) => array[row]));
    indexSettingFunc(rowsSelected?.map((row) => row));
  };

  const assessmentsTableOptions = {
    rowsSelected: selectIndex,
    ...options,

    onRowSelectionChange: (_, allRowsSelected, rowsSelected) =>
      rowSelectionHandler(
        filledAssessmentsList,
        setSelectedFilledAssessments,
        rowsSelected,
        setSelectIndex
      )
  };

  const usersTableOptions = {
    ...options,
    rowsSelected: selectIndexOption,
    onRowSelectionChange: (_, allRowsSelected, rowsSelected) =>
      rowSelectionHandler(filteredUsers, setSelectedUsers, rowsSelected, setSelectIndexOption)
  };

  return (
    <Grid>
      <Text variant="h4" style={{ color: style.textColor }}>
        Assign Reviewers
      </Text>

      {showLoader && <Loader open={showLoader} />}

      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <Grid container justifyContent="flex-end" alignItems="center">
        {showAlert && (
          <ConfirmationBox
            openConfirmationBox={showAlert}
            setOpenConfirmationBox={setShowAlert}
            handleYes={confirmHandler}
            title="Change the selected assessment?"
            message={
              <Text>
                Are you sure you want to change the selected assessment? Changing the selected
                assessment will result in the loss of any unsaved data and selections. Are you sure
                you want to proceed with changing the assessment?
              </Text>
            }
          />
        )}
        <Grid item xs={12}>
          <Stack sx={{ mt: '3vh' }}>
            <InputLabel
              htmlFor="demo-simple-select-label"
              sx={{
                fontFamily: style.fontFamily,
                color: style.textGreen,
                marginBottom: 1
              }}
              className="text-menu-color-green">
              Select Assessment <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <Select
              value={selectedAssessment.id}
              onChange={handleAssessemntSelection}
              style={{ height: '40px' }}
              name="name">
              {filteredPublishedAssessments?.length > 0 &&
                filteredPublishedAssessments.map(({ name, assessmentId }) => {
                  return (
                    <MenuItem value={assessmentId} key={assessmentId}>
                      {name}
                    </MenuItem>
                  );
                })}
            </Select>
          </Stack>
        </Grid>
        <CustomButton
          onClick={handleAssignReviewers}
          endIcon={<Assignment />}
          disabled={disableAssignBtn}>
          Assign Reviewers
        </CustomButton>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item md={12} lg={6}>
            <Text variant="h5" style={{ color: style.textColor, marginBottom: 8 }}>
              Select Filled Assessments
            </Text>
            <Table
              data={filledAssessmentsList}
              columns={columns}
              options={assessmentsTableOptions}
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <Text variant="h5" style={{ color: style.textColor, marginBottom: 8 }}>
              Select Reviewers
            </Text>
            <Table data={filteredUsers} columns={columns_users} options={usersTableOptions} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssignReviewers;

import { Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useState, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import CustomButton from 'components/Button';
import { authPostRequest } from 'Api/api';
import SuccessError from 'components/SuccessError';
import { useSelector } from 'react-redux';
import { style } from 'constant/constants';
import UserForm from 'components/UserForm';
import { emailValidation } from 'utils/email_strength';
import ModalComp from 'components/Modal';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import CenteredWrapper from 'pages/updatePassword/CenteredWrapper';
import { checkMinLength, checkName, checkTextLength, isStringEmpty } from 'utils/utils';
import { ROLE } from 'constant/enums';
import Papa from 'papaparse';

const CreateUser = ({
  addOpenModal,
  setAddOpenModal,
  setSuccess,
  setAlertBar,
  setIsFormSaved,
  isFormSaved
}) => {
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [fileLabel, setFileLabel] = useState('No file chosen');
  const [validationErrors, setValidationErrors] = useState([]);
  const [bulkUsers, setBulkUsers] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', email: '', role: '' });
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errors, setErrors] = useState({});
  const [isFileSelected, setIsFileSelected] = useState(false);
  const inputRef = useRef(null);

  const { userData } = useSelector((store) => store.auth);

  const handleUserInfo = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    handleError(e.target.name, null);
  };

  //=========================  Validation for bulk user ==============================//
  const validateCSV = (data) => {
    const errors = [];

    data.forEach((user, index) => {
      if (!user.firstName) {
        errors.push(`Row ${index + 1}: Please enter first Name.`);
      }

      const firstNameMinLength = checkMinLength(user.firstName, 2);
      if (firstNameMinLength) {
        errors.push(`Row ${index + 1}: Please enter at least 2 characters.`);
      }

      const firstNameValid = checkTextLength(user.firstName, 50);
      if (firstNameValid) {
        errors.push(`Row ${index + 1}: Maximum 50 characters allowed.`);
      }

      const firstNameCheck = checkName(user.firstName);
      if (firstNameCheck) {
        errors.push(
          `Row ${
            index + 1
          }: Please use alphabetic and international characters only for the first name.`
        );
      }

      if (!user.lastName) {
        errors.push(`Row ${index + 1}: Please enter last name.`);
      }
      const lastNameMinLength = checkMinLength(user.lastName, 2);
      if (lastNameMinLength) {
        errors.push(`Row ${index + 1}: Please enter at least 2 characters.`);
      }

      const lasNameValid = checkTextLength(user.lastName, 50);
      if (lasNameValid) {
        errors.push(`Row ${index + 1}: Maximum 50 characters allowed.`);
      }

      const lastNameCheck = checkName(user.lastName);
      if (lastNameCheck) {
        errors.push(
          `Row ${
            index + 1
          }: Please use alphabetic and international characters only for the last name.`
        );
      }
      const emailvalid = emailValidation(user.email);
      if (emailvalid !== '') {
        errors.push(`Row ${index + 1}: ${emailvalid}`);
      }

      if (!user.role) {
        errors.push(`Row ${index + 1}: Please select role`);
      }
      const role = ROLE.includes(user.role?.split('\r')[0]);
      if (user.role && !role) {
        errors.push(`Row ${index + 1}: role format is incorrect`);
      }
    });

    return errors;
  };

  //=================== File change handler ===============//
  const handleFileChange = (e) => {
    setIsFileSelected(false);
    const file = e.target.files[0];

    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'csv') {
        setIsFileSelected(true);
        setValidationErrors([]);
        setFileLabel(file.name);
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            setBulkUsers(result.data);
          }
        });
      }
    }
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const updateHandler = () => {
    //===================   bulk user validation  =====================//
    if (isBulkUpload) {
      setValidationErrors(validateCSV(bulkUsers));
      const valid = validateCSV(bulkUsers);

      if (valid.length > 0) {
        inputRef.current.value = '';
      }

      if (bulkUsers.length > 0 && !valid.length > 0) {
        return true;
      } else if (bulkUsers.length < 1 && !isFileSelected) {
        setValidationErrors(['Please select CSV file']);
        return false;
      } else if (bulkUsers.length < 1 && isFileSelected) {
        setValidationErrors([
          'The file you uploaded is empty. Please upload file with valid data.'
        ]);
        return false;
      } else return false;
    }

    setValidationErrors([]);
    // Single user validations
    if (isStringEmpty(userInfo.firstName)) {
      handleError('firstName', 'Please enter first name');
    }

    const firstNameMinLength = checkMinLength(userInfo.firstName, 2);
    if (firstNameMinLength) {
      handleError('firstName', 'Please enter at least 2 characters.');
    }
    const firstNameValid = checkTextLength(userInfo.firstName, 50);
    if (firstNameValid) {
      handleError('firstName', 'Maximum 50 characters allowed');
    }

    const firstNameCheck = checkName(userInfo.firstName);
    if (firstNameCheck) {
      handleError(
        'firstName',
        'Please use alphabetic and international characters only for the name '
      );
    }

    if (isStringEmpty(userInfo.lastName)) {
      handleError('lastName', 'Please enter last name');
    }

    const lastNameMinLength = checkMinLength(userInfo.lastName, 2);
    if (lastNameMinLength) {
      handleError('lastName', 'Please enter at least 2 characters.');
    }
    const lastNameValid = checkTextLength(userInfo.lastName, 50);
    if (lastNameValid) {
      handleError('lastName', 'Maximum 50 characters allowed');
    }

    const lastNameCheck = checkName(userInfo.lastName);
    if (lastNameCheck) {
      handleError(
        'lastName',
        'Please use alphabetic and international characters only for the name'
      );
    }

    if (!userInfo.role) {
      handleError('role', 'Please select role');
    }

    const emailvalid = emailValidation(userInfo.email);
    handleError('email', emailvalid);

    if (
      userInfo.role &&
      emailvalid === '' &&
      !isStringEmpty(userInfo.firstName) &&
      !isStringEmpty(userInfo.lastName) &&
      !firstNameCheck &&
      !lastNameCheck &&
      !firstNameValid &&
      !lastNameValid &&
      !firstNameMinLength &&
      !lastNameMinLength
    ) {
      setErrors({});
      return true;
    }

    return false;
  };

  //save and add more
  const saveAndAddMore = async (e) => {
    e.preventDefault();
    setSuccess([]);
    const createUser = await handleSubmit();
    if (createUser?.resStatus === 1) {
      setSuccessMessage(createUser.success);
      setErrorMessage(createUser.error);
      if (isBulkUpload) {
        inputRef.current.value = '';
      }
      setIsFormSaved(!isFormSaved);
      setUserInfo({ firstName: '', lastName: '', email: '', role: '' });
    }
  };

  //Save and close
  const saveAndClose = async (e) => {
    e.preventDefault();
    setErrorMessage([]);
    setSuccess([]);
    setAlertBar(false);
    const createUser = await handleSubmit();
    if (createUser?.resStatus === 1) {
      if (createUser.error.length > 0) {
        setSuccessMessage(createUser.success);
        setErrorMessage(createUser.error);
        inputRef.current.value = '';
      } else {
        setSuccess(createUser.success);
      }
      setIsFormSaved(!isFormSaved);
      if (createUser.error.length === 0) {
        setAddOpenModal(false);
      }

      setUserInfo({ firstName: '', lastName: '', email: '', role: '' });
    }

    setAlertBar(true);
  };

  const handleSubmit = async () => {
    setErrorMessage([]);
    setSuccessMessage([]);
    const isFormValid = updateHandler();
    try {
      const requestParams = isBulkUpload
        ? { userId: userData.userId, isBulkUpload: 1, bulkUsers }
        : {
            userId: userData.userId,
            firstName: userInfo.firstName.trim(),
            lastName: userInfo.lastName.trim(),
            email: userInfo.email.trim(),
            role: userInfo.role,
            isBulkUpload: 0,
            bulkUsers: []
          };

      setShowLoader(true);
      setShowAlertBar(false);
      const createBulkUsers = isFormValid && (await authPostRequest('createUser', requestParams));

      setShowLoader(false);
      setShowAlertBar(true);
      return createBulkUsers;
    } catch (err) {
      setErrorMessage([...errorMessage, 'Error']);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <CenteredWrapper>
      <ModalComp open={addOpenModal} setOpen={setAddOpenModal} header="Create User">
        {showAlertBar && (
          <SuccessError
            successMessage={successMessage}
            errorMessage={errorMessage}
            setShowAlertBar={setShowAlertBar}
          />
        )}

        {showLoader && <Loader open={showLoader} />}

        <Stack spacing={2} sx={{ marginLeft: 5 }}>
          <FormControlLabel
            control={<Checkbox color="success" />}
            sx={{
              marginTop: '3vh'
            }}
            style={{ fontFamily: style.fontFamily }}
            label="Bulk User Creation"
            onChange={() => {
              setIsBulkUpload(!isBulkUpload);
              setErrors({});
            }}
            className="text-menu-color-teal"
          />

          {isBulkUpload && (
            <>
              <Stack direction="row">
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept=".csv"
                  style={{ display: 'none' }}
                  ref={inputRef}
                />
                <CustomButton endIcon={<UploadFileIcon />} sx={{ margin: 0 }} onClick={handleClick}>
                  Upload CSV File
                </CustomButton>
                <label
                  style={{
                    margin: '1vh',
                    color: style.textGreen,
                    fontFamily: style.fontFamily
                  }}>
                  {fileLabel}
                </label>
              </Stack>
              <div className="validation-errors">
                {validationErrors.map((error, index) => (
                  <div key={index} className="text-red-600">
                    {error}
                  </div>
                ))}
              </div>
            </>
          )}
        </Stack>

        <UserForm
          handleSubmit={saveAndAddMore}
          buttonText="Save & Add More"
          buttonClose="Save & Close"
          handleError={handleError}
          handleCancelAction={saveAndClose}
          handleUserInfo={handleUserInfo}
          isBulkUpload={isBulkUpload}
          userInfo={userInfo}
          error={errors}
        />
      </ModalComp>
    </CenteredWrapper>
  );
};

CreateUser.propTypes = {
  addOpenModal: PropTypes.bool,
  isFormSaved: PropTypes.bool,
  setIsFormSaved: PropTypes.func,
  setAddOpenModal: PropTypes.func,
  setSuccess: PropTypes.func,
  setAlertBar: PropTypes.func
};

export default CreateUser;

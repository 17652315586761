import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CategoryIcon from '@mui/icons-material/Category';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ClassIcon from '@mui/icons-material/Class';
import '../assets/css/CustomCheckboxTree.css';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const CheckBoxTreeView = ({ checked, expanded, handleCheck, handleExpand, checkboxTreeData }) => {
  return (
    <CheckboxTree
      nodes={checkboxTreeData}
      checked={checked}
      expanded={expanded}
      onCheck={handleCheck}
      onExpand={handleExpand}
      icons={{
        check: <CheckBoxIcon />,
        uncheck: <CheckBoxOutlineBlankIcon />,
        halfCheck: <IndeterminateCheckBoxIcon />,
        expandClose: <ChevronRightIcon />,
        expandOpen: <ExpandMoreIcon />,
        expandAll: <AddBoxIcon />,
        collapseAll: <IndeterminateCheckBoxIcon />,
        parentClose: <CategoryIcon />,
        parentOpen: <ClassIcon />,
        leaf: <QuestionAnswerIcon />
      }}
    />
  );
};

CheckBoxTreeView.propTypes = {
  checked: PropTypes.array,
  expanded: PropTypes.array,
  handleCheck: PropTypes.func,
  handleExpand: PropTypes.func,
  checkboxTreeData: PropTypes.array
};

export default CheckBoxTreeView;

import { format } from 'date-fns';

/**
 * Removes time zone offset from the date string
 *
 * @param {string} dateStr
 * @returns date without timezone offset info
 */
export const removeTimeZoneOffset = (dateStr) => {
  if (typeof dateStr === 'string') {
    return dateStr?.split('+')[0];
  } else {
    const date = dateStr?.toString().split('GMT')[0];
    return date?.toString();
  }
};

//To convert DateTime string into human readable formate
export const dateFormatter = (dateTimeStr) => {
  if (dateTimeStr == 'Invalid Date') return;

  if (!dateTimeStr) {
    return '';
  }
  const date = new Date(removeTimeZoneOffset(dateTimeStr));
  return format(date, 'LLL-dd-yyyy');
};

export const stringDateFormatting = (stringDate) => {
  const dateWithoutOffset = removeTimeZoneOffset(stringDate);
  const date = new Date(dateWithoutOffset);
  return date.toDateString();
};

// Define the initial expiry date
let initialExpiryDate = new Date('2024-05-31T23:59:59');

// Function to increment the expiry date by one year
export const incrementExpiryDate = (expiryDate) => {
  const newExpiryDate = new Date(expiryDate);
  newExpiryDate.setFullYear(newExpiryDate.getFullYear() + 1);
  return newExpiryDate;
};

// Function to check if the current date matches the expiry date
export const isExpiryDateReached = (currentDate, expiryDate) => {
  return currentDate > expiryDate;
};

// Simulating the current date for demonstration purposes
const currentDate = new Date(); // Replace with new Date() for actual usage

export const getValidUntilDate = () => {
  // Check if current date matches expiry date
  if (isExpiryDateReached(currentDate, initialExpiryDate)) {
    // Increment expiry date by one year
    const newExpiryDate = incrementExpiryDate(initialExpiryDate);
    initialExpiryDate = newExpiryDate;
    return newExpiryDate;
  } else {
    return initialExpiryDate;
  }
};

import { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Stack } from '@mui/material';
import Input from '../components/Input';
import LoginIcon from '@mui/icons-material/Login';
import { passwordValidation } from '../utils/password-strength';
import { emailValidation } from 'utils/email_strength';
import CustomButton from 'components/Button';
import { style } from 'constant/constants';
import { useDispatch } from 'react-redux';
import { loggedIn, addUser } from 'reduxStore/authSlice';
import { postRequest } from 'Api/api';
import SuccessError from 'components/SuccessError';
import Loader from 'components/Loader';

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);

  const emailInput = useRef('');
  const passwordInput = useRef('');

  const dispatch = useDispatch();

  const valueChangeHandler = (e, input) => {
    input.current = e.target.value;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    const email = emailValidation(emailInput.current);
    handleError('email', email);
    const password = passwordValidation(passwordInput.current, 'password');
    handleError('password', password);

    if (email === '' && password === '') {
      setErrors(false);
      loginHandler(emailInput.current, passwordInput.current);
    }
  };

  const loginHandler = async (email, password) => {
    setSuccessMessage([]);
    setErrorMessage([]);
    setShowAlertBar(false);
    try {
      setShowLoader(true);
      const data = await postRequest('signIn', { email, password });
      setShowLoader(false);
      setShowAlertBar(true);
      if (data.resStatus === 1) {
        dispatch(loggedIn(data.token));
        dispatch(addUser(data));
        setSuccessMessage([data.resMessage]);
      } else {
        setErrorMessage([data.resMessage]);
      }
    } catch (err) {
      handleError('backend', err);
    }
  };

  return (
    <form noValidate onSubmit={formSubmitHandler}>
      <Grid container spacing={4}>
        {showAlertBar && (
          <SuccessError
            successMessage={successMessage}
            sx={{ ml: '10vh' }}
            errorMessage={errorMessage}
            setShowAlertBar={setShowAlertBar}
          />
        )}

        <Input
          id="email-login"
          name="email"
          placeholder="Enter email address"
          type="email"
          error={errors.email}
          required={true}
          onFocus={() => handleError('email', null)}
          label=" Email Address"
          htmlFor="email-login"
          changehandler={(e) => valueChangeHandler(e, emailInput)}
          fullWidth
        />

        <Input
          id="-password-login"
          name="password"
          placeholder="Enter password"
          type={showPassword ? 'text' : 'password'}
          error={errors.password}
          required={true}
          onFocus={() => handleError('password', null)}
          label=" Password"
          htmlFor="password-login"
          changehandler={(e) => valueChangeHandler(e, passwordInput)}
          onClickHandler={handleClickShowPassword}
          onMouseDownPassword={handleMouseDownPassword}
          showPassword={showPassword}
          fullWidth
        />

        <Grid item xs={12} sx={{ mt: -1 }}>
          <Stack direction="row" spacing={2} sx={{ ml: '5vh' }}>
            <Link
              variant="text"
              component={RouterLink}
              to="/forgot-password"
              color="#489840"
              sx={{ fontFamily: style.fontFamily }}>
              Forgot Password?
            </Link>
          </Stack>
        </Grid>
        <Grid container justifyContent="end" alignItems="center" sx={{ mr: '5vh' }}>
          <CustomButton type="submit" endIcon={<LoginIcon />}>
            Login
          </CustomButton>
        </Grid>
        {showLoader && <Loader open={showLoader} />}
      </Grid>
    </form>
  );
};

export default LoginForm;

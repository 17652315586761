import { useNavigate } from 'react-router-dom';
import Text from './Text';
import CustomButton from './Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { loggedOut } from 'reduxStore/authSlice';

const SuccessPopup = ({ successMessage, setSuccessPopup }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center text-menu-color-green">
      <CheckCircleIcon style={{ fontSize: 64 }} size="large" />
      <br />
      <Text variant="h3">Success</Text>
      <Text variant="h5">{successMessage}</Text>
      <CustomButton
        onClick={() => {
          setSuccessPopup(false);
          dispatch(loggedOut());
          navigate('/login');
        }}
        endIcon={<CheckCircleIcon />}>
        OK
      </CustomButton>
    </div>
  );
};

SuccessPopup.propTypes = {
  successMessage: PropTypes.string,
  setSuccessPopup: PropTypes.func
};

export default SuccessPopup;

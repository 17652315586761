import { Grid } from '@mui/material';
import CategoryForm from './CategoryForm';
import PropTypes from 'prop-types';

const UpdateCategory = ({
  categories,
  closeModal,
  setIsFormSaved,
  setSuccess,
  setError,
  setAlertBar,
  isFormSaved
}) => {
  return (
    <Grid sx={{ width: '100%' }}>
      <div>
        <CategoryForm
          buttonClose="Save"
          categories={categories}
          closeModal={closeModal}
          isFormSaved={isFormSaved}
          setIsFormSaved={setIsFormSaved}
          setSuccess={setSuccess}
          setError={setError}
          setAlertBar={setAlertBar}
        />
      </div>
    </Grid>
  );
};

UpdateCategory.propTypes = {
  categories: PropTypes.object,
  closeModal: PropTypes.func,
  setIsFormSaved: PropTypes.func,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  isFormSaved: PropTypes.bool
};

export default UpdateCategory;

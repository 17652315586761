import PropTypes from 'prop-types';
import {
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  Rating,
  IconButton,
  Grid
} from '@mui/material';
import Text from 'components/Text';
import CloseIcon from '@mui/icons-material/Close';
import { stringDateFormatting } from 'utils/dateFormatter';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CategoryIcon from '@mui/icons-material/Category';
import ClassIcon from '@mui/icons-material/Class';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AssesmentScale from 'components/AssessmentScale';
import { stepperText } from 'utils/utils';

import { style } from 'constant/constants';

const categoryColor = [style.textColor, style.textGreen, '#bf9001'];
const textStyle = { color: style.textColor };
const stackMargin = { mr: '5vh' };
const textAlign = { textAlign: 'center' };
const alignCenter = 'center';

const Question = ({ question, color }) => {
  // method just to show the value in the stars.. an empty method
  const handleRatingChange = (e, extraArgument) => {
    console.log('Rating:', e.target.value, extraArgument);
  };

  return (
    <li
      style={{
        marginLeft: '30px',
        color: color,
        fontSize: '16px'
      }}>
      <Grid container spacing={12}>
        <Grid item xs={8} display="flex" direction="row">
          <div>
            <QuestionAnswerIcon sx={{ mr: 2 }} />
          </div>
          {question.question}
        </Grid>
        <Grid item xs={4}>
          <Rating
            size="large"
            readOnly={question.answer !== null}
            onChange={(e) => handleRatingChange(e, question.questionId)}
            icon={<RadioButtonCheckedIcon fontSize="inherit" />}
            emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
            sx={{
              '& .MuiRating-iconFilled': {
                color: style.textColor // Change the color as needed
              }
            }}
          />
        </Grid>
      </Grid>
    </li>
  );
};

Question.propTypes = {
  question: PropTypes.object,
  color: PropTypes.string
};

const SubCategory = ({ subCategory, color }) => (
  <li
    style={{
      marginLeft: '22px',
      fontSize: '18px',
      color: color
    }}>
    <ClassIcon sx={{ mr: 2 }} />
    {subCategory.name}
    <ul>
      {subCategory.questionsList.map((question) => (
        <Question key={question.questionId} question={question} color={color} />
      ))}
    </ul>
  </li>
);

SubCategory.propTypes = {
  subCategory: PropTypes.object,
  color: PropTypes.string
};

const Category = ({ category, color }) => (
  <li
    style={{
      marginLeft: '10px',
      color: color,
      fontFamily: style.fontFamily,
      fontSize: '22px'
    }}>
    <CategoryIcon sx={{ mr: 2 }} />
    {category.name}
    <ul>
      {category.subCategoryList.map((subCategory) => (
        <SubCategory key={subCategory.subCategoryId} subCategory={subCategory} color={color} />
      ))}
    </ul>
  </li>
);

Category.propTypes = {
  category: PropTypes.object,
  color: PropTypes.string
};

const NestedOrderedList = ({ data }) => (
  <ul style={{ marginLeft: 10, marginTop: 10 }}>
    {data.map((category) => (
      <Category
        key={category.categoryId}
        category={category}
        color={categoryColor[category.categoryId % categoryColor.length]}
      />
    ))}
  </ul>
);

NestedOrderedList.propTypes = {
  data: PropTypes.object
};

const PreviewAssessment = ({
  showAssessmentPreview,
  setShowAssessmentPreview,
  info,
  checked,
  questions
}) => {
  // Filter the data on the basis of checked items.
  const filterData = questions
    .map((category) => {
      const filteredSubCategoryList = category.subCategoryList
        .map((subCategory) => {
          const filteredQuestionsList = subCategory.questionsList.filter((question) =>
            checked.includes(`questionId-${question.questionId}`)
          );
          if (filteredQuestionsList.length === 0) {
            return null; // Skip empty subcategory
          }
          return { ...subCategory, questionsList: filteredQuestionsList };
        })
        .filter(Boolean); // Remove null subcategories

      if (filteredSubCategoryList.length === 0) {
        return null; // Skip empty category
      }

      return { ...category, subCategoryList: filteredSubCategoryList };
    })
    .filter(Boolean); // Remove null categories

  const handleClose = () => {
    setShowAssessmentPreview(false);
  };

  return (
    <Dialog open={showAssessmentPreview} onClose={handleClose} scroll="paper" maxWidth="lg">
      <div className="text-center mx-8">
        <Grid display="flex" alignItems={alignCenter} justifyContent="space-between">
          <Text variant="h4" style={textStyle} sx={textAlign}>
            {''}
          </Text>

          <IconButton onClick={() => setShowAssessmentPreview(false)} style={{ marginRight: -20 }}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Text variant="h4" style={textStyle} sx={textAlign}>
          {info.name}
        </Text>

        <Text style={textStyle} sx={textAlign}>
          {info.description}
        </Text>

        <Stack
          direction="row"
          alignItems={alignCenter}
          justifyContent={alignCenter}
          sx={stackMargin}>
          <Text sx={{ mr: 15 }} style={{ color: style.textGreen }}>
            Valid Until:&nbsp;
          </Text>

          <Text sx={{ mr: 15 }} style={textStyle}>
            {stringDateFormatting(info.validUntil)}
          </Text>
        </Stack>
      </div>

      <AssesmentScale />
      <Text variant="p" style={{ color: style.textColor, marginLeft: 10, marginTop: 10 }}>
        {stepperText}
      </Text>

      <Text style={{ color: 'red', marginLeft: 10 }}>*All questions are mandatory.</Text>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Stack
            direction="row"
            alignItems={alignCenter}
            justifyContent="flex-start"
            sx={stackMargin}>
            <NestedOrderedList data={filterData} />
          </Stack>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

PreviewAssessment.propTypes = {
  showAssessmentPreview: PropTypes.bool,
  setShowAssessmentPreview: PropTypes.func,
  checked: PropTypes.array,
  info: PropTypes.object,
  questions: PropTypes.array
};

export default PreviewAssessment;

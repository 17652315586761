import { useState, useEffect } from 'react';
import { Grid, Tooltip, IconButton } from '@mui/material';
import CustomButton from 'components/Button';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useLocation } from 'react-router-dom';
import Text from 'components/Text';
import { style } from 'constant/constants';
import { trancateText } from 'utils/utils';
import Table from '../../components/Table';
import ConfirmationBox from 'components/ConfirmationBox';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthRequest, authPostRequest } from 'Api/api';
import { addAssessment } from 'reduxStore/authSlice';
import Loader from 'components/Loader';
import SuccessError from 'components/SuccessError';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import EditIcon from '@mui/icons-material/Edit';
import { dateFormatter } from 'utils/dateFormatter';
import { ROLES } from 'constant/enums';
import { tableOptionFilter, columOptions } from 'utils/utils';
import PropTypes from 'prop-types';
import { EditNote } from '@mui/icons-material';
import ModalComp from 'components/Modal';
import EditAssessmentDetailsPopup from './EditAssessmentDetailsPopup';

const column = [
  {
    name: 'assessmentId',
    label: 'ID',
    options: {
      display: 'excluded', // Hide the column from the table
      filter: false
    }
  },

  {
    name: 'name',
    label: 'Assessment',
    options: {
      ...columOptions,
      customBodyRender: (value) => (
        <Tooltip title={value}>
          <span>{trancateText(value)}</span>
        </Tooltip>
      )
    }
  },

  {
    name: 'assessmentForName',
    label: 'Assessment For',
    options: {
      ...columOptions,
      customBodyRender: (value) => ROLES[value]
    }
  },

  {
    name: 'description',
    label: 'Description',
    options: {
      ...columOptions,
      customBodyRender: (value) => (
        <Tooltip title={value}>
          <span>{trancateText(value)}</span>
        </Tooltip>
      )
    }
  },

  {
    name: 'validUntil',
    label: 'Valid Until',
    options: {
      ...columOptions,
      customBodyRender: (value) => {
        return dateFormatter(value);
      }
    }
  },
  {
    name: 'publish',
    label: 'Published',
    options: {
      ...columOptions,
      customBodyRender: (value) => {
        if (value) {
          return 'Yes';
        }
        return 'No';
      }
    }
  }
];

const Assessments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const success = location.state?.propsToPass.success;
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [unpublishedAssessment, setUnpublishedAssessment] = useState(false);
  const [assesment, setAssesment] = useState();
  const [assesmentList, setAssessmentList] = useState([]);
  const [publishAssessments, setPublishAssessments] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isUpdateAssessmentList, setIsUpdateAssessmentList] = useState(false);

  const { userData } = useSelector((store) => store.auth);

  const dispatch = useDispatch();
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [assInfo, setAssInfo] = useState({});

  const handleShowEditPopup = (data) => {
    setShowEditPopup(true);
    const assesmentInfo = {
      assessmentId: data[0],
      name: data[1],
      description: data[3],
      validUntil: data[4]
    };
    setAssInfo(assesmentInfo);
  };

  const columns = [
    ...column,
    {
      name: 'actions',
      label: 'Actions',
      options: {
        ...columOptions,
        filter: false,

        //Actions Delete, Update, Resend Invite
        customBodyRender: (value, tableMeta) => {
          return (
            <Grid>
              {tableMeta.rowData[5] ? (
                <>
                  <Tooltip title="Unpublish Assessment">
                    <IconButton
                      onClick={() => {
                        handleOpenConfirmationBox(tableMeta.rowIndex);
                      }}>
                      <UnpublishedIcon style={{ color: '#800000' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Details">
                    <IconButton onClick={() => handleShowEditPopup(tableMeta.rowData)}>
                      <EditNote style={{ color: '#040273' }} />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Update">
                  <IconButton
                    onClick={() => {
                      navigate(`/update-assesment/${tableMeta.rowData[0]}`);
                    }}>
                    <EditIcon style={{ color: '#040273' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          );
        }
      }
    }
  ];

  //For Open confirmation diaglog
  const handleOpenConfirmationBox = (assesment) => {
    setAssesment(assesmentList[assesment]);
    setOpenConfirmationBox(true);
  };

  const getAllAssessments = async () => {
    try {
      setShowLoader(true);
      const assesments = await getAuthRequest('getAllAssessments');
      setShowLoader(false);

      if (assesments.resStatus === 1) {
        const publishAssessment = assesments?.assessmentsList.filter((ass) => ass.publish);
        setPublishAssessments(publishAssessment);
        setAssessmentList(assesments.assessmentsList);
      }
    } catch (err) {
      console.debug(err);
    }
  };

  useEffect(() => {
    getAllAssessments();
  }, [unpublishedAssessment, isUpdateAssessmentList]);

  useEffect(() => {
    const successMessage = localStorage.getItem('successMessage');
    if (successMessage) {
      setShowAlertBar(false);
      setSuccessMessage([success]);
      setShowAlertBar(true);
      // Remove it from local storage
      localStorage.removeItem('successMessage');
    }
  }, []);

  useEffect(() => {
    dispatch(addAssessment(publishAssessments));
  }, [publishAssessments]);

  const unpublishAssessment = async () => {
    setSuccessMessage([]);
    setErrorMessage([]);
    setUnpublishedAssessment(false);

    const requestParams = {
      userId: userData.userId,
      assessmentId: assesment.assessmentId
    };

    try {
      setShowLoader(true);
      const assesments = await authPostRequest('unPublishAssessment', requestParams);
      setOpenConfirmationBox(false);
      setUnpublishedAssessment(true);
      setShowAlertBar(true);
      if (assesments.resStatus === 1) {
        setSuccessMessage([assesments.resMessage]);
      } else {
        setErrorMessage([assesments.resMessage]);
      }
      setShowLoader(false);
    } catch (err) {
      setErrorMessage([err]);
      setShowLoader(false);
    }
  };

  return (
    <Grid>
      {showEditPopup && (
        <ModalComp
          sx={{ maxWidth: '80%', marginLeft: '10%' }}
          open={showEditPopup}
          setOpen={setShowEditPopup}
          header="Edit Assessment Info">
          <EditAssessmentDetailsPopup
            info={assInfo}
            closePopup={setShowEditPopup}
            setSuccessMessage={setSuccessMessage}
            setIsUpdateAssessmentList={setIsUpdateAssessmentList}
            setAlertBar={setShowAlertBar}
          />
        </ModalComp>
      )}
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Text variant="h4" style={{ color: style.textColor }}>
          Assessments
        </Text>
        <CustomButton
          endIcon={<AddIcon />}
          onClick={() => {
            navigate('/create-assesment');
          }}>
          Add
        </CustomButton>
      </Grid>
      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <Table data={assesmentList} options={tableOptionFilter} columns={columns} />

      {showLoader && <Loader open={showLoader} />}
      {openConfirmationBox && (
        <ConfirmationBox
          openConfirmationBox={openConfirmationBox}
          setOpenConfirmationBox={setOpenConfirmationBox}
          handleYes={unpublishAssessment}
          title="Unpublish Assessment?"
          message={
            <Text>
              Please be mindful that you are about to unpublish the assessment titled{' '}
              <span style={{ color: 'black' }}>
                <b>{assesment.name}</b>
              </span>
              . Once unpublished, the assessment will no longer be accessible to you or any users
              and will result in the loss of all the data. Are you sure you want to proceed with
              unpublishing this assessment?
            </Text>
          }
        />
      )}
    </Grid>
  );
};

Assessments.propTypes = {
  success: PropTypes.array
};

export default Assessments;

import Text from './Text';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const Expiry = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-menu-color-teal">
      <SentimentDissatisfiedIcon style={{ fontSize: 64 }} size="large" />
      <br />
      <Text variant="h3">Assessment Expired</Text>
      <Text variant="h5">The assessment has expired. Please contact admin.</Text>
    </div>
  );
};
export default Expiry;

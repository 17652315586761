import { forwardRef, useState, useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { style } from 'constant/constants';
// Todo: Replace all alert bar with this component.

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertBar = ({ severity, children, ...props }) => {
  const [visible, setVisible] = useState(true);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    if (severity === 'success') {
      const fadeTimeout = setTimeout(() => {
        setOpacity(0);
      }, 5000); // Fade out after 5 seconds

      const removeTimeout = setTimeout(() => {
        setVisible(false);
      }, 5500); // Hide after 5.5 seconds

      return () => {
        clearTimeout(fadeTimeout);
        clearTimeout(removeTimeout);
      };
    }
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Alert
      severity={severity}
      sx={{ fontFamily: style.fontFamily }}
      style={
        severity === 'success' ? { opacity: opacity, transition: 'opacity 0.5s', marginTop: 2 } : {}
      }
      {...props}
      // To pass the prop to the component conditionally
      {...(severity === 'error' && {
        onClose: () => {
          setVisible(false);
        }
      })}>
      {children}
    </Alert>
  );
};

AlertBar.propTypes = {
  severity: PropTypes.string,
  children: PropTypes.node,
  setShowAlertBar: PropTypes.func
};

export default AlertBar;

import { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { InputLabel, Grid, Stack, Typography } from '@mui/material';
import { style } from '../constant/constants';
import { dateFormatter } from 'utils/dateFormatter';

const CustomDatePicker = ({
  dateLabel,
  minDate,
  maxDate,
  onChange,
  value,
  name,
  required,
  disableFuture,
  validate,
  message
}) => {
  const [validateDate, setValidateDate] = useState(null);

  const validateHandler = (reason) => {
    if (reason === 'minDate') {
      setValidateDate(
        message
          ? message
          : minDate
          ? `End date must be later than start date`
          : `Date must be later than ${dateFormatter('1899-12-31')}`
      );
    }
    if (reason === 'disableFuture') {
      setValidateDate('Please enter valid date that is not in the future');
    }
    if (reason === 'maxDate') {
      setValidateDate('Start date must be less than the end date');
    }
    if (reason === 'invalidDate') {
      setValidateDate('Invalid date');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid item xs={12} sx={{ mx: '5vh' }}>
        <Stack>
          <InputLabel
            sx={{
              fontFamily: style.fontFamily,
              color: style.textGreen,
              marginTop: 1
            }}
            className="text-menu-color-green">
            {dateLabel}
            {required && <span className="text-red-600"> *</span>}
          </InputLabel>

          <DatePicker
            slotProps={{ textField: { size: 'small' } }}
            minDate={minDate}
            maxDate={maxDate}
            value={value}
            onChange={onChange}
            name={name}
            disableFuture={disableFuture}
            onError={(reason) => {
              if (reason === null) {
                setValidateDate(false);
                validate(reason);
              } else {
                validate(reason);
                validateHandler(reason);
              }
            }}
          />
          {validateDate && (
            <Typography className="text-red-600" style={{ fontSize: 12 }}>
              {validateDate}
            </Typography>
          )}
        </Stack>
      </Grid>
    </LocalizationProvider>
  );
};

CustomDatePicker.propTypes = {
  dateLabel: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
  name: PropTypes.string,
  message: PropTypes.string,
  disableFuture: PropTypes.bool,
  validate: PropTypes.func,
  required: PropTypes.bool
};

export default CustomDatePicker;

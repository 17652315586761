import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authPostRequest } from 'Api/api';
import { ROLES_PRECEDENCE } from 'constant/enums';

export const useReviewerPopup = ({ userAssessment }) => {
  const { userData, allUsers } = useSelector((store) => store.auth);

  const [personName, setPersonName] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [reviewersList, setReviewersList] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  //to get AssessmentList
  const getAssessmentList = async () => {
    const userAss = userAssessment[9];
    const params = {
      userAssessmentId: userAss
    };
    const assessmentList = await authPostRequest('getAssessmentReviewers', params);
    if (assessmentList.resStatus === 1) {
      setReviewersList(assessmentList.assessmentReviewersList);
      setPersonName([]);
    }
  };

  useEffect(() => {
    getAssessmentList();
  }, [showLoader]);

  useEffect(() => {
    // filter useres based on role
    // getting users with roles having equal or higher precedence than selected assessment
    const filteredUsers = allUsers?.filter(
      (user) => ROLES_PRECEDENCE[user.role] >= ROLES_PRECEDENCE[userAssessment[4]]
    );

    //filter users who are already in the reviewers list
    const reviewers = filteredUsers.filter(
      (userData) => !reviewersList.some((user) => user.reviewerId === userData.id)
    );

    //add label and value into filteredUsers for selection
    const dropdownOptions = reviewers.map((review) => {
      const user = {
        ...review,
        value: review.lastName + ' ' + review.firstName + ' (' + review.email + ')',
        label: review.lastName + ' ' + review.firstName + ' (' + review.email + ')'
      };
      return user;
    });

    //remove assessment user from the reviewer list
    const dropdownList = dropdownOptions.filter((user) => user.id !== userAssessment[1]);
    setFilteredUsers(dropdownList);
  }, [filteredUsers, personName]);

  //to handle assign reviewers api
  const handleAssignReviewers = async () => {
    setShowAlertBar(false);
    setSuccessMessage([]);
    setErrorMessage([]);
    const requestParams = {
      userId: userData.userId,
      userAssessmentsList: [{ userAssessmentId: userAssessment[9] }],
      usersList: personName.map((selectedUser) => {
        return { id: selectedUser.id };
      })
    };

    try {
      setShowLoader(true);
      const response = await authPostRequest('assignReviewer', requestParams);
      setShowLoader(false);

      if (response.resStatus === 1) {
        setSuccessMessage(response.success);
        setErrorMessage(response.error);
      } else {
        setErrorMessage(response.resMessage);
      }
      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage(err);
      setShowAlertBar(true);
    }
  };

  return {
    handleAssignReviewers,
    setPersonName,
    personName,
    showAlertBar,
    setShowAlertBar,
    successMessage,
    errorMessage,
    showLoader,
    filteredUsers,
    reviewersList
  };
};

import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import { style } from 'constant/constants';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  headerCell: {
    '& th': {
      color: 'white !important',
      fontSize: '16px',
      fontFamily: style.fontFamily
    }
  },
  tablecell: {
    '& tr': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
});

const headerStyle = {
  // Add any other custom styles you want for the headers when sorting is active
  color: 'white !important',
  fontSize: '16px',
  textTransform: 'capitalize !important',
  textAlign: 'left',
  fontFamily: style.fontFamily,
  marginLeft: -18
};

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: 'rgba(120, 114, 118, 0.09)'
            },
            '&:nth-of-type(even)': {
              backgroundColor: 'rgba(211, 211, 200, 0.09)'
            }
          }
        }
      },
      MUIDataTableViewCol: {
        styleOverrides: {
          formGroup: {
            marginTop: '20px',
            marginLeft: '2vh'
          },
          title: {
            paddingLeft: '1vh',
            paddingTop: '2vh',
            paddingRight: '3vh'
          },
          label: {
            marginLeft: '3px'
          }
        }
      },

      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: style.backgroundColor,
            '& .tss-50g6qt-MUIDataTableHeadCell-data': {
              marginLeft: '0 !important'
            }
          },

          sortActive: headerStyle,

          data: headerStyle
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            //change the color of checked icon
            backgroundColor: style.backgroundColor
          }
        }
      },

      MUIDataTable: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            padding: '16px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
          }
        }
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            //minimum width set to 850px acc to local changing it to simple
            // value to test on test env
            minWidth: 600
          }
        }
      }
    }
  });

const Table = ({ data, columns, options, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.headerCell}>
      <ThemeProvider theme={getMuiTheme}>
        <MUIDataTable title={title} data={data} columns={columns} options={options} />
      </ThemeProvider>
    </div>
  );
};

Table.propTypes = {
  options: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  title: PropTypes.string
};

export default Table;

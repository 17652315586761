import React from 'react';
import { Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { style } from 'constant/constants';
import SaveIcon from '@mui/icons-material/Save';
import PostAddIcon from '@mui/icons-material/PostAdd';

const CustomButton = ({ children, sx, disabled, buttonClose, ...props }) => (
  <Grid item sx={[{ textAlign: 'center', mt: '3vh', ml: '3vh', mb: '3vh' }, sx]}>
    <Button
      disabled={disabled}
      disableElevation
      variant="contained"
      sx={{ fontFamily: style.fontFamily, height: '40px', padding: 2 }}
      endIcon={buttonClose && (buttonClose === 'Save' ? <PostAddIcon /> : <SaveIcon />)}
      style={{ backgroundColor: disabled ? '' : '#0d5391', textTransform: 'none' }}
      {...props}>
      {children}
    </Button>
  </Grid>
);

CustomButton.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  buttonClose: PropTypes.string
};

export default CustomButton;

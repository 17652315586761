import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

const CenteredWrapper = ({ children }) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: '98vh'
      }}>
      {children}
    </Grid>
  );
};
CenteredWrapper.propTypes = {
  children: PropTypes.node
};
export default CenteredWrapper;

import { useState } from 'react';
import CenteredWrapper from 'pages/updatePassword/CenteredWrapper';
import PropTypes from 'prop-types';
import UserForm from 'components/UserForm';
import { authPostRequest } from 'Api/api';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';
import SuccessError from 'components/SuccessError';
import ModalComp from 'components/Modal';
import { checkMinLength, checkName, checkTextLength, isStringEmpty } from 'utils/utils';

const UpdateUser = ({
  setOpenModal,
  openModal,
  user,
  setSuccess,
  setAlertBar,
  isFormSaved,
  setIsFormSaved
}) => {
  const [userInfo, setUserInfo] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
    email: user.email
  });

  const [successMessage, setSuccessMessage] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errors, setErrors] = useState({});
  const { userData } = useSelector((store) => store.auth);

  const handleUserInfo = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const updateHandler = (e) => {
    e.preventDefault();

    if (isStringEmpty(userInfo.firstName)) {
      handleError('firstName', 'Please enter first name');
    }

    const firstNameMinLength = checkMinLength(userInfo.firstName, 2);
    if (firstNameMinLength) {
      handleError('firstName', 'Please enter at least 2 characters.');
    }
    const firstNameValid = checkTextLength(userInfo.firstName, 50);
    if (firstNameValid) {
      handleError('firstName', 'Maximum 50 characters allowed');
    }

    const firstNameCheck = checkName(userInfo.firstName);
    if (firstNameCheck) {
      handleError(
        'firstName',
        'Please use alphabetic and international characters only for the name '
      );
    }

    if (isStringEmpty(userInfo.lastName)) {
      handleError('lastName', 'Please enter last name');
    }

    const lastNameMinLength = checkMinLength(userInfo.lastName, 2);
    if (lastNameMinLength) {
      handleError('lastName', 'Please enter at least 2 characters.');
    }
    const lastNameValid = checkTextLength(userInfo.lastName, 50);
    if (lastNameValid) {
      handleError('lastName', 'Maximum 50 characters allowed');
    }

    const lastNameCheck = checkName(userInfo.lastName);
    if (lastNameCheck) {
      handleError(
        'lastName',
        'Please use alphabetic and international characters only for the name'
      );
    }

    if (
      !isStringEmpty(userInfo.firstName) &&
      !isStringEmpty(userInfo.lastName) &&
      !firstNameCheck &&
      !lastNameCheck &&
      !firstNameValid &&
      !lastNameValid &&
      !firstNameMinLength &&
      !lastNameMinLength
    ) {
      setErrors({});
      submitHandler();
    }
  };

  const handleCancelAction = () => {
    setOpenModal(false);
  };

  const submitHandler = async () => {
    try {
      setSuccessMessage([]);
      setErrorMessage([]);
      const requestParams = {
        userId: userData.userId,
        id: user.id,
        firstName: userInfo.firstName.trim(),
        lastName: userInfo.lastName.trim(),
        archive: false,
        email: user.email,
        role: userInfo.role
      };

      setShowLoader(true);
      setShowAlertBar(false);
      setAlertBar(false);

      const updateUsers = await authPostRequest('updateUser', requestParams);

      if (updateUsers.resStatus === 1) {
        setIsFormSaved(!isFormSaved);
        setSuccessMessage([updateUsers.resMessage]);
        setSuccess([updateUsers.resMessage]);
        setUserInfo({ firstName: '', lastName: '', role: '' });
        handleCancelAction();
      } else if (updateUsers.resStatus === 0) {
        setErrorMessage([updateUsers.resMessage]);
      }

      setShowLoader(false);
      setAlertBar(true);
      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage([...errorMessage, 'Error']);
    }
  };

  return (
    <CenteredWrapper>
      <ModalComp open={openModal} setOpen={setOpenModal} header="Update User">
        {showAlertBar && (
          <SuccessError
            successMessage={successMessage}
            errorMessage={errorMessage}
            setShowAlertBar={setShowAlertBar}
          />
        )}

        {showLoader && <Loader open={showLoader} />}

        <UserForm
          handleSubmit={updateHandler}
          handleError={handleError}
          buttonText="Save"
          user={user}
          handleCancelAction={handleCancelAction}
          handleUserInfo={handleUserInfo}
          isBulkUpload={false}
          userInfo={userInfo}
          error={errors}
          disableEmail={true}
        />
      </ModalComp>
    </CenteredWrapper>
  );
};

UpdateUser.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  user: PropTypes.object,
  isFormSaved: PropTypes.bool,
  setIsFormSaved: PropTypes.func,
  setSuccess: PropTypes.func,
  setAlertBar: PropTypes.func
};

export default UpdateUser;

import { useState } from 'react';
import { authPostRequest } from 'Api/api';
import { useSelector } from 'react-redux';
import { checkTextLength, formattedDateString, isStringEmpty } from '../../utils/utils';

export const useAssEditDetailsForm = (
  info,
  closePopup,
  setSuccessMessage,
  setAlertBar,
  setIsUpdateAssessmentList
) => {
  const [assesmentInfo, setAssesmentInfo] = useState(info);
  const [validateDate, setValidateDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const { userData } = useSelector((store) => store.auth);

  const handleAssesmentInfo = (e) => {
    setAssesmentInfo({ ...assesmentInfo, [e.target.name]: e.target.value });
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const checkIsFormValid = () => {
    if (isStringEmpty(assesmentInfo.name)) {
      handleError('name', 'Please enter assessment name');
    }

    if (isStringEmpty(assesmentInfo.description)) {
      handleError('description', 'Please enter description');
    }

    if (!assesmentInfo.validUntil) {
      handleError('validUntil', 'Please enter valid until date');
    }

    const isAssessmentNameValid = checkTextLength(assesmentInfo.name, 100);

    if (isAssessmentNameValid) {
      handleError('name', 'Maximum 100 characters allowed.');
    }

    const isDescriptionLengthValid = checkTextLength(assesmentInfo.description, 500);

    if (isDescriptionLengthValid) {
      handleError('description', 'Maximum 500 characters allowed.');
    }

    if (
      assesmentInfo.name?.trim() &&
      assesmentInfo.validUntil &&
      assesmentInfo.description.trim() &&
      !isDescriptionLengthValid &&
      !isAssessmentNameValid &&
      validateDate === null
    ) {
      handleError({});
      return true;
    }

    return false;
  };

  const updateAssessment = async () => {
    const isFormValid = checkIsFormValid();

    if (!isFormValid) return;

    const requestParams = {
      userId: userData.userId,
      assessmentId: assesmentInfo?.assessmentId,
      name: assesmentInfo?.name?.trim(),
      description: assesmentInfo?.description?.trim(),
      validUntil: formattedDateString(assesmentInfo?.validUntil)
    };

    try {
      setErrorMessage([]);
      setSuccessMessage([]);

      setOpenLoader(true);
      setShowAlertBar(false);
      setAlertBar(false);
      const saveAssessment =
        isFormValid && (await authPostRequest('updatePublishedAssessment', requestParams));

      if (saveAssessment.resStatus === 1) {
        setSuccessMessage([saveAssessment.resMessage]);
        setAlertBar(true);
        setIsUpdateAssessmentList((prevState) => !prevState);
        closePopup(false);
      } else {
        setErrorMessage([saveAssessment.resMessage]);
      }
      setOpenLoader(false);
      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage([...errorMessage, 'Error']);
    }
  };

  return {
    assesmentInfo,
    setAssesmentInfo,
    handleAssesmentInfo,
    errors,
    handleError,
    updateAssessment,
    errorMessage,
    openLoader,
    showAlertBar,
    setValidateDate,
    setShowAlertBar
  };
};

import { Stack } from '@mui/material';
import { useState } from 'react';
import Input from 'components/Input';
import { useSelector } from 'react-redux';
import CustomButton from 'components/Button';
import PropTypes from 'prop-types';
import SuccessError from 'components/SuccessError';
import AddIcon from '@mui/icons-material/Add';
import Save from '@mui/icons-material/Save';
import { authPostRequest } from 'Api/api';
import { checkTextLength, isStringEmpty } from '../../utils/utils';
import Loader from 'components/Loader';

export const InputFeilds = [
  {
    name: 'name',
    label: 'Category Name',
    type: 'text',
    placeholder: 'Enter category name',
    required: true
  },
  {
    name: 'description',
    label: 'Description',
    type: 'text',
    placeholder: 'Enter description',
    multiline: true
  }
];

const CategoryForm = ({
  categories,
  buttonText,
  buttonClose,
  closeModal,
  setAlertBar,
  setSuccess,
  setError,
  setIsFormSaved,
  isFormSaved
}) => {
  const [categoryInfo, setCategoryInfo] = useState(categories);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const { userData } = useSelector((store) => store.auth);

  const handleUserInfo = (e) => {
    setCategoryInfo({ ...categoryInfo, [e.target.name]: e.target.value });
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const checkIsFormValid = () => {
    if (isStringEmpty(categoryInfo.name)) {
      handleError('name', 'Please enter category name');
    }

    const isCategoryValid = checkTextLength(categoryInfo.name, 250);
    if (isCategoryValid) {
      handleError('name', 'Maximum 250 characters allowed.');
    }
    const isTextLengthValid = checkTextLength(categoryInfo.description, 500);

    if (isTextLengthValid) {
      handleError('description', 'Description range must be less than 500.');
    }

    if (!isStringEmpty(categoryInfo.name) && !isTextLengthValid && !isCategoryValid) {
      setErrors({});
      return true;
    }

    return false;
  };

  //=================== Save and add more ========================//
  const saveAndAddMore = async (e) => {
    e.preventDefault();
    setSuccess([]);
    setError([]);
    const category = await saveCategory();
    if (category) {
      if (category.resStatus === 1) {
        setSuccessMessage([category.resMessage]);
        setIsFormSaved(!isFormSaved);
        setCategoryInfo({ name: '', description: '' });
      } else {
        setErrorMessage([category.resMessage]);
      }
    }
  };

  //=================== Save and close ==========================//
  const saveAndClose = async (e) => {
    e.preventDefault();
    setSuccess([]);
    setError([]);
    setAlertBar(false);
    const category = await saveCategory();
    if (category?.resStatus === 1) {
      setSuccessMessage([category.resMessage]);
      setSuccess([category.resMessage]);
      setIsFormSaved(!isFormSaved);

      closeModal();
      setCategoryInfo({ name: '', description: '' });
    } else {
      setErrorMessage([category?.resMessage]);
    }
    setAlertBar(true);
  };

  const saveCategory = async () => {
    setSuccessMessage([]);
    setErrorMessage([]);
    const isFormValid = checkIsFormValid();

    const requestParams = {
      userId: userData.userId,
      name: categoryInfo.name?.trim(),
      description: categoryInfo.description?.trim(),
      categoryId: categoryInfo?.categoryId ? categoryInfo.categoryId : ''
    };
    setOpenLoader(true);
    setShowAlertBar(false);
    if (isFormValid) {
      const category = await authPostRequest('saveCategory', requestParams);
      setShowAlertBar(true);
      setOpenLoader(false);
      setIsFormSaved(true);

      return category;
    } else {
      setOpenLoader(false);
      return null;
    }
  };

  return (
    <form onSubmit={saveAndClose} className="mt-5">
      {showAlertBar && (
        <SuccessError
          sx={{ ml: '5vh' }}
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      {InputFeilds.map(({ name, type, placeholder, label, required, multiline }) => (
        <Stack key={name} direction="row" sx={{ mt: '5vh' }}>
          <Input
            type={type}
            placeholder={placeholder}
            onChange={handleUserInfo}
            value={categoryInfo[name]}
            required={required}
            label={label}
            onFocus={() => handleError(name, null)}
            name={name}
            multiline={multiline}
            error={errors[name]}
          />
        </Stack>
      ))}

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mr: '5vh' }}>
        {buttonClose !== 'Save' && (
          <CustomButton type="submit" endIcon={<AddIcon />} onClick={saveAndAddMore}>
            {buttonText}
          </CustomButton>
        )}

        <CustomButton
          type="button"
          endIcon={<Save />}
          buttonClose={buttonClose}
          onClick={saveAndClose}>
          {buttonClose}
        </CustomButton>
      </Stack>
      {openLoader && <Loader open={openLoader} />}
    </form>
  );
};

CategoryForm.propTypes = {
  categories: PropTypes.object,
  buttonText: PropTypes.string,
  buttonClose: PropTypes.string,
  closeModal: PropTypes.func,
  setIsFormSaved: PropTypes.func,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  isFormSaved: PropTypes.bool
};

export default CategoryForm;

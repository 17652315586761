import { Select, MenuItem, Grid, Stack, InputLabel } from '@mui/material';
import Input from './Input';
import CustomButton from './Button';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import { getAuthRequest } from 'Api/api';
import { style } from 'constant/constants';
import { useState, useEffect } from 'react';
import { ROLES } from 'constant/enums';
import ErrorForSelect from './ErrorForSelect';

const InputFeilds = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    placeholder: 'Enter first name',
    required: true
  },

  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    placeholder: 'Enter last name',
    required: true
  },

  {
    name: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'Enter email',
    required: true
  }
];

const UserForm = ({
  userInfo,
  disableEmail,
  buttonText,
  buttonClose,
  handleSubmit,
  handleCancelAction,
  handleUserInfo,
  isBulkUpload,
  error,
  handleError
}) => {
  const [roles, setRoles] = useState([]);

  const getRoles = async () => {
    try {
      const userRoles = await getAuthRequest('getRoles');
      setRoles(userRoles);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      {InputFeilds.map(({ name, type, placeholder, label, required }) => (
        <Stack key={name} direction="row" sx={{ marginTop: '3vh' }}>
          <Input
            type={type}
            placeholder={placeholder}
            onChange={handleUserInfo}
            value={userInfo[name]}
            required={required}
            label={label}
            onFocus={() => handleError(name, null)}
            disabled={disableEmail && name === 'email' ? disableEmail : isBulkUpload}
            name={name}
            error={error[name]}
          />
        </Stack>
      ))}

      <Grid item xs={12} sx={{ ml: '5vh', mr: '5vh', marginTop: '3vh' }}>
        <InputLabel
          htmlFor="demo-simple-select-label"
          sx={{
            fontFamily: style.fontFamily,
            color: style.textGreen,
            marginBottom: 1
          }}
          className="text-menu-color-green">
          Role <span className="text-red-600">*</span>
        </InputLabel>
        <Select
          value={userInfo.role}
          size="small"
          sx={{ width: '100%', fontFamily: style.fontFamily }}
          name="role"
          onChange={handleUserInfo}
          error={error.role}
          onFocus={() => handleError(userInfo.role, null)}
          disabled={isBulkUpload}
          defaultValue={ROLES.ROLE_ADMIN}>
          {roles.length > 0 &&
            roles.map(({ name, id }) => (
              <MenuItem value={name} key={id} sx={{ fontFamily: style.fontFamily }}>
                {ROLES[name]}
              </MenuItem>
            ))}
        </Select>
        {error.role && <ErrorForSelect>{error.role}</ErrorForSelect>}
      </Grid>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mr: '5vh' }}>
        <CustomButton
          sx={{ ml: '15vh' }}
          type="submit"
          onClick={handleSubmit}
          endIcon={buttonText === 'Save' ? <SaveIcon /> : <AddIcon />}>
          {buttonText}
        </CustomButton>
        {buttonText !== 'Save' && (
          <CustomButton type="button" endIcon={<SaveIcon />} onClick={handleCancelAction}>
            {buttonClose}
          </CustomButton>
        )}
      </Stack>
    </form>
  );
};

UserForm.propTypes = {
  userInfo: PropTypes.object,
  buttonText: PropTypes.string,
  buttonClose: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancelAction: PropTypes.func,
  handleUserInfo: PropTypes.func,
  handleError: PropTypes.func,
  roles: PropTypes.array,
  disableEmail: PropTypes.bool,
  user: PropTypes.object,
  isBulkUpload: PropTypes.bool,
  error: PropTypes.object
};

export default UserForm;

import InreviewAssessments from 'pages/reports/InReviewAssessments';
import CompletedAssessments from 'pages/reports/CompletedAssessments';
import ReadyForReviewAssessments from 'pages/reports/ReadyForReviewAssessments';
import NotFilledAssessments from 'pages/reports/NotFilledAssessments';
import ReportsLayout from './ReportsLayout';
import TotalAssessments from 'pages/reports/TotalAssessments';
const ReportsRoutes = {
  path: '/',
  element: <ReportsLayout />,
  children: [
    {
      path: '/total-assessments',
      element: <TotalAssessments />
    },
    {
      path: '/inreview-assessments',
      element: <InreviewAssessments />
    },
    {
      path: '/completed-assessments',
      element: <CompletedAssessments />
    },
    {
      path: '/rfr-assessments',
      element: <ReadyForReviewAssessments />
    },
    {
      path: '/notfilled-assessments',
      element: <NotFilledAssessments />
    }
  ]
};
export default ReportsRoutes;

import { useRoutes } from 'react-router-dom';
import AuthRoutes from './AuthRoutes';
import NonAuthRoutes from './NonAuthRoutes';
import ResetRoute from './ResetRoute';
import AssessmentsRoute from './AssessmentRoute';
import ReportsRoutes from './ReportsRoutes';
import OpenRoutes from './OpenRoutes';

const Routing = () => {
  const route = useRoutes([
    AuthRoutes,
    NonAuthRoutes,
    ResetRoute,
    AssessmentsRoute,
    OpenRoutes,
    ReportsRoutes
  ]);

  return <>{route}</>;
};

export default Routing;

import { Stack, InputLabel, Grid, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { ROLES } from 'constant/enums';
import { dateFormatter } from 'utils/dateFormatter';
import Table from 'components/Table';
import { tableOptions } from 'utils/utils';
import { style } from '../../constant/constants';
import Select from 'react-select';
import CustomButton from 'components/Button';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Text from 'components/Text';
import SuccessError from 'components/SuccessError';
import Loader from 'components/Loader';
import { useReviewerPopup } from './useReviewerPopup';
import { trancateText } from 'utils/utils';

const columns_options = { filter: true, sort: false };

const options = { ...tableOptions, tableBodyHeight: '40vh' };

const column = [
  {
    name: 'reviewerName',
    label: 'Reviewers',
    options: columns_options
  },
  {
    name: 'role',
    label: 'Role',
    options: {
      ...columns_options,
      customBodyRender: (value) => ROLES[value]
    }
  },
  {
    name: 'assignedDate',
    label: 'Assigned Date',
    options: {
      ...columns_options,
      customBodyRender: (value) => dateFormatter(value)
    }
  },

  {
    name: 'assignedBy',
    label: 'Assigned By',
    options: columns_options
  },

  {
    name: 'reviewDate',
    label: 'Review Date',
    options: {
      ...columns_options,
      customBodyRender: (value) => dateFormatter(value)
    }
  },
  {
    name: 'reviews',
    label: 'Reviews',
    options: {
      ...columns_options,
      customBodyRender: (value) => (
        <Tooltip title={value}>
          <span>{trancateText(value)}</span>
        </Tooltip>
      )
    }
  }
];

const ReviewerPopup = ({ userAssessment }) => {
  const {
    handleAssignReviewers,
    setPersonName,
    personName,
    showAlertBar,
    setShowAlertBar,
    successMessage,
    errorMessage,
    showLoader,
    filteredUsers,
    reviewersList
  } = useReviewerPopup({
    userAssessment
  });

  return (
    <div>
      {showLoader && <Loader open={showLoader} />}

      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}
      <Stack sx={{ mt: '2vh' }}>
        <InputLabel
          htmlFor="demo-simple-select-label"
          sx={{
            fontFamily: style.fontFamily,
            color: style.textGreen
          }}
          className="text-menu-color-green">
          Select Reviewers <span className="text-red-600">*</span>
        </InputLabel>
        <Select
          defaultValue={personName}
          onChange={setPersonName}
          options={filteredUsers}
          value={personName}
          isMulti
        />
        <Grid container direction="row" alignItems="center" justifyContent="flex-end">
          <CustomButton
            endIcon={<AssignmentIcon />}
            onClick={handleAssignReviewers}
            disabled={personName.length === 0}>
            Assign
          </CustomButton>
        </Grid>
        <Text variant="h5" style={{ color: style.textColor, marginBottom: '1vh' }}>
          Assigned Reviewers
        </Text>
        <Table data={reviewersList} columns={column} options={options} />
      </Stack>
    </div>
  );
};

ReviewerPopup.propTypes = {
  userAssessment: PropTypes.array
};

export default ReviewerPopup;

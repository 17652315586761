import axios from 'axios';

export const style = {
  fontFamily: 'serif',
  backgroundColor: '#4f82b0',
  textColor: '#0d5391',
  textGreen: '#489840',
  pink: '#b3854b',
  yellow: '#0083a1',
  purple: '#a1698c',
  blue: '#5295b1',
  green: '#779a40'
};

export const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const api = axios.create({
  baseURL: `${REACT_APP_BACKEND_URL}/api/`
});

export const authorizedApi = (token) =>
  axios.create({
    baseURL: `${REACT_APP_BACKEND_URL}/api/`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

import { useState } from 'react';
import { OutlinedInput, InputLabel, InputAdornment, IconButton, Grid, Stack } from '@mui/material';
import Text from './Text';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import { style } from 'constant/constants';

const Input = ({
  id,
  name,
  placeholder,
  type,
  error,
  label,
  htmlFor,
  onMouseDownPassword,
  showPassword,
  changehandler,
  onClickHandler,
  sx,
  required,
  muliline,
  onFocus = () => {},
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Grid item xs={12} sx={[{ mx: '5vh' }, sx]}>
      <Stack
        spacing={1}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': { borderColor: '#0d5391' },
            '&.Mui-focused fieldset': {
              borderColor: style.textColor
            }
          }
        }}>
        <Grid>
          <InputLabel
            htmlFor={htmlFor}
            sx={{
              fontFamily: style.fontFamily,
              color: style.textGreen
            }}>
            {label} <span className="text-red-600">{required ? '*' : ''}</span>
          </InputLabel>
        </Grid>
        <OutlinedInput
          sx={{
            borderColor: error ? 'red' : isFocused ? '#486740' : '',
            fontFamily: style.fontFamily,
            muliline: muliline,
            height: muliline && '6vh'
          }}
          id={id}
          type={id === '-password-login' ? (showPassword ? 'text' : 'password') : type}
          name={name}
          size="small"
          rows={4}
          error={error}
          onChange={changehandler}
          required={required}
          placeholder={placeholder}
          onFocus={() => {
            onFocus();
            setIsFocused(true);
          }}
          {...props}
          endAdornment={
            id === '-password-login' && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onClickHandler}
                  onMouseDown={onMouseDownPassword}
                  edge="end"
                  size="medium">
                  {showPassword ? (
                    <VisibilityIcon style={{ color: 'green' }} />
                  ) : (
                    <VisibilityOffIcon style={{ color: 'green' }} />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }
        />
        {error && (
          <Text className="text-red-600" style={{ fontSize: 14 }}>
            {error}
          </Text>
        )}
      </Stack>
    </Grid>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onClickHandler: PropTypes.func,
  onMouseDownPassword: PropTypes.func,
  showPassword: PropTypes.bool,
  error: PropTypes.string,
  changehandler: PropTypes.func,
  onFocus: PropTypes.func,
  sx: PropTypes.object,
  required: PropTypes.bool,
  muliline: PropTypes.bool
};

export default Input;

import { useState, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Input from 'components/Input';
import CustomDatePicker from 'components/CustomDatePicker';
import { authPostRequest, getAuthRequest } from 'Api/api';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';
import CustomButton from 'components/Button';
import SaveIcon from '@mui/icons-material/Save';
import SuccessError from 'components/SuccessError';
import { checkTextLength } from 'utils/utils';
import CustomDropdown from 'components/CustomDropdown';

const InputFeilds = [
  {
    name: 'designation',
    label: 'Designation',
    type: 'text',
    placeholder: 'Enter designation '
  },

  {
    name: 'jobDescription',
    label: 'Job Description',
    type: 'text',
    multiline: true,
    placeholder: 'Enter job description'
  }
];

const RodgersInfo = () => {
  const [rodgersInfo, setRodgersInfo] = useState({
    doj: new Date(),
    designation: '',
    team: null,
    jobDescription: ''
  });

  const [validateDate, setValidateDate] = useState(null);
  const [teams, setTeams] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const { userData } = useSelector((store) => store.auth);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);

  const getTeamsList = async () => {
    try {
      const teamsList = await getAuthRequest('getTeamsList', { userId: userData.userId });
      setTeams(teamsList);
    } catch (err) {
      setErrorMessage([err]);
    }
  };

  //Rodger info setter
  const handleRodgerInfo = (e) => {
    setRodgersInfo({ ...rodgersInfo, [e.target.name]: e.target.value });
  };

  //Error handler
  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const getRodgersInfo = async () => {
    try {
      setOpenLoader(true);
      const rodgersdetail = await authPostRequest('getRodgerDetails', { userId: userData.userId });
      setOpenLoader(false);

      if (rodgersdetail.resStatus === 1) {
        setRodgersInfo(rodgersdetail);
      } else {
        console.log(rodgersdetail);
      }
    } catch (err) {
      setErrorMessage(['Error']);
    }
  };

  useEffect(() => {
    getRodgersInfo();
    getTeamsList();
  }, []);

  //Submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;

    const isDestinationValid = checkTextLength(rodgersInfo.designation, 250);
    if (isDestinationValid) {
      handleError('designation', 'Maximum 250 characters allowed');
      valid = false;
    }

    const isJobDescriptionValid = checkTextLength(rodgersInfo.jobDescription, 255);
    if (isJobDescriptionValid) {
      handleError('jobDescription', 'Maximum 255 characters allowed');
    }

    if (!rodgersInfo.doj) {
      valid = false;
    }

    if (validateDate !== null) {
      valid = false;
    }

    if (valid) {
      addUpdateHandler();
    }
  };

  const addUpdateHandler = async () => {
    setSuccessMessage([]);
    setErrorMessage([]);
    setShowAlertBar(false);
    const requestParams = {
      userId: userData.userId,
      designation: rodgersInfo.designation,
      team: rodgersInfo.team,
      doj: rodgersInfo.doj,
      jobDescription: rodgersInfo.jobDescription,
      id: userData.userId
    };

    try {
      setOpenLoader(true);
      const rodger = await authPostRequest('saveRodgerDetails', requestParams);
      setOpenLoader(false);

      if (rodger.resStatus === 1) {
        setSuccessMessage([rodger.resMessage]);
      } else {
        setErrorMessage([rodger.resMessage]);
      }

      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage([...errorMessage, 'Error']);
    }
  };

  return (
    <>
      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}
      <Paper
        sx={{
          p: 2,
          margin: 4,
          maxWidth: '100%',
          flexGrow: 1,
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff')
        }}>
        <Grid>
          <Grid item xs container direction="column" spacing={2} className="border-l rounded-sm">
            <Grid item xs>
              {InputFeilds.map(({ name, type, placeholder, label, required }) => (
                <Stack key={name} direction="row" sx={{ marginBottom: '5vh' }}>
                  <Input
                    sx={{ width: '80%' }}
                    type={type}
                    placeholder={placeholder}
                    onChange={handleRodgerInfo}
                    value={rodgersInfo[name]}
                    required={required}
                    label={label}
                    onFocus={() => handleError(name, null)}
                    name={name}
                    error={errors[name]}
                  />
                </Stack>
              ))}
              <CustomDropdown
                value={rodgersInfo?.team ?? null}
                onChange={handleRodgerInfo}
                list={teams}
                name="team"
                label="Team"
                isId={true}
              />
              <CustomDatePicker
                dateLabel="Date of Joining"
                name="doj"
                minDate={null}
                maxDate={null}
                disableFuture
                onChange={(e) => setRodgersInfo({ ...rodgersInfo, doj: e })}
                validate={setValidateDate}
                value={new Date(rodgersInfo.doj)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mr: '7vh' }}>
          <CustomButton type="submit" onClick={handleSubmit} endIcon={<SaveIcon />}>
            Save
          </CustomButton>
        </Stack>

        {openLoader && <Loader open={openLoader} />}
      </Paper>
    </>
  );
};

export default RodgersInfo;

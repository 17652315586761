import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { postRequest } from 'Api/api';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Loader from 'components/Loader';
import CustomButton from 'components/Button';
import LinkIcon from '@mui/icons-material/Link';
import { style } from 'constant/constants';
import { getParamsFromUrl } from 'utils/DecodeToken';

// eslint-disable-next-line max-len
const isActiveMessage = `The link has expired. Please click the Resend Link button to get the new link.`;
const isNotActiveMessage = (
  // eslint-disable-next-line max-len
  <p>
    The invite has expired. Please email admin at <b>leaders@rodgers.com</b> to get a new invite.
  </p>
);

const TOKEN_TYPE = Object.freeze({
  CREATE: 'CREATE',
  FORGOT: 'FORGOT'
});

const font = { fontFamily: style.fontFamily };

const ValidateToken = () => {
  const { queryString } = useParams();
  const navigate = useNavigate();
  const [openLoader, setOpenLoader] = useState(true);
  const [openDiaglog, setOpenDialog] = useState(false);

  const decodedParams = atob(queryString);

  const params = getParamsFromUrl(decodedParams);
  const handleClose = () => {
    setOpenDialog(false);
  };

  const getTokenValidity = async () => {
    try {
      const validateToken = await postRequest('validateToken', {
        token: params.token,
        userId: params.userId
      });
      if (validateToken.resStatus === 1) {
        setOpenLoader(false);
        navigate(`/create-password/${params.userId}`);
      } else {
        setOpenLoader(false);
        setOpenDialog(true);
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getTokenValidity();
  }, []);

  return (
    <>
      {openLoader && <Loader open={openLoader} />}

      <Dialog
        open={openDiaglog}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={font}>
        <DialogTitle sx={font}>The link has expired</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={font}>
            {params.tokenType === TOKEN_TYPE.CREATE ? isNotActiveMessage : isActiveMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mr: 3 }}>
          {params.tokenType !== TOKEN_TYPE.CREATE ? (
            <CustomButton
              onClick={() => {
                navigate('/forgot-password');
              }}
              endIcon={<LinkIcon />}>
              Resend Link
            </CustomButton>
          ) : (
            <CustomButton onClick={handleClose}>OK</CustomButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ValidateToken;

import { dateFormatter } from 'utils/dateFormatter';
import AssessementReport from './AssessmentReport';
import { STATUS } from 'constant/enums';
import { columOptions } from 'utils/utils';
import TextEllipsize from 'utils/TextEllipsize';

const columns = [
  {
    name: 'employeeName',
    label: 'Employee Name',
    options: columOptions
  },

  {
    name: 'assessmentName',
    label: 'Assessment',
    options: { ...columOptions, customBodyRender: (value) => <TextEllipsize value={value} /> }
  },

  {
    name: 'assignedDate',
    label: 'Assigned Date',
    options: {
      ...columOptions,
      customBodyRender: (value) => dateFormatter(value)
    }
  },

  {
    name: 'status',
    label: 'Status',
    options: {
      ...columOptions,
      customBodyRender: (value) => STATUS[value]
    }
  }
];

const ReadyForReviewAssessments = () => {
  return (
    <AssessementReport
      title="Ready For Review Assessments"
      columns={columns}
      endPoint="getReadyForReviewUsersAssessments"
    />
  );
};

export default ReadyForReviewAssessments;

import { useState, useEffect } from 'react';
import Table from '../../components/Table';
import { Grid } from '@mui/material';
import DialogBox from '../../components/DialogBox';
import CustomButton from 'components/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { authPostRequest, getAuthRequest } from 'Api/api';
import Loader from 'components/Loader';
import SuccessError from 'components/SuccessError';
import Text from 'components/Text';
import { style } from 'constant/constants';
import Modal from 'components/Modal';
import UpdateSubCategory from './UpdateSubCategory';
import AddSubCategory from './AddSubCategory';
import CRUDActions from 'components/CRUDActions';
import { tableOptionFilter, columOptions } from 'utils/utils';
import DeleteSpan from 'components/DeleteSpan';
import TextEllipsize from 'utils/TextEllipsize';

const SubCategories = () => {
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [subCategory, setSubCategory] = useState();
  const [subCategories, setSubCategories] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);

  const { userData } = useSelector((store) => store.auth);

  //For Open Dialog for delete
  const handleOpenDialogBox = (subCat) => {
    setSubCategory(subCategories[subCat]);
    setOpenDialogBox(true);
  };

  //For Open modal for update
  const handleOpenUpdateModal = (subCat) => {
    setSubCategory(subCategories[subCat]);
    setOpenUpdateModal(true);
  };

  //confirm for delete
  const handleConfirm = async () => {
    setSuccessMessage([]);
    setErrorMessage([]);
    try {
      const params = {
        userId: userData.userId,
        subCategoryId: subCategory.subCategoryId
      };

      setShowLoader(true);
      setShowAlertBar(false);
      const subCat = await authPostRequest('deleteSubCategory', params);
      setShowLoader(false);

      if (subCat.resStatus === 1) {
        setSuccessMessage([subCat.resMessage]);
      } else {
        setErrorMessage([subCat.resMessage]);
      }

      setShowAlertBar(true);
      setOpenDialogBox(!openDialogBox);
    } catch (err) {
      setShowLoader(false);
    }
  };

  //Open or close delete dialog
  const handleDialogBoxState = () => {
    setOpenDialogBox(!openDialogBox);
  };

  //Open or close update modal
  const handleModalState = () => {
    setOpenUpdateModal(!openUpdateModal);
    setIsFormSaved(false);
  };

  //Open or close add modal
  const handleAddModalState = () => {
    setOpenAddModal(!openAddModal);
  };

  const getInfo = async () => {
    try {
      setShowLoader(true);
      const subCategory = await getAuthRequest('getSubCategories');
      setShowLoader(false);

      if (subCategory.resStatus === 1) {
        setSubCategories(subCategory.subCategoryList);
        setShowLoader(false);
      }
    } catch (err) {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getInfo();
  }, [isFormSaved, successMessage]);

  const columns = [
    {
      name: 'subCategoryName',
      label: 'Sub-Category',
      options: {
        ...columOptions,
        customBodyRender: (value) => <TextEllipsize value={value} />
      }
    },

    {
      name: 'categoryName',
      label: 'Category',
      options: {
        ...columOptions,
        customBodyRender: (value) => <TextEllipsize value={value} />
      }
    },

    {
      name: 'description',
      label: 'Description',
      options: {
        ...columOptions,
        customBodyRender: (value) => <TextEllipsize value={value} />
      }
    },

    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        //Actions Delete, Update
        customBodyRender: (value, tableMeta) => {
          return (
            <CRUDActions
              onDelete={() => handleOpenDialogBox(tableMeta.rowIndex)}
              onEdit={() => handleOpenUpdateModal(tableMeta.rowIndex)}
            />
          );
        }
      }
    }
  ];

  return (
    <Grid sx={{ width: '100%' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={12}>
        <Text variant="h4" style={{ color: style.textColor }}>
          Sub-Categories
        </Text>
        <CustomButton endIcon={<AddIcon />} onClick={() => setOpenAddModal(true)}>
          Add
        </CustomButton>
      </Grid>

      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <Table data={subCategories} options={tableOptionFilter} columns={columns} />

      {showLoader && <Loader open={showLoader} />}

      {openDialogBox && (
        <DialogBox
          handleDialogBoxState={handleDialogBoxState}
          openDialogBox={openDialogBox}
          handleConfirmAction={handleConfirm}
          deleteMessage={
            <DeleteSpan message="sub-category" toBeDel={subCategory.subCategoryName} />
          }
        />
      )}

      {openUpdateModal && (
        <Modal open={openUpdateModal} setOpen={setOpenUpdateModal} header="Update Sub-Category">
          <UpdateSubCategory
            handleModalState={handleModalState}
            subCat={subCategory}
            setIsFormSaved={setIsFormSaved}
            isFormSaved={isFormSaved}
            setError={setErrorMessage}
            setSuccess={setSuccessMessage}
            setAlertBar={setShowAlertBar}
          />
        </Modal>
      )}

      {openAddModal && (
        <Modal open={openAddModal} setOpen={setOpenAddModal} header="Add Sub-Category">
          <AddSubCategory
            handleModalState={handleAddModalState}
            setIsFormSaved={setIsFormSaved}
            isFormSaved={isFormSaved}
            setSuccess={setSuccessMessage}
            setError={setErrorMessage}
            setAlertBar={setShowAlertBar}
          />
        </Modal>
      )}
    </Grid>
  );
};
export default SubCategories;

import { dateFormatter } from 'utils/dateFormatter';
import TableCell from '@mui/material/TableCell';
import Table from 'components/Table';
import { columOptions, expandableTableOptions, tableOptions } from 'utils/utils';
import { ExpandButton } from 'mui-datatables';
import AssessementReport from './AssessmentReport';
import { STATUS } from 'constant/enums';
import TextEllipsize from 'utils/TextEllipsize';

const columns = [
  {
    name: 'employeeName',
    label: 'Employee Name',
    options: columOptions
  },

  {
    name: 'assessmentName',
    label: 'Assessment',
    options: { ...columOptions, customBodyRender: (value) => <TextEllipsize value={value} /> }
  },

  {
    name: 'assignedDate',
    label: 'Assigned Date',
    options: {
      ...columOptions,
      customBodyRender: (value) => dateFormatter(value)
    }
  },

  {
    name: 'status',
    label: 'Status',
    options: {
      ...columOptions,
      customBodyRender: (value) => STATUS[value]
    }
  },
  {
    name: 'assessmentReviewersList',
    options: {
      filter: false,
      sort: false,
      display: 'excluded'
    }
  }
];

const columnsReviewers = [
  {
    name: 'reviewerName',
    label: 'Reviewer',
    options: columOptions
  },
  {
    name: 'assignedBy',
    label: 'Assigned By',
    options: columOptions
  },
  {
    name: 'assignedDate',
    label: 'Assigned Date',
    options: {
      ...columOptions,
      customBodyRender: (value) => dateFormatter(value)
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: columOptions
  }
];

const optionsReviewers = {
  ...tableOptions,
  tableBodyHeight: '',
  sort: false,
  filter: false,
  pagination: false
};

const options = {
  ...expandableTableOptions,
  isRowExpandable: () => true,
  rowsExpanded: [0, 1],
  renderExpandableRow: (rowData) => {
    const colSpan = rowData.length + 1;

    return (
      <TableCell colSpan={colSpan}>
        <Table columns={columnsReviewers} data={rowData[4]} options={optionsReviewers} />
      </TableCell>
    );
  }
};

const InreviewAssessments = () => {
  return (
    <AssessementReport
      title="In Review Assessments"
      columns={columns}
      endPoint="getInReviewUsersAssessments"
      options={options}
      components={(props) => <ExpandButton {...props} />}
    />
  );
};

export default InreviewAssessments;

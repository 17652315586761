import * as React from 'react';
import { Modal, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Text from './Text';
import { style } from 'constant/constants';
import CenteredWrapper from 'pages/updatePassword/CenteredWrapper';
import OutlinedCard from './OutlinedCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const ModalComp = ({ open, setOpen, children, header, sx }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Modal
      style={sx}
      open={open}
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <CenteredWrapper>
        <OutlinedCard
          sx={{
            minWidth: 500,
            overflowY: 'auto'
          }}>
          <Grid sx={{ width: '100%' }}>
            {header && (
              <>
                <Grid display="flex" alignItems="center" justifyContent="space-between">
                  <Text
                    variant="h5"
                    style={{ margin: '1vh', fontFamily: style.fontFamily, color: style.textColor }}>
                    {header}
                  </Text>
                  <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </Grid>

                <hr />
              </>
            )}
            {children}
          </Grid>
        </OutlinedCard>
      </CenteredWrapper>
    </Modal>
  );
};

ModalComp.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  setOpen: PropTypes.func,
  header: PropTypes.string,
  sx: PropTypes.object
};

export default ModalComp;

export const formatPhoneNumber = (phoneNumberString) => {
  const cleanedPhoneNumber = phoneNumberString.replace(/\D/g, '');

  // Apply the desired phone number format
  let formattedPhoneNumber = '';
  if (cleanedPhoneNumber.length >= 3) {
    formattedPhoneNumber = '(' + cleanedPhoneNumber.substring(0, 3) + ')';
  }
  if (cleanedPhoneNumber.length >= 6) {
    formattedPhoneNumber += ' ' + cleanedPhoneNumber.substring(3, 6);
  }
  if (cleanedPhoneNumber.length >= 9) {
    formattedPhoneNumber += '-' + cleanedPhoneNumber.substring(6, 9);
  }
  if (cleanedPhoneNumber.length >= 10) {
    formattedPhoneNumber += ' ' + cleanedPhoneNumber.substring(9);
  }
  return formattedPhoneNumber;
};

export const usFormatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.length > 10) {
    return null;
  }
  // Remove all non-digit characters from the phone number
  var cleaned = phoneNumber.replace(/\D/g, '');

  // Check if the cleaned phone number is of a valid length
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  // If the cleaned phone number is valid, format it
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  // If the cleaned phone number is invalid, return the original input
  return phoneNumber;
};

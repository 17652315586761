import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from './Button';
import PropTypes from 'prop-types';
import Text from './Text';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

//backdrop Style
const backdropStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.01)'
};

const DialogBox = ({ handleDialogBoxState, openDialogBox, handleConfirmAction, deleteMessage }) => {
  return (
    <Dialog
      BackdropProps={{ style: backdropStyles }}
      open={openDialogBox}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title" sx={{ display: 'flex' }}>
        <WarningAmberIcon style={{ color: 'red', marginRight: 5, marginTop: 3 }} />
        <Text variant="h5" style={{ color: 'red' }}>
          Delete
        </Text>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{deleteMessage}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <CustomButton
          autoFocus
          onClick={handleConfirmAction}
          style={{ backgroundColor: 'red', textTransform: 'none' }}>
          Delete
        </CustomButton>

        <Button style={{ textTransform: 'none' }} onClick={handleDialogBoxState} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogBox.propTypes = {
  openDialogBox: PropTypes.bool,
  handleDialogBoxState: PropTypes.func,
  handleConfirmAction: PropTypes.func,
  deleteMessage: PropTypes.object
};

export default DialogBox;

import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { style } from 'constant/constants';

const Text = ({ variant, children, ...props }) => (
  <Typography variant={variant} {...props} sx={{ fontFamily: style.fontFamily }}>
    {children}
  </Typography>
);

Text.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node
};

export default Text;

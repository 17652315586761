import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import PropTypes from 'prop-types';
import { style } from 'constant/constants';
import ErrorIcon from '@mui/icons-material/Error';

const ConfirmationBox = ({
  openConfirmationBox,
  setOpenConfirmationBox,
  handleYes,
  message,
  title
}) => {
  const handleClose = () => {
    setOpenConfirmationBox(false);
  };

  return (
    <div>
      <Dialog
        open={openConfirmationBox}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle style={{ fontFamily: style.fontFamily, color: 'red' }}>
          <ErrorIcon style={{ marginRight: 2, marginBottom: 5 }} />
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} style={{ fontFamily: style.fontFamily }} autoFocus>
            Yes
          </Button>
          <Button
            style={{ fontFamily: style.fontFamily }}
            onClick={() => {
              setOpenConfirmationBox(false);
            }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmationBox.propTypes = {
  openConfirmationBox: PropTypes.bool,
  setOpenConfirmationBox: PropTypes.func,
  handleYes: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.node
};

export default ConfirmationBox;

import { Grid } from '@mui/material';
import Text from 'components/Text';
import Table from '../../components/Table';
import Loader from 'components/Loader';
import { tableOptionFilter } from 'utils/utils';
import { style } from 'constant/constants';
import SharePopup from 'components/SharePopup';
import ReviewerPopup from './ReviewerPopup';
import ModalComp from 'components/Modal';
import ConfirmationBox from 'components/ConfirmationBox';
import SuccessError from 'components/SuccessError';
import ViewPopup from './ViewPopup';
import { useManageAssessment } from './useManageAssessmentHook';

const ManageAssessments = () => {
  const {
    assesmentList,
    successMessage,
    errorMessage,
    showAlert,
    showViewPopup,
    showAlertBar,
    showLoader,
    showSharePopup,
    setShowSharePopup,
    showRviewerPopup,
    userAssessment,
    columns,
    setShowAlertBar,
    setShowViewPopup,
    setShowAlert,
    confirmHandler,
    setShowRviewerPopup
  } = useManageAssessment();

  return (
    <Grid>
      <Text variant="h4" style={{ color: style.textColor, marginBottom: '2vh' }}>
        Manage Assessments
      </Text>
      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}
      <Table data={assesmentList} options={tableOptionFilter} columns={columns} />
      {showViewPopup && (
        <ModalComp
          sx={{ maxWidth: '80%', marginLeft: '10%' }}
          open={showViewPopup}
          setOpen={setShowViewPopup}
          header="Assessment View">
          <ViewPopup assessment={userAssessment} />
        </ModalComp>
      )}
      {showAlert && (
        <ConfirmationBox
          openConfirmationBox={showAlert}
          setOpenConfirmationBox={setShowAlert}
          handleYes={confirmHandler}
          title="Mark assessment as complete?"
          message={<Text>Are you sure you want to mark this assessment as complete?</Text>}
        />
      )}
      {showLoader && <Loader open={showLoader} />}
      {showSharePopup && (
        <ModalComp
          sx={{ maxWidth: '80%', marginLeft: '10%' }}
          open={showSharePopup}
          setOpen={setShowSharePopup}
          header="Share Assessment">
          <SharePopup userAssessmentId={userAssessment[9]} />
        </ModalComp>
      )}
      {showRviewerPopup && (
        <ModalComp
          sx={{ maxWidth: '80%', marginLeft: '10%' }}
          open={showRviewerPopup}
          setOpen={setShowRviewerPopup}
          header="Assign Reviewers">
          <ReviewerPopup userAssessment={userAssessment} />
        </ModalComp>
      )}
    </Grid>
  );
};

export default ManageAssessments;

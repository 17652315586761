import PropTypes from 'prop-types';
import { Stack, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import Input from 'components/Input';
import CustomButton from 'components/Button';
import AddIcon from '@mui/icons-material/Add';
import Save from '@mui/icons-material/Save';
import { useQuestionForm } from './useQuestionForm';
import Loader from 'components/Loader';
import { style } from '../../constant/constants';
import SuccessError from 'components/SuccessError';
import ErrorForSelect from 'components/ErrorForSelect';
import TextEllipsize from 'utils/TextEllipsize';

const InputFeilds = [
  {
    name: 'question',
    label: 'Question',
    type: 'text',
    placeholder: 'Enter question',
    multiline: true,
    required: true
  }
];

const QuestionForm = ({
  buttonText,
  question,
  setIsFormSaved,
  handleModalState,
  buttonClose,
  setAlertBar,
  setSuccess,
  update,
  setError,
  isFormSaved
}) => {
  const {
    categories,
    errorMessage,
    successMessage,
    questionInfo,
    handleQuesInfo,
    handleError,
    errors,
    saveAndAddMore,
    saveAndClose,
    openLoader,
    subCategories,
    showAlertBar,
    setShowAlertBar,
    onChangeHandler
  } = useQuestionForm({
    question,
    handleModalState,
    setIsFormSaved,
    setSuccess,
    setError,
    update,
    setAlertBar,
    isFormSaved
  });

  return (
    <Grid sx={{ width: '100%' }}>
      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <div className="mx-12">
        <form>
          <Stack container justifyContent="center" sx={{ mt: '2vh' }}>
            <InputLabel
              htmlFor="demo-simple-select-label"
              sx={{
                fontFamily: style.fontFamily,
                color: style.textGreen,
                marginBottom: 1
              }}
              className="text-menu-color-green">
              Category <span className="text-red-600">*</span>
            </InputLabel>

            <Select
              value={questionInfo.categoryName}
              name="categoryName"
              size="small"
              error={errors.categoryName}
              onChange={onChangeHandler}
              onFocus={() => handleError(questionInfo.categoryName, null)}>
              {categories.length > 0 &&
                categories.map(({ name, id }) => {
                  return (
                    <MenuItem value={name} key={id}>
                      <TextEllipsize value={name} />
                    </MenuItem>
                  );
                })}
            </Select>
            {errors.categoryName && <ErrorForSelect>{errors.categoryName}</ErrorForSelect>}
          </Stack>

          <Stack container justifyContent="center" sx={{ mt: '2vh' }}>
            <InputLabel
              htmlFor="demo-simple-select-label"
              sx={{
                fontFamily: style.fontFamily,
                color: style.textGreen,
                marginBottom: 1
              }}
              className="text-menu-color-green">
              Sub-Category <span className="text-red-600">*</span>
            </InputLabel>

            <Select
              value={questionInfo.subCategoryName}
              name="subCategoryName"
              size="small"
              error={errors.subCategoryName}
              required
              onChange={handleQuesInfo}
              onFocus={() => handleError(questionInfo.subCategoryName, null)}>
              {subCategories?.length > 0 &&
                subCategories.map(({ subCategoryName, id }) => {
                  return (
                    <MenuItem value={subCategoryName} key={id}>
                      {subCategoryName}
                    </MenuItem>
                  );
                })}
            </Select>

            {errors.subCategoryName && <ErrorForSelect>{errors.subCategoryName}</ErrorForSelect>}
          </Stack>

          <Grid container alignItems="center" justifyContent="center">
            {InputFeilds.map(({ name, label, placeholder, type, required, multiline }) => (
              <Input
                sx={{ marginTop: '2vh', width: '100%', mx: '0vh' }}
                key={name}
                required={required}
                type={type}
                placeholder={placeholder}
                onChange={handleQuesInfo}
                value={questionInfo[name]}
                multiline={multiline}
                label={label}
                onFocus={() => handleError(name, null)}
                name={name}
                error={errors[name]}
              />
            ))}
          </Grid>

          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            {buttonClose !== 'Save' && (
              <CustomButton type="submit" endIcon={<AddIcon />} onClick={saveAndAddMore}>
                {buttonText}
              </CustomButton>
            )}

            <CustomButton
              type="button"
              endIcon={<Save />}
              buttonClose={buttonClose}
              onClick={saveAndClose}>
              {buttonClose}
            </CustomButton>
          </Stack>
        </form>
      </div>

      {openLoader && <Loader open={openLoader} />}
    </Grid>
  );
};

QuestionForm.propTypes = {
  question: PropTypes.object,
  buttonText: PropTypes.string,
  buttonClose: PropTypes.string,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  isFormSaved: PropTypes.bool,
  setIsFormSaved: PropTypes.func,
  update: PropTypes.bool,
  handleModalState: PropTypes.func
};

export default QuestionForm;

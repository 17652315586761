import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Text from './Text';
import { darkenColor } from 'utils/utils';

const styles = {
  textAlign: 'center',
  fontSize: 20,
  color: 'white'
};

const tileStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 120,
  p: 2,
  mt: 4,
  ml: '1.2%',
  mr: '1.2%',
  border: `1px solid lightgrey`,
  borderRadius: 4,
  cursor: 'pointer',
  transition: 'box-shadow 0.3s'
};

const LinkTile = ({ title, count, url, color }) => {
  const textStyles = { ...styles };

  const handleClick = () => {
    open(url, '_blank');
  };

  return (
    <Grid
      item
      md={2}
      onClick={handleClick}
      sx={{
        ...tileStyles,
        backgroundColor: color,
        '&:hover': {
          boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.3)',
          backgroundColor: darkenColor(color, 30)
        }
      }}>
      <Text style={textStyles}>{title}</Text>
      <Text style={{ ...textStyles, fontSize: 25, fontWeight: 'bold' }}>{count}</Text>
    </Grid>
  );
};

LinkTile.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
  url: PropTypes.string,
  color: PropTypes.string
};

export default LinkTile;

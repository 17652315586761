import SubCategoryForm from './SubCategoryForm';
import PropTypes from 'prop-types';

const subCat = {
  subCategoryName: '',
  categoryName: '',
  description: ''
};

const AddSubCategory = ({
  handleModalState,
  setIsFormSaved,
  setAlertBar,
  setSuccess,
  setError,
  isFormSaved
}) => (
  <SubCategoryForm
    handleModalState={handleModalState}
    buttonText="Save & Add More"
    buttonClose="Save & Close"
    setSuccess={setSuccess}
    setError={setError}
    setAlertBar={setAlertBar}
    isFormSaved={isFormSaved}
    subCategory={subCat}
    setIsFormSaved={setIsFormSaved}
  />
);

AddSubCategory.propTypes = {
  handleModalState: PropTypes.func,
  setIsFormSaved: PropTypes.func,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  isFormSaved: PropTypes.bool
};

export default AddSubCategory;

import React from 'react';
// import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { ExpandButton } from 'mui-datatables';
import Table from 'components/Table';
import { tableOptions } from 'utils/utils';
// import { useState } from 'react';
// import { dateFormatter } from 'utils/dateFormatter';
// import { authPostRequest, getAuthRequest } from 'Api/api';
// import { useEffect } from 'react';

const columns = [
  {
    name: 'Name',
    options: {
      filter: true
    }
  },
  {
    name: 'Title',
    options: {
      filter: true
    }
  },
  {
    name: 'Location',
    options: {
      filter: false
    }
  },
  {
    name: 'Age',
    options: {
      filter: true
    }
  },
  {
    name: 'Salary',
    options: {
      filter: true,
      sort: false
    }
  },
  {
    name: 'Games',
    options: {
      filter: true,
      sort: false,
      display: false
    }
  }
];

const columns2 = [
  {
    name: 'Football',
    options: {
      filter: true
    }
  },
  {
    name: 'Pingpong',
    options: {
      filter: false
    }
  },
  {
    name: 'Cricket',
    options: {
      filter: true
    }
  },
  {
    name: 'Baseball',
    options: {
      filter: true,
      sort: false
    }
  }
];

const data = [
  {
    Name: 'Nelson Wyatt',
    Title: 'Business Analyst',
    Location: 'Minneapolis',
    Age: 30,
    Salary: '$100,000',
    Games: [
      {
        Football: '12334',
        Baseball: '342424',
        Cricket: '44444',
        Pingpong: '77777'
      },
      {
        Football: '12334',
        Baseball: '342424',
        Cricket: '44444',
        Pingpong: '77777'
      },
      {
        Football: '12334',
        Baseball: '342424',
        Cricket: '44444',
        Pingpong: '77777'
      }
    ]
  },
  {
    Name: 'Steve Ostin',
    Title: 'Business Analyst',
    Location: 'Minneapolis',
    Age: 30,
    Salary: '$100,000',
    Games: [
      {
        Football: '12334',
        Baseball: '342424',
        Cricket: '44444',
        Pingpong: '77777'
      }
    ]
  },
  {
    Name: 'Barry Allen',
    Title: 'Business Analyst',
    Location: 'Minneapolis',
    Age: 30,
    Salary: '$100,000',
    Games: [
      {
        Football: '12334',
        Baseball: '342424',
        Cricket: '44444',
        Pingpong: '77777'
      }
    ]
  },
  {
    Name: 'Clark Kent',
    Title: 'Business Analyst',
    Location: 'Minneapolis',
    Age: 30,
    Salary: '$100,000',
    Games: [
      {
        Football: '12334',
        Baseball: '342424',
        Cricket: '44444',
        Pingpong: '77777'
      }
    ]
  },
  {
    Name: 'John Snow',
    Title: 'Business Analyst',
    Location: 'Minneapolis',
    Age: 30,
    Salary: '$100,000',
    Games: [
      {
        Football: '12334',
        Baseball: '342424',
        Cricket: '44444',
        Pingpong: '77777'
      }
    ]
  },
  {
    Name: 'Jimmy Nutron',
    Title: 'Business Analyst',
    Location: 'Minneapolis',
    Age: 30,
    Salary: '$100,000',
    Games: [
      {
        Football: '12334',
        Baseball: '342424',
        Cricket: '44444',
        Pingpong: '77777'
      }
    ]
  }
];

const options2 = {
  ...tableOptions,
  tableBodyHeight: '',
  sort: false,
  filter: false
};

// const columOptions = { sort: false, filter: true };

// const columnsMain = [
//   {
//     name: 'userAssessmentId',
//     label: 'Id',
//     options: {
//       ...columOptions,
//       display: false
//     }
//   },
//   {
//     name: 'employeeName',
//     label: 'Employee Name',
//     options: columOptions
//   },

//   {
//     name: 'assessmentName',
//     label: 'Assessement',
//     options: columOptions
//   },

//   {
//     name: 'assignedDate',
//     label: 'Assigned Date',
//     options: {
//       ...columOptions,
//       customBodyRender: (value) => {
//         return dateFormatter(value);
//       }
//     }
//   },

//   {
//     name: 'status',
//     label: 'Status',
//     options: columOptions
//   }
// ];

// const columnsSub = [
//   {
//     name: 'reviewerName',
//     label: 'Reviewer Name',
//     options: columOptions
//   },

//   {
//     name: 'assignedDate',
//     label: 'Assigned Date',
//     options: {
//       ...columOptions,
//       customBodyRender: (value) => {
//         return dateFormatter(value);
//       }
//     }
//   },

//   {
//     name: 'assignedBy',
//     label: 'Assigned By',
//     options: columOptions
//   },

//   {
//     name: 'status',
//     label: 'Status',
//     options: columOptions
//   }
// ];

// const components = {
//   ExpandButton: (props) => {
//     if (props.dataIndex === 3 || props.dataIndex === 4) return <div style={{ width: '24px' }} />;
//     return <ExpandButton {...props} />;
//   }
// };

const DummyReport = () => {
  // const [reviewers, setReviewers] = useState([]);
  // const [assesmentsList, setAssessmentsList] = useState([]);

  // const getTotalAssessments = async () => {
  //   try {
  //     const assessments = await getAuthRequest('getInReviewUsersAssessments');
  //     if (assessments.resStatus === 1) {
  //       setAssessmentsList(assessments.userAssessmentCustomResponseList);
  //     }
  //   } catch (err) {
  //     console.debug(err);
  //   }
  // };

  // useEffect(() => {
  //   getTotalAssessments();
  // }, []);

  // const handleRowClick = async (userAssessmentId) => {
  //   console.log('userAssessmentId', userAssessmentId);
  //   try {
  //     const reviewersRes = await authPostRequest('getAssessmentReviewers', { userAssessmentId });
  //     console.log(reviewersRes);
  //     if (reviewersRes.resStatus === 1) {
  //       setReviewers(reviewersRes.assessmentReviewersList);
  //     }
  //   } catch (err) {
  //     console.debug(err);
  //   }
  // };

  // console.log('reviewers:', reviewers);

  const options = {
    filter: true,
    selectableRows: false,
    filterType: 'dropdown',
    responsive: 'standard',
    tableBodyHeight: '50vh',
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows
      //expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    rowsExpanded: [0, 1],
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      console.log(rowMeta, rowData);

      return (
        <>
          <TableCell colSpan={colSpan}>
            <Table columns={columns2} data={rowData[5]} options={options2} />
          </TableCell>
        </>
      );
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
      console.log(curExpanded, allExpanded, rowsExpanded)
    // onRowClick: (rowData) => handleRowClick(rowData[0])
  };

  return (
    <Table
      data={data}
      columns={columns}
      options={options}
      components={(props) => <ExpandButton {...props} />}
    />
  );
};

export default DummyReport;

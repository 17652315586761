import { trancateText } from 'utils/utils';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

const TextEllipsize = ({ value }) => (
  <Tooltip title={value}>
    <span>{trancateText(value)}</span>
  </Tooltip>
);

TextEllipsize.propTypes = {
  value: PropTypes.string
};

export default TextEllipsize;

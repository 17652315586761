import { Stack, Paper, InputLabel, MenuItem, Select, Grid, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import { style } from 'constant/constants';
import { useAssessmentForm } from './useAssessmentForm';
import React from 'react';
import Input from 'components/Input';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomButton from 'components/Button';
import PreviewIcon from '@mui/icons-material/Preview';
import Save from '@mui/icons-material/Save';
import CheckBoxTreeView from 'components/TreeView';
import ErrorForSelect from 'components/ErrorForSelect';
import { ROLES } from 'constant/enums';
import SuccessError from 'components/SuccessError';
import Loader from 'components/Loader';
import PreviewAssessment from './PreviewAssessment';
import { useState, useEffect } from 'react';
import { stringDateFormatting } from 'utils/dateFormatter';

const InputFeilds = [
  {
    name: 'name',
    label: 'Assessment Name',
    type: 'text',
    placeholder: 'Enter assessment name',
    required: true
  },
  {
    name: 'description',
    label: 'Description',
    type: 'text',
    placeholder: 'Enter description',
    multiline: true,
    required: true
  }
];

const AssessmentForm = ({ info, header, checkedQuestions, setAssessmentSaved }) => {
  const {
    assesmentInfo,
    setAssesmentInfo,
    handleAssesmentInfo,
    roles,
    errors,
    handleError,
    saveAssessment,
    setValidateDate,
    checked,
    expanded,
    handleExpand,
    handleCheck,
    checkboxTreeData,
    showAlertBar,
    successMessage,
    errorMessage,
    openLoader,
    setShowAlertBar,
    setShowAssessmentPreview,
    showAssessmentPreview,
    assesmentQuestions
  } = useAssessmentForm(info, checkedQuestions, setAssessmentSaved);

  // Create a state variable to track whether the "Preview" button should be enabled
  const [isPreviewButtonEnabled, setIsPreviewButtonEnabled] = useState(false);

  // Check the assessment info fields, selected questions, and assessment for role
  // and update the state variable accordingly
  useEffect(() => {
    const { name, description, role } = assesmentInfo;
    const areFieldsEmpty = !description;
    const isNameEmpty = !name;
    const areQuestionsSelected = checked.length > 0;
    const isRoleSelected = !!role;

    setIsPreviewButtonEnabled(
      !isNameEmpty || !areFieldsEmpty || areQuestionsSelected || isRoleSelected
    );
  }, [assesmentInfo, checked]);

  return (
    <Paper
      sx={{
        p: 2,
        margin: 4,
        maxWidth: '100%',
        flexGrow: 1
      }}>
      <Text variant="h4" style={{ color: style.textColor, marginLeft: '5vh', marginTop: '2vh' }}>
        {header}
      </Text>

      <Stack sx={{ mt: '2vh', width: '100%' }}>
        {showAlertBar && (
          <SuccessError
            successMessage={successMessage}
            errorMessage={errorMessage}
            setShowAlertBar={setShowAlertBar}
          />
        )}
        {InputFeilds.map(({ name, type, placeholder, label, required, multiline }) => (
          <Stack key={name} sx={{ marginTop: '3vh' }}>
            <Input
              type={type}
              placeholder={placeholder}
              onChange={handleAssesmentInfo}
              value={assesmentInfo[name]}
              required={required}
              label={label}
              onFocus={() => handleError(name, null)}
              name={name}
              multiline={multiline}
              error={errors[name]}
            />
          </Stack>
        ))}
        <Grid item xs={12} sx={{ ml: '5vh', mr: '5vh', marginTop: '3vh', marginBottom: '2vh' }}>
          <InputLabel
            htmlFor="demo-simple-select-label"
            sx={{
              fontFamily: style.fontFamily,
              color: style.textGreen,
              marginBottom: 1
            }}
            className="text-menu-color-green">
            Assessment For <span className="text-red-600">*</span>
          </InputLabel>
          <Select
            value={assesmentInfo.role}
            size="small"
            sx={{ width: '100%', fontFamily: style.fontFamily }}
            name="role"
            onChange={handleAssesmentInfo}
            error={errors.role}
            onFocus={() => handleError('role', null)}
            defaultValue={ROLES.ROLE_ADMIN}>
            {roles.length > 0 &&
              roles.map(({ name, id }) => {
                if (name === 'ROLE_SUPER_ADMIN' || name === 'ROLE_ADMIN') {
                  // Skip rendering this item
                  return null;
                }
                return (
                  <MenuItem value={id} key={id} sx={{ fontFamily: style.fontFamily }}>
                    {ROLES[name]}
                  </MenuItem>
                );
              })}
          </Select>
          {errors.role && <ErrorForSelect>{errors.role}</ErrorForSelect>}
        </Grid>

        <CustomDatePicker
          minDate={new Date()}
          dateLabel="Valid Until"
          required={true}
          name="validUntil"
          style={{ marginTop: '3vh' }}
          onChange={(e) =>
            setAssesmentInfo({ ...assesmentInfo, validUntil: stringDateFormatting(e) })
          }
          validate={setValidateDate}
          value={new Date(stringDateFormatting(assesmentInfo.validUntil))}
          message="Plesae select a future date for the assessment expiry."
        />

        <Stack direction="row" sx={{ marginLeft: '4vh', marginTop: '3vh' }} alignItems="center">
          <Checkbox
            checked={assesmentInfo.publish}
            name="checkedA"
            onChange={() =>
              setAssesmentInfo({ ...assesmentInfo, publish: !assesmentInfo.publish })
            }></Checkbox>
          <label style={{ color: style.textGreen }}>Publish</label>
        </Stack>

        <Paper
          sx={{
            p: 2,
            mx: '5vh',
            marginTop: '3vh',
            maxWidth: '95%'
          }}>
          <Text variant="h6" style={{ color: style.textGreen }}>
            Select Questions <span style={{ color: 'red' }}>*</span>
          </Text>
          <CheckBoxTreeView
            checked={checked}
            expanded={expanded}
            handleCheck={handleCheck}
            handleExpand={handleExpand}
            checkboxTreeData={checkboxTreeData}
          />
        </Paper>
        {errors.checkedQs && (
          <Stack direction="row" sx={{ marginLeft: '5vh' }} alignItems="center">
            <ErrorForSelect>{errors.checkedQs}</ErrorForSelect>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mr: '5vh' }}>
          <CustomButton
            sx={{ ml: '15vh' }}
            type="submit"
            onClick={saveAssessment}
            endIcon={<Save />}>
            Save
          </CustomButton>
          <CustomButton
            type="button"
            endIcon={<PreviewIcon />}
            onClick={() => setShowAssessmentPreview(true)}
            disabled={!isPreviewButtonEnabled} // Conditionally set the disabled attribute
          >
            Preview
          </CustomButton>
        </Stack>
      </Stack>

      {openLoader && <Loader open={openLoader} />}
      {showAssessmentPreview && (
        <PreviewAssessment
          showAssessmentPreview={showAssessmentPreview}
          setShowAssessmentPreview={setShowAssessmentPreview}
          info={assesmentInfo}
          questions={assesmentQuestions}
          checked={checked}
        />
      )}
    </Paper>
  );
};

AssessmentForm.propTypes = {
  info: PropTypes.object,
  header: PropTypes.string,
  checkedQuestions: PropTypes.array,
  setAssessmentSaved: PropTypes.bool
};

export default AssessmentForm;

import ellipsize from 'ellipsize';
import TextEllipsize from './TextEllipsize';
import { removeTimeZoneOffset } from './dateFormatter';
import { format } from 'date-fns';

const nameRegex = /^[a-zA-ZÀ-ÿ ]+$/;
const isValidInteger = /^[0-9]+$/;

export const trancateText = (text, maxChars = 50) => {
  return ellipsize(text, maxChars);
};

export const checkTextLength = (text, length) => {
  if (!text) {
    return false;
  }

  if (text.length > length) {
    return true;
  }
  return false;
};

//for minimum length
export const checkMinLength = (text, length) => {
  if (!text) {
    return false;
  }
  if (text.length < length) {
    return true;
  }
  return false;
};

export const checkInteger = (number) => {
  if (!number) {
    return false;
  }
  if (!isValidInteger.test(number)) {
    return true;
  }
};

export const checkName = (name) => {
  if (!name) {
    return false;
  }
  if (!nameRegex.test(name)) {
    return true;
  }
};

const hexToRgb = (hexColor) => {
  // Remove the '#' symbol if it's present
  const hexColorStr = hexColor.replace('#', '');

  // Convert the hex color to RGB components
  const r = parseInt(hexColorStr.substring(0, 2), 16);
  const g = parseInt(hexColorStr.substring(2, 4), 16);
  const b = parseInt(hexColorStr.substring(4, 6), 16);

  return [r, g, b];
};

const rgbToHex = (r, g, b) => {
  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  return `#${hexR}${hexG}${hexB}`;
};

export const darkenColor = (hexColor, percentage) => {
  // Remove the "#" symbol if present
  const hexColorStr = hexColor.replace('#', '');

  const [red, green, blue] = hexToRgb(hexColorStr);

  // Calculate the darkened RGB values
  const darkenedRed = Math.max(0, red - Math.floor(red * (percentage / 100)));
  const darkenedGreen = Math.max(0, green - Math.floor(green * (percentage / 100)));
  const darkenedBlue = Math.max(0, blue - Math.floor(blue * (percentage / 100)));

  return rgbToHex(darkenedRed, darkenedGreen, darkenedBlue);
};

const customStyles = {
  viewColumns: {
    marginLeft: '50px !important' // Add your left margin here
  }
};
export const filterTable = {
  customStyles,
  filterType: 'dropdown',
  selectableRows: false,
  download: false,
  responsive: 'standard',
  fixedHeader: true,
  fixedSelectColumn: false

  //For future use
  // textLabels: {
  //   body: {
  //     noMatch: ''
  //   }
  // }
};

// for filters
export const tableOptionFilter = {
  ...filterTable,
  tableBodyHeight: '50vh',
  textLabels: {
    body: {
      noMatch: ' Sorry, no records found'
    }
  }
};

// for no filters
export const tableOptions = {
  ...tableOptionFilter,
  download: false,
  print: false,
  search: false,
  viewColumns: false,
  filter: false
};

export const expandableTableOptions = {
  ...tableOptionFilter,
  tableBodyHeight: '64vh',
  selectableRows: false,
  expandableRows: true,
  expandableRowsHeader: false,
  expandableRowsOnClick: true,
  defaultExpanded: false
};

export const columOptions = {
  sort: false,
  filter: true,
  filterOptions: {
    renderValue: (item) => (item?.length ? <TextEllipsize value={item} /> : '(empty)')
  },
  customFilterListRender: (filterList) => <TextEllipsize value={filterList} />
};

export const getFilteredValueByName = (array, name) => array.filter((arr) => arr.name === name);

export const detectBrowser = () => {
  const userAgent = window.navigator.userAgent;

  if (userAgent.includes('Edg')) {
    return 'Edge';
  } else if (userAgent.includes('Chrome')) {
    return 'Chrome';
  } else {
    return 'Unknown'; // Default to "Unknown" or handle other browsers as needed
  }
};

export const isNotAdmin = (roles) => {
  if (!roles) {
    return false;
  }
  if (roles[0] !== 'ROLE_SUPER_ADMIN' && roles[0] !== 'ROLE_ADMIN') {
    return true;
  }
  return false;
};

export const serverToClientDateConvertor = (serverDate) => {
  var serverDateObj = new Date(serverDate);
  var offset = serverDateObj.getTimezoneOffset();
  var newDate = new Date(serverDateObj - offset);
  return newDate;
};

export const isDateExpired = (date) => {
  const finalDate = new Date(removeTimeZoneOffset(date));
  const currDate = new Date();
  return currDate > finalDate;
};

/**
 * takes date object and converts into simple date string with no time zone information
 * to be added in db
 *
 * @param {Date} date
 * @returns date string
 */
export const formattedDateString = (date) => {
  const stringDate = removeTimeZoneOffset(date);
  const combinedString = format(new Date(stringDate), 'yyyy-MM-dd') + ' ' + '23:59:59';
  return combinedString;
};

/**
 * to check if a string is empty or full of white space
 *
 * @param {string} value
 * @returns true if string is not empty or spaces only
 */
export const isStringEmpty = (value) => {
  if (!value) return true;
  if (value?.length === 0) return true;
  if (value?.trim()?.length === 0) return true;
  return false;
};

export const stepperText =
  // eslint-disable-next-line max-len
  'NOTE: Select the rating that best describes your observations and experiences as indicated in the scale above for each question.';

import PropTypes from 'prop-types';
import AlertBar from './AlertBar';
import { Grid } from '@mui/material';

const SuccessError = ({ successMessage, errorMessage, setShowAlertBar, sx }) => (
  <Grid
    container
    direction="column"
    alignItems="flex-start"
    justifyContent="center"
    sx={[{ ml: '1vh' }, sx]}>
    {successMessage?.length > 0 &&
      successMessage.map((message, index) => (
        <AlertBar severity="success" key={index}>
          {message}
        </AlertBar>
      ))}

    {errorMessage?.length > 0 &&
      errorMessage.map((message, index) => (
        <AlertBar severity="error" key={index} setShowAlertBar={setShowAlertBar}>
          {message}
        </AlertBar>
      ))}
  </Grid>
);

SuccessError.propTypes = {
  successMessage: PropTypes.array,
  errorMessage: PropTypes.array,
  setShowAlertBar: PropTypes.func,
  sx: PropTypes.object
};

export default SuccessError;

import { authPostRequest } from 'Api/api';
import { getAuthRequest } from 'Api/api';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { checkTextLength, isStringEmpty } from '../../utils/utils';

export const useQuestionForm = ({
  question,
  setIsFormSaved,
  setSuccess,
  setError,
  handleModalState,
  setAlertBar,
  update,
  isFormSaved
}) => {
  const [questionInfo, setQuestionInfo] = useState(question);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const { userData } = useSelector((store) => store.auth);

  const errorForSubCat = () => {
    handleError(
      'subCategoryName',
      'No Sub-Category found. Please create a subcategory before adding a question'
    );
  };
  const handleQuesInfo = (e) => {
    setQuestionInfo({ ...questionInfo, [e.target.name]: e.target.value });
    handleError(e.target.name, null);
  };

  const onChangeHandler = async (e) => {
    setQuestionInfo({ ...questionInfo, categoryName: e.target.value });
    handleError(e.target.name, null);
    const categoryId = categories
      .filter((cat) => cat.name === e.target.value)
      .map((cat) => cat.categoryId);
    const requestParams = {
      categoryId: categoryId[0]
    };
    getSubCategoryByCategory(requestParams);
  };

  const getSubCategoryByCategory = async (requestParams) => {
    const subCat = await authPostRequest('getSubCategoriesByCategory', requestParams);
    handleError('subCategoryName', null);
    if (subCat.resStatus === 1) {
      setSubCategories(subCat.subCategoryList);
    } else if (subCat.resStatus === 0) {
      errorForSubCat();
      setSubCategories([]);
    }
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const getCategories = async () => {
    try {
      const categoriesList = await getAuthRequest('getCategories');
      setCategories(categoriesList.categoriesList);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getCategories();

    if (update) {
      const requestParams = {
        categoryId: questionInfo.categoryId
      };
      getSubCategoryByCategory(requestParams);
    }
  }, [openLoader, setIsFormSaved]);

  const handleSubmit = () => {
    let valid = true;
    const isTextLengthValid = checkTextLength(questionInfo.question, 500);

    if (isTextLengthValid) {
      handleError('question', 'Maximum 500 characters allowed.');
      valid = false;
    }

    if (subCategories.length < 1 && questionInfo.categoryName) {
      errorForSubCat();
      valid = false;
    } else if (!questionInfo.subCategoryName) {
      handleError('subCategoryName', 'Please select Sub-Category');
      valid = false;
    }
    if (!questionInfo.categoryName) {
      handleError('categoryName', 'Please select category');
      valid = false;
    }

    if (isStringEmpty(questionInfo.question)) {
      handleError('question', 'Please enter question');
    }

    if (valid) {
      return true;
    }
    return false;
  };

  //=============== Save and add more ================//
  const saveAndAddMore = async (e) => {
    e.preventDefault();
    setSuccess([]);
    setError([]);
    setShowAlertBar(false);
    const question = await saveQuestion();
    if (question) {
      if (question.resStatus === 1) {
        setSuccessMessage([question.resMessage]);
        setIsFormSaved(!isFormSaved);
        setQuestionInfo({ question: '', subCategoryName: '', categoryName: '' });
      } else {
        setErrorMessage([question.resMessage]);
      }
    }
    setShowAlertBar(true);
  };

  //=============== Save and Close ==================//
  const saveAndClose = async (e) => {
    e.preventDefault();
    setError([]);
    setSuccess([]);
    setAlertBar(false);
    const question = await saveQuestion();
    if (question?.resStatus === 1) {
      setSuccessMessage([question.resMessage]);
      setSuccess([question.resMessage]);
      setIsFormSaved(!isFormSaved);

      handleModalState();
      setQuestionInfo({ question: '', subCategoryName: '', categoryName: '' });
    } else {
      setErrorMessage([question?.resMessage]);
    }
    setAlertBar(true);
  };

  //=========== Save Question API ===================//
  const saveQuestion = async () => {
    setShowAlertBar(false);
    setSuccess([]);
    setError([]);
    setSuccessMessage([]);
    setErrorMessage([]);

    const subCat =
      handleSubmit() &&
      subCategories.filter((cat) => cat.subCategoryName === questionInfo.subCategoryName);

    const requestParams = {
      userId: userData.userId,
      question: questionInfo.question?.trim(),
      subCategoryId: subCat ? subCat[0].subCategoryId : '',
      questionId: questionInfo.questionId ? questionInfo.questionId : ''
    };
    setOpenLoader(true);
    if (handleSubmit()) {
      const ques = await authPostRequest('saveQuestion', requestParams);

      setShowAlertBar(true);
      setOpenLoader(false);
      setIsFormSaved(true);
      return ques;
    } else {
      setOpenLoader(false);
      return null;
    }
  };

  return {
    setQuestionInfo,
    categories,
    saveAndClose,
    saveAndAddMore,
    subCategories,
    errorMessage,
    successMessage,
    questionInfo,
    handleQuesInfo,
    handleError,
    errors,
    showAlertBar,
    setShowAlertBar,
    openLoader,
    onChangeHandler
  };
};

import { authPostRequest } from 'Api/api';
import { getAuthRequest } from 'Api/api';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { checkTextLength, isStringEmpty } from '../../utils/utils';

export const useSubCategoryForm = ({
  subCategory,
  setIsFormSaved,
  setSuccess,
  handleModalState,
  setAlertBar,
  setError,
  isFormSaved
}) => {
  const [subCategoryInfo, setSubCategoryInfo] = useState(subCategory);
  const [categories, setCategories] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const { userData } = useSelector((store) => store.auth);

  const handleCatInfo = (e) => {
    setSubCategoryInfo({ ...subCategoryInfo, [e.target.name]: e.target.value });
    handleError(e.target.name, null);
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const getCategories = async () => {
    try {
      const categoriesList = await getAuthRequest('getCategories');
      setCategories(categoriesList.categoriesList);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getCategories();
  }, [openLoader, setIsFormSaved]);

  const handleSubmit = () => {
    let valid = true;

    if (isStringEmpty(subCategoryInfo.categoryName)) {
      handleError('categoryName', 'Please select category');
      valid = false;
    }

    const isSubcategoryValid = checkTextLength(subCategoryInfo.subCategoryName, 250);
    if (isSubcategoryValid) {
      handleError('subCategoryName', 'Maximum 255 characters allowed. ');
      valid = false;
    }
    if (isStringEmpty(subCategoryInfo.subCategoryName)) {
      handleError('subCategoryName', 'Please enter Sub-Category');
      valid = false;
    }

    const isTextLengthValid = checkTextLength(subCategoryInfo.description, 500);
    if (isTextLengthValid) {
      handleError('description', 'Description  range must be less than 500.');
      valid = false;
    }

    if (valid) {
      return true;
    }
    return false;
  };

  //================ save and add more =================//
  const saveAndAddMore = async (e) => {
    e.preventDefault();
    setShowAlertBar(false);
    setSuccess([]);
    setError([]);
    setErrorMessage([]);
    setSuccessMessage([]);
    const subCatList = await saveSubCategory();
    if (subCatList) {
      if (subCatList.resStatus === 1) {
        setSuccessMessage([subCatList.resMessage]);
        setSuccess([subCatList.resMessage]);
        setIsFormSaved(!isFormSaved);
        setSubCategoryInfo({ subCategoryName: '', description: '', categoryName: '' });
      } else {
        setErrorMessage([subCatList.resMessage]);
      }
    }
    setShowAlertBar(true);
  };

  //============== save and close =====================//
  const saveAndClose = async (e) => {
    e.preventDefault();
    setSuccess([]);
    setError([]);
    setAlertBar(false);
    const subCatList = await saveSubCategory();
    if (subCatList?.resStatus === 1) {
      setSuccessMessage([subCatList.resMessage]);
      setSuccess([subCatList.resMessage]);
      setIsFormSaved(!isFormSaved);

      handleModalState();
      setSubCategoryInfo({ subCategoryName: '', description: '', categoryName: '' });
    } else {
      setErrorMessage([subCatList?.resMessage]);
    }
    setAlertBar(true);
  };

  //=================== Save API ===================//
  const saveSubCategory = async () => {
    setShowAlertBar(false);
    setSuccessMessage([]);
    setErrorMessage([]);

    const cat =
      handleSubmit() && categories.filter((cat) => cat.name === subCategoryInfo.categoryName);

    const requestParams = {
      userId: userData.userId,
      subCategoryName: subCategoryInfo.subCategoryName?.trim(),
      description: subCategoryInfo.description?.trim(),
      categoryId: cat ? cat[0].categoryId : '',
      subCategoryId: subCategoryInfo.subCategoryId ? subCategoryInfo.subCategoryId : ''
    };
    setOpenLoader(true);
    if (handleSubmit()) {
      setShowAlertBar(false);

      const subCat = await authPostRequest('saveSubCategory', requestParams);
      setOpenLoader(false);
      setIsFormSaved(true);
      setShowAlertBar(true);
      return subCat;
    } else {
      setOpenLoader(false);
      return null;
    }
  };

  return {
    setSubCategoryInfo,
    categories,
    errorMessage,
    successMessage,
    subCategoryInfo,
    handleCatInfo,
    handleError,
    showAlertBar,
    setShowAlertBar,
    errors,
    saveAndAddMore,
    openLoader,
    saveAndClose
  };
};

import { Grid } from '@mui/material';
import { getAuthRequest } from 'Api/api';
import LinkTile from 'components/LinkTile';
import { style } from 'constant/constants';
import { STATUS } from 'constant/enums';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar
} from 'recharts';

const assessmentsInfo = [
  { title: 'Total Assigned', url: '/total-assessments', count: 0, color: style.blue },
  { title: STATUS.notFilled, url: '/notfilled-assessments', count: 0, color: style.pink },
  { title: STATUS.readyForReview, url: '/rfr-assessments', count: 0, color: style.purple },
  { title: STATUS.inReview, url: '/inreview-assessments', count: 0, color: style.yellow },
  { title: STATUS.completed, url: '/completed-assessments', count: 0, color: style.green }
];

const customLegends = [
  {
    value: `${STATUS.notFilled} %`,
    type: 'circle',
    color: style.pink
  },
  {
    value: `${STATUS.readyForReview} %`,
    type: 'circle',
    color: style.purple
  },
  {
    value: `${STATUS.inReview} %`,
    type: 'circle',
    color: style.yellow
  },
  {
    value: `${STATUS.completed} %`,
    type: 'circle',
    color: style.green
  }
];

const customLabel = {
  notFilledPer: STATUS.notFilled,
  readyForReviewPer: STATUS.readyForReview,
  inReviewPer: STATUS.inReview,
  completedPer: STATUS.completed
};

const BarInfo = [
  { dataKey: 'notFilledPer', color: style.pink },
  { dataKey: 'readyForReviewPer', color: style.purple },
  { dataKey: 'inReviewPer', color: style.yellow },
  { dataKey: 'completedPer', color: style.green }
];

const customTickFormatter = (value) => {
  if (value.length > 20) {
    return value.substring(0, 20) + '...';
  }
  return value;
};

const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={50} y={0} dy={16} textAnchor="middle" fill="#666">
        {customTickFormatter(payload.value)}
      </text>
    </g>
  );
};

CustomizedXAxisTick.propTypes = {
  payload: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number
};

const AdminDashboard = () => {
  const [assessments, setAssessments] = useState([]);
  const [chartData, setChartData] = useState([]);

  const getAssessmentCountWithStatus = async () => {
    try {
      const assessmentsData = await getAuthRequest('getAssessmentsCountByStatus');

      if (assessmentsData.resStatus === 1) {
        assessmentsInfo[0].count = assessmentsData.totalAssigned;
        assessmentsInfo[1].count = assessmentsData.notFilled;
        assessmentsInfo[2].count = assessmentsData.readyForReview;
        assessmentsInfo[3].count = assessmentsData.inReview;
        assessmentsInfo[4].count = assessmentsData.completed;
        setAssessments(assessmentsInfo);
      }
    } catch (err) {
      console.debug(err);
    }
  };

  const getAllAssessmentsStats = async () => {
    try {
      const assessmentsChartData = await getAuthRequest('getAllAssessmentsStatsByStatus');

      if (assessmentsChartData.resStatus === 1) {
        const dataForChart = assessmentsChartData.dashboardChartsResponses.map((data) => {
          return { ...data, name: data.assessmentName };
        });
        setChartData(dataForChart);
      }
    } catch (err) {
      console.debug(err);
    }
  };

  useEffect(() => {
    getAssessmentCountWithStatus();
    getAllAssessmentsStats();
  }, []);

  return (
    <>
      <Grid container justifyContent="center">
        {assessments.map(({ title, count, url, color }, i) => (
          <LinkTile key={i} count={count} url={url} color={color} title={title} />
        ))}
      </Grid>
      <Grid width="99%" height={400} sx={{ mt: 4 }}>
        <ResponsiveContainer width="100%" height="100%">
          {chartData?.length === 0 ? (
            <BarChart
              width={500}
              height={300}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              data={[{ name: 'No records found', value: 0 }]}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip formatter={(name) => [customLabel[name]]} />
              <Legend payload={customLegends} />
              {BarInfo.map((info, index) => (
                <Bar key={index} dataKey={info.dataKey} fill={info.color} />
              ))}
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          ) : (
            <BarChart
              width={500}
              height={300}
              data={chartData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={<CustomizedXAxisTick />} angle={5} textAnchor="center" />
              <YAxis />
              <Tooltip
                labelStyle={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '300px'
                }}
                formatter={(value, name) => [`${value}%`, `${customLabel[name]}`]}
              />
              <Legend payload={customLegends} />
              {BarInfo.map((info, index) => (
                <Bar key={index} dataKey={info.dataKey} fill={info.color} />
              ))}
            </BarChart>
          )}
        </ResponsiveContainer>
      </Grid>
    </>
  );
};

export default AdminDashboard;

import jwtDecode from 'jwt-decode';

// decode the token
export const decodeToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (error) {
    return null;
  }
};

// decode the url params and return the object for url params
export const getParamsFromUrl = (queryString) => {
  const url = decodeURI(queryString);
  if (typeof url === 'string') {
    const params = url.split('?');
    const eachParamsArr = params[1].split('&');
    const obj = {};
    if (eachParamsArr && eachParamsArr.length) {
      eachParamsArr.map((param) => {
        const keyValuePair = param.split('=');
        const key = keyValuePair[0];
        const value = keyValuePair[1];
        obj[key] = value;
      });
    }
    return obj;
  }
};

// encode the object into the string
export const encodeObjectInQueryString = (obj) => {
  const queryString = Object.entries(obj)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `?${encodeURI(queryString)}`;
};

import AssessmentForm from './AssessmentForm';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { authPostRequest } from 'Api/api';
import { useState } from 'react';
import { getValidUntilDate } from 'utils/dateFormatter';

const UpdateAssessment = () => {
  const { id } = useParams();
  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [assessForId, setAssessForId] = useState({});
  const [isDataReady, setIsDataReady] = useState(false); // New state variable

  const getAssessmentQuestions = async () => {
    try {
      setIsDataReady(false);
      const assesmentQuestion = await authPostRequest('getAssessmentQuestionsInHierarchy', {
        assessmentId: id
      });

      setAssessForId(assesmentQuestion);

      const outputArray = assesmentQuestion.categoriesList.flatMap((category) =>
        category.subCategoryList.flatMap((subCategory) =>
          subCategory.questionsList.map((question) => `questionId-${question.questionId}`)
        )
      );

      setCheckedQuestions(outputArray);

      setIsDataReady(true);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getAssessmentQuestions();
  }, []);

  return (
    // Conditional rendering based on data readiness
    <>
      {isDataReady && (
        <AssessmentForm
          info={{
            assessmentId: id,
            name: assessForId.name,
            description: assessForId.description,
            validUntil:
              assessForId.validUntil === null ? getValidUntilDate() : assessForId.validUntil,
            publish: assessForId.publish,
            role: assessForId.assessmentForId
          }}
          header="Update Assessment"
          checkedQuestions={checkedQuestions}
        />
      )}
    </>
  );
};

export default UpdateAssessment;

import SubCategoryForm from './SubCategoryForm';
import PropTypes from 'prop-types';

const UpdateSubCategory = ({
  handleModalState,
  subCat,
  setAlertBar,
  setSuccess,
  setError,
  isFormSaved,
  setIsFormSaved
}) => (
  <SubCategoryForm
    subCategory={subCat}
    handleModalState={handleModalState}
    setIsFormSaved={setIsFormSaved}
    buttonClose="Save"
    setSuccess={setSuccess}
    setError={setError}
    setAlertBar={setAlertBar}
    isFormSaved={isFormSaved}
  />
);

UpdateSubCategory.propTypes = {
  subCat: PropTypes.object,
  handleModalState: PropTypes.func,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  setIsFormSaved: PropTypes.func,
  isFormSaved: PropTypes.bool
};

export default UpdateSubCategory;

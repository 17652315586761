import { Grid, Typography } from '@mui/material';
import loginSide from '../assets/images/auth/login.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import LoginForm from './LoginForm';
import Logo from '../assets/images/auth/logo.png';
import { style } from 'constant/constants';

const LoginPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(() => theme.breakpoints.down('md'));

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={10}
        container
        spacing={matches ? 1 : 2}
        sx={{
          marginTop: '10vh',
          maxWidth: '90%',
          minHeight: '80vh',
          border: 'white',
          borderRadius: '10px',
          boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.6);'
        }}>
        {!matches && (
          <Grid
            item
            xs={6}
            style={{
              backgroundImage: `url(${loginSide})`,
              width: '100%',
              height: '100%',
              backgroundSize: '90%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}>
            <img src={Logo} alt="logo" style={{ maxHeight: '5%' }} />
          </Grid>
        )}

        <Grid xs={matches ? 12 : 6} item container>
          <Grid item container justifyContent="center">
            <Typography
              variant="h4"
              className="text-menu-color-teal"
              sx={{ fontFamily: style.fontFamily, marginTop: '5vh' }}>
              Login
            </Typography>
          </Grid>
          <Grid>
            <LoginForm />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginPage;

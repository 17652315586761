// Regular expression for email validation
export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export const emailValidation = (email) => {
  if (email.trim().length <= 0 || !email) {
    return 'Please enter email';
  } else if (!email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
    return 'Please provide a valid email address in the format "username@example.com" ';
  } else return '';
};

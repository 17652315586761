import QuestionForm from './QuestionForm';
import PropTypes from 'prop-types';

const UpdateQuestion = ({
  closeModal,
  ques,
  setIsFormSaved,
  setAlertBar,
  setSuccess,
  setError,
  isFormSaved
}) => (
  <QuestionForm
    setIsFormSaved={setIsFormSaved}
    buttonClose="Save"
    setSuccess={setSuccess}
    setError={setError}
    setAlertBar={setAlertBar}
    isFormSaved={isFormSaved}
    update={true}
    question={ques}
    handleModalState={closeModal}
  />
);

UpdateQuestion.propTypes = {
  ques: PropTypes.object,
  closeModal: PropTypes.func,
  setIsFormSaved: PropTypes.func,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  isFormSaved: PropTypes.bool
};

export default UpdateQuestion;

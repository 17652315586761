import { useState } from 'react';
import { Box, CssBaseline, Toolbar, IconButton, Grid, Avatar } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import OutlinedCard from '../components/OutlinedCard';
import { Navigate, Outlet } from 'react-router-dom';
import { AppBar, Main, DrawerHeader } from '../basePage/components/StyledComponents';
import { useSelector } from 'react-redux';
import AccountMenu from '../basePage/components/AccountMenu';
import { style } from 'constant/constants';
import Text from '../components/Text';

const AssessmentView = () => {
  const { authToken } = useSelector((store) => store.auth);
  const auth = !!authToken;

  const [open, setOpen] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);

  const { userData, loggedOut } = useSelector((store) => store.auth);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  if (!auth) {
    // When the user clicks the link in the email and is not logged in
    // when user logs out manually, we prevent storing route localy
    !loggedOut && localStorage.setItem('redirectAfterLogin', window.location.href);
    return <Navigate to="/login" />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {openMenu && <AccountMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />}
      <AppBar
        position="fixed"
        open={open}
        style={{ backgroundColor: style.backgroundColor, width: '100%' }}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}>
          <Grid display="flex" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}>
              <MenuIcon />
            </IconButton>
            <Text variant="h6" noWrap component="div">
              Employee Self Assessment
            </Text>
          </Grid>
          <Grid display="flex">
            <Text>{userData.fullName}</Text>
            <Avatar
              alt="Remy Sharp"
              src={userData.avatar}
              sx={{ width: 30, height: 30, marginLeft: 1 }}
              onClick={() => {
                setOpenMenu(!openMenu);
              }}
            />
          </Grid>
        </Toolbar>
      </AppBar>

      <Main open={open} className="bg-background">
        <DrawerHeader />
        <OutlinedCard>
          <Outlet />
        </OutlinedCard>
      </Main>
    </Box>
  );
};

export default AssessmentView;

import { Grid, Tooltip, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';

const CRUDActions = ({ onDelete, onEdit }) => {
  return (
    <Grid>
      <Tooltip title="Delete">
        <IconButton onClick={onDelete}>
          <DeleteForeverIcon style={{ color: '#800000' }} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Update">
        <IconButton onClick={onEdit}>
          <EditIcon style={{ color: '#040273' }} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

CRUDActions.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default CRUDActions;

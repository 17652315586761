import AssessmentForm from './AssessmentForm';
import { getValidUntilDate } from 'utils/dateFormatter';

const info = {
  name: '',
  description: '',
  role: '',
  validUntil: getValidUntilDate(),
  publish: false
};

const CreateAssement = () => (
  <AssessmentForm info={info} header="Create Assessment" checkedQuestions={[]} />
);

export default CreateAssement;

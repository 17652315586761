import OutlinedCard from 'components/OutlinedCard';
import { Typography, Grid } from '@mui/material';
import Input from 'components/Input';
import { useState } from 'react';
import { postRequest } from 'Api/api';
import { useParams } from 'react-router-dom';
import { strengthColor } from '../../utils/password-strength';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomButton from 'components/Button';
import LockIcon from '@mui/icons-material/Lock';
import Loader from 'components/Loader';
import CenteredWrapper from './CenteredWrapper';
import { passwordValidation } from '../../utils/password-strength';
import SuccessError from 'components/SuccessError';
import ModalComp from 'components/Modal';
import SuccessPopup from 'components/SuccessPopup';

const CreatePassword = () => {
  const { userId } = useParams();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);

  const [message, setMessage] = useState();
  const [errorMessage, setErrorMessage] = useState([]);
  const [success, setSuccess] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [errors, setErrors] = useState({});

  const [passwordInfo, setPasswordInfo] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  const valueChangeHandler = (e, input) => {
    setMessage(input === passwordInfo.newPassword ? strengthColor(e.target.value) : '');
    setPasswordInfo({ ...passwordInfo, [e.target.name]: e.target.value });
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPasswordValid = passwordValidation(passwordInfo.newPassword, ' password');
    handleError('newPassword', newPasswordValid);
    const confirmPasswordValid = passwordValidation(passwordInfo.confirmPassword, 'password again');
    handleError('confirmPassword', confirmPasswordValid);
    if (!confirmPasswordValid && passwordInfo.confirmPassword !== passwordInfo.newPassword) {
      handleError('confirmPassword', 'Passwords do not match');
    }
    if (
      newPasswordValid === '' &&
      confirmPasswordValid === '' &&
      passwordInfo.confirmPassword === passwordInfo.newPassword
    ) {
      setErrors(false);
      createPasswordHandler();
    }
  };

  const createPasswordHandler = async () => {
    setSuccess([]);
    setErrorMessage([]);
    setShowAlertBar(false);
    try {
      setShowLoader(true);
      const data = await postRequest('resetPassword', {
        userId,
        password: passwordInfo.newPassword,
        rePassword: passwordInfo.confirmPassword
      });
      setShowLoader(false);
      if (data.resStatus === 1) {
        setSuccessPopup(true);
        setSuccess([data.resMessage]);
      } else {
        setErrorMessage([data.resMessage]);
      }
      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage(err);
    }
  };

  return (
    <CenteredWrapper>
      {successPopup && (
        <ModalComp open={successPopup} setOpen={setSuccessPopup} sx={{ maxHeight: '50%' }}>
          <SuccessPopup successMessage={success} setSuccessPopup={setSuccessPopup} />
        </ModalComp>
      )}
      <OutlinedCard sx={{ maxWidth: 400 }}>
        <Typography
          variant="h4"
          className="text-menu-color-teal"
          justifyContent="center"
          textAlign="center"
          sx={{ fontFamily: 'serif', marginBottom: '5vh', marginTop: '2vh' }}>
          Create Password
        </Typography>
        <Grid container spacing={3}>
          {showAlertBar && (
            <SuccessError
              successMessage={success}
              sx={{ ml: '8vh', mt: '2vh' }}
              errorMessage={errorMessage}
              setShowAlertBar={setShowAlertBar}
            />
          )}

          <Input
            id="-password-login"
            name="newPassword"
            placeholder="Enter new password"
            label="New Password"
            value={passwordInfo.newPassword}
            error={errors.newPassword}
            required={true}
            changehandler={(e) => valueChangeHandler(e, passwordInfo.newPassword)}
            onClickHandler={() => {
              setShowNewPassword(!showNewPassword);
            }}
            onFocus={() => handleError('newPassword', null)}
            showPassword={showNewPassword}
          />

          {message?.label && (
            <Typography style={{ marginLeft: '9vh', marginTop: '2vh', color: message?.color }}>
              {message.label}
              <CheckCircleIcon style={{ color: message?.color }} />
            </Typography>
          )}

          <Input
            id="-password-login"
            name="confirmPassword"
            placeholder="Enter new password again"
            label="Confirm New Password"
            value={passwordInfo.confirmPassword}
            error={errors.confirmPassword}
            required={true}
            changehandler={valueChangeHandler}
            onClickHandler={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
            onFocus={() => handleError('confirmPassword', null)}
            showPassword={showConfirmPassword}
          />
          <Grid container justifyContent="end" alignItems="center" sx={{ mr: '5vh' }}>
            <CustomButton
              endIcon={<LockIcon />}
              onClick={handleSubmit}
              sx={{ marginBottom: '5vh' }}>
              Create Password
            </CustomButton>
          </Grid>
        </Grid>
        {showLoader && <Loader open={showLoader} />}
      </OutlinedCard>
    </CenteredWrapper>
  );
};

export default CreatePassword;

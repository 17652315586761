import LoginPage from '../authentication/LoginPage';
import ForgotPassword from 'pages/updatePassword/ForgotPassword';
import CreatePassword from 'pages/updatePassword/CreatePassword';
import NonAuthLayout from './NonAuthLayout';
import ValidateToken from 'pages/updatePassword/ValidateToken';

const NonAuthRoutes = {
  path: '/',
  element: <NonAuthLayout />,
  children: [
    {
      path: 'login',
      element: <LoginPage />
    },
    {
      path: '/create-password/:userId',
      element: <CreatePassword />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/validate-token/:queryString',
      element: <ValidateToken />
    }
  ]
};
export default NonAuthRoutes;

import Text from './Text';
import { useState } from 'react';
import PropTypes from 'prop-types';
import CustomButton from './Button';
import Input from './Input';
import { style } from 'constant/constants';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { authPostRequest } from 'Api/api';
import SuccessError from './SuccessError';
import Loader from './Loader';
import { Chip, Paper } from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useEffect } from 'react';
import { dateFormatter } from 'utils/dateFormatter';
import Table from './Table';
import { filterTable } from 'utils/utils';
import { emailRegex } from 'utils/email_strength';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5)
}));

const columns_options = { filter: true, sort: false };

const columns = [
  {
    name: 'sharedWith',
    label: 'Shared With',
    options: columns_options
  },
  {
    name: 'sharedBy',
    label: 'Shared By',
    options: columns_options
  },
  {
    name: 'sharedDate',
    label: 'Shared Date',
    options: {
      ...columns_options,
      customBodyRender: (value) => dateFormatter(value)
    }
  }
];

const SharePopup = ({ userAssessmentId }) => {
  const { userData } = useSelector((store) => store.auth);

  // Create a state variable to manage the chips
  const [chipsArray, setChipsArray] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [isAssessmentShared, setIsAssessmentShared] = useState(false);
  const [sharedAssessments, setSharedAssessments] = useState([]);
  const [emailInputValue, setEmailInputValue] = useState('');

  // Handle chip delete event
  const handleDelete = (emailToDelete) => {
    // Filter out the deleted email from the chip array
    const newChipsArray = chipsArray.filter((email) => email !== emailToDelete);
    setChipsArray(newChipsArray);

    // Update the text area value with the remaining emails
    const updatedTextAreaValue = newChipsArray.join(', ');
    setEmailInputValue(updatedTextAreaValue);
  };

  const valueChangeHandler = (e) => {
    // Split the user input emailList into an array using the comma as the separator
    const emailArray = e.target.value.split(',').map((email) => email.trim());

    // Update the chipsArray with unique, valid emails
    const uniqueEmails = [...new Set(emailArray.filter((email) => emailRegex.test(email)))];
    setChipsArray(uniqueEmails);

    // Update the text area value as the user types
    setEmailInputValue(e.target.value);
  };

  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const onSubmit = async () => {
    const requestParams = {
      userId: userData.userId,
      userAssessmentId: userAssessmentId,
      emailList: chipsArray
    };

    if (chipsArray.length > 0) {
      try {
        setErrorMessage([]);
        setSuccessMessage([]);

        setOpenLoader(true);
        setShowAlertBar(false);
        const saveAssessment = await authPostRequest('shareAssessment', requestParams);

        if (saveAssessment.resStatus === 1) {
          setSuccessMessage(saveAssessment.success);
          setIsAssessmentShared(!isAssessmentShared);
          setChipsArray([]);
          setEmailInputValue('');
        } else {
          setErrorMessage([saveAssessment.resMessage]);
        }
        setOpenLoader(false);
        setShowAlertBar(true);
      } catch (err) {
        setErrorMessage([...errorMessage, 'Error']);
      }
    } else {
      if (!emailInputValue) {
        handleError('email', 'Please enter email address');
      } else {
        handleError('email', 'Please enter valid email addresses separated by a comma.');
      }
    }
  };

  const getAssessmentSharedWith = async () => {
    try {
      setOpenLoader(true);
      const sharedAssessment = await authPostRequest('getAssessmentSharedWith', {
        userAssessmentId: userAssessmentId
      });

      if (sharedAssessment.resStatus === 1) {
        setSharedAssessments(sharedAssessment.sharedAssessmentList);
      }
      setOpenLoader(false);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getAssessmentSharedWith();
  }, [isAssessmentShared]);

  return (
    <div>
      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <Text style={{ margin: '5vh' }}>
        The assessment will be shared to the email address(es) provided below. For multiple email
        addresses, please separate them using commas.
      </Text>
      {chipsArray.length > 0 && (
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            p: 0.5,
            mt: 2,
            mx: 4
          }}
          component="ul">
          {chipsArray.map((email, index) => (
            <ListItem key={index}>
              <Chip
                label={email}
                onDelete={() => handleDelete(email)}
                color="success"
                variant="outlined"
              />
            </ListItem>
          ))}
        </Paper>
      )}
      <Input
        name="email"
        label="Email Address"
        type="email"
        fullWidth
        variant="standard"
        multiline={true}
        value={emailInputValue} /* Set the value of the text area */
        changehandler={valueChangeHandler}
        onFocus={() => handleError('email', null)}
        error={errors['email']}
      />
      <CustomButton onClick={onSubmit} endIcon={<ForwardToInboxIcon />}>
        Share
      </CustomButton>
      <Text variant="h6" style={{ color: style.textColor, marginLeft: '5vh' }}>
        Shared With
      </Text>
      <div className="mx-10">
        <Table data={sharedAssessments} options={filterTable} columns={columns} />
      </div>
      {openLoader && <Loader open={openLoader} />}
    </div>
  );
};

SharePopup.propTypes = {
  userAssessmentId: PropTypes.number
};

export default SharePopup;

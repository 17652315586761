import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { style } from 'constant/constants';

const DeleteSpan = ({ message, toBeDel }) => {
  return (
    <Grid style={{ fontFamily: style.fontFamily }}>
      Are you sure you want to delete the {message}
      <span style={{ backgroundColor: '#E2E5DE' }}> {toBeDel}</span>?
    </Grid>
  );
};

DeleteSpan.propTypes = {
  message: PropTypes.string,
  toBeDel: PropTypes.string
};

export default DeleteSpan;

import OutlinedCard from 'components/OutlinedCard';
import { Typography, Grid } from '@mui/material';
import Input from 'components/Input';
import { emailValidation } from 'utils/email_strength';
import { postRequest } from 'Api/api';
import { useState } from 'react';
import { style } from 'constant/constants';
import CustomButton from 'components/Button';
import CenteredWrapper from './CenteredWrapper';
import Loader from 'components/Loader';
import SuccessError from 'components/SuccessError';

const ForgotPassword = () => {
  const [error, setError] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [success, setSuccess] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [emailValue, setEmailValue] = useState('');

  const handleError = (errorMessage) => {
    setError(errorMessage);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setSuccess([]);
    setShowAlertBar(false);
    const email = emailValidation(emailValue);
    handleError(email);
    if (email === '') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setSuccess([]);
    setErrorMessage([]);
    try {
      setShowLoader(true);
      const data = await postRequest('forgotPassword', {
        email: emailValue
      });

      if (data.resStatus === 1) {
        setSuccess([data.resMessage]);
        setShowLoader(false);
        setEmailValue('');
      } else {
        setShowLoader(false);
        setErrorMessage([data.resMessage]);
      }
      setShowAlertBar(true);
    } catch (err) {
      setShowLoader(false);
      setError(err);
    }
  };

  return (
    <CenteredWrapper>
      <OutlinedCard direction="column" sx={{ maxWidth: 400 }}>
        <Typography
          variant="h4"
          className="text-menu-color-teal"
          justifyContent="center"
          textAlign="center"
          sx={{ fontFamily: 'serif', marginBottom: '4vh', marginTop: '2vh' }}>
          Forgot Password
        </Typography>

        {showAlertBar && (
          <SuccessError
            successMessage={success}
            errorMessage={errorMessage}
            setShowAlertBar={setShowAlertBar}
            sx={{ mb: '2vh' }}
          />
        )}

        <Grid container spacing={2}>
          <Input
            id="email-login"
            name="email"
            placeholder="Enter email address"
            type="email"
            error={error}
            required={true}
            onFocus={() => handleError(null)}
            label=" Email Address"
            htmlFor="email-login"
            changehandler={(e) => setEmailValue(e.target.value)}
            fullWidth
            value={emailValue}
          />

          <Grid container justifyContent="end" alignItems="center" sx={{ mr: '5vh' }}>
            <CustomButton
              type="submit"
              sx={{ fontFamily: style.fontFamily }}
              onClick={handleForgotPassword}>
              Forgot Password
            </CustomButton>
          </Grid>
        </Grid>
      </OutlinedCard>
      {showLoader && <Loader open={showLoader} />}
    </CenteredWrapper>
  );
};
export default ForgotPassword;

import { authPostRequest } from 'Api/api';
import { useEffect, useState } from 'react';

export const useGetAssessmentDataWithReviews = (reqParams) => {
  const [showLoader, setShowLoader] = useState(false);
  const [assessmentReviews, setAssessmentReviews] = useState([]);
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [empAssessmentData, setEmpAssessmentData] = useState({});
  const [reviews, setReviews] = useState('');

  const getReveiews = async () => {
    try {
      setShowLoader(true);
      const getReveiews = await authPostRequest(
        'getEmployeeAssessmentInHierarchyWithReviews',
        reqParams
      );
      if (getReveiews.resStatus === 1) {
        setReviews(getReveiews.reviewsList[0]?.reviews);
        setAssessmentReviews(getReveiews?.reviewsList);
        setEmpAssessmentData(getReveiews);
        setAssessmentQuestions(getReveiews.categoriesList);
      }
      setShowLoader(false);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getReveiews();
  }, []);

  return {
    showLoader,
    assessmentReviews,
    reviews,
    empAssessmentData,
    assessmentQuestions
  };
};

import { Task } from '@mui/icons-material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import GroupIcon from '@mui/icons-material/Group';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CategoryIcon from '@mui/icons-material/Category';
import ClassIcon from '@mui/icons-material/Class';
import MenuIcon from '@mui/icons-material/Menu';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

import { style } from 'constant/constants';

const styles = { color: style.textColor, fontFamily: style.fontFamily };

export const ICONS = [
  {
    name: 'Dashboard',
    icon: <MenuIcon style={styles} />,
    path: '/'
  },
  {
    name: 'Users',
    icon: <GroupIcon style={styles} />,
    path: '/users'
  },
  {
    name: 'Categories',
    icon: <CategoryIcon style={styles} />,
    path: '/categories'
  },
  {
    name: 'Sub-Categories',
    icon: <ClassIcon style={styles} />,
    path: '/sub-categories'
  },
  {
    name: 'Questions',
    icon: <QuestionAnswerIcon style={styles} />,
    path: '/questions'
  },
  {
    name: 'Assessments',
    icon: <AssessmentIcon style={styles} />,
    path: '/assesments'
  },
  {
    name: 'Assign Assessment',
    icon: <EditNoteIcon style={styles} />,
    path: '/assignAssessment'
  },
  {
    name: 'Assign Reviewers',
    icon: <Task style={styles} />,
    path: '/assign-reviewers'
  },
  {
    name: 'Manage Assessments',
    icon: <ListAltOutlinedIcon style={styles} />,
    path: '/manage-assessments'
  }
];

import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authToken: localStorage.getItem('token') || null,
    userData: JSON.parse(localStorage.getItem('user')) || {},
    loggedOut: false,
    publishAssessments: [],
    allUsers: []
  },
  reducers: {
    loggedIn: (state, action) => {
      state.authToken = action.payload;
      localStorage.setItem('token', action.payload);
    },

    loggedOut: (state) => {
      state.authToken = null;
      state.loggedOut = true;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('redirectAfterLogin');
    },

    addUser: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.userData = JSON.parse(localStorage.getItem('user'));
    },
    updateUser: (state, action) => {
      const localStorageKey = 'user';
      const existingData = localStorage.getItem(localStorageKey);
      const existingDataJSON = existingData ? JSON.parse(existingData) : {};
      const { avatar, firstName, lastName } = action.payload;
      const data = { ...existingDataJSON, avatar: avatar, fullName: lastName + ' ' + firstName };
      state.userData = data;
      localStorage.setItem(localStorageKey, JSON.stringify(data));
    },

    removeUser: (state) => {
      state.userData = null;
      localStorage.removeItem('user');
    },

    addAssessment: (state, action) => {
      state.publishAssessments = action.payload;
    },
    addUsers: (state, action) => {
      state.allUsers = action.payload;
      state.allUsers = state.allUsers?.filter((user) => user.active === true);
    }
  }
});

export const { loggedIn, loggedOut, addUser, removeUser, addAssessment, addUsers, updateUser } =
  authSlice.actions;
export default authSlice.reducer;

import ProfessionalInfoForm from './ProfessionalInfoForm';
import PropTypes from 'prop-types';

const info = {
  jobTitle: '',
  companyName: '',
  jobDescription: '',
  startDate: new Date(),
  endDate: new Date()
};

const AddProfessionalInfo = ({
  handleModalState,
  setAlertBar,
  setSuccess,
  setError,

  isFormSaved,
  setIsFormSaved
}) => {
  return (
    <ProfessionalInfoForm
      info={info}
      handleModalState={handleModalState}
      buttonText="Save & Add More"
      buttonClose="Save & Close"
      setSuccess={setSuccess}
      setError={setError}
      setAlertBar={setAlertBar}
      isFormSaved={isFormSaved}
      setIsFormSaved={setIsFormSaved}
    />
  );
};

AddProfessionalInfo.propTypes = {
  handleModalState: PropTypes.func,
  setIsFormSaved: PropTypes.func,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func,
  isFormSaved: PropTypes.bool
};

export default AddProfessionalInfo;

import CustomDatePicker from 'components/CustomDatePicker';
import SuccessError from 'components/SuccessError';
import { Stack } from '@mui/material';
import Input from 'components/Input';
import { useAssEditDetailsForm } from './useEditAssDetailsFrom';
import Loader from 'components/Loader';
import CustomButton from 'components/Button';
import Save from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import { stringDateFormatting } from 'utils/dateFormatter';

const InputFeilds = [
  {
    name: 'name',
    label: 'Assessment Name',
    type: 'text',
    placeholder: 'Enter assessment name',
    required: true
  },
  {
    name: 'description',
    label: 'Description',
    type: 'text',
    placeholder: 'Enter description',
    multiline: true,
    required: true
  }
];

const EditAssessmentDetailsPopup = ({
  info,
  closePopup,
  setSuccessMessage,
  setAlertBar,
  setIsUpdateAssessmentList
}) => {
  const {
    assesmentInfo,
    setAssesmentInfo,
    handleAssesmentInfo,
    errors,
    handleError,
    updateAssessment,
    errorMessage,
    openLoader,
    showAlertBar,
    setValidateDate,
    setShowAlertBar
  } = useAssEditDetailsForm(
    info,
    closePopup,
    setSuccessMessage,
    setAlertBar,
    setIsUpdateAssessmentList
  );

  return (
    <>
      <Stack sx={{ mt: '2vh', width: '100%' }}>
        {showAlertBar && (
          <SuccessError errorMessage={errorMessage} setShowAlertBar={setShowAlertBar} />
        )}
        {InputFeilds.map(({ name, type, placeholder, label, required, multiline }) => (
          <Stack key={name} sx={{ marginTop: '3vh' }}>
            <Input
              type={type}
              placeholder={placeholder}
              onChange={handleAssesmentInfo}
              value={assesmentInfo[name]}
              required={required}
              label={label}
              onFocus={() => handleError(name, null)}
              name={name}
              multiline={multiline}
              error={errors[name]}
            />
          </Stack>
        ))}

        <CustomDatePicker
          minDate={new Date()}
          dateLabel="Valid Until"
          required={true}
          name="validUntil"
          style={{ marginTop: '3vh' }}
          onChange={(e) =>
            setAssesmentInfo({ ...assesmentInfo, validUntil: stringDateFormatting(e) })
          }
          validate={setValidateDate}
          value={new Date(stringDateFormatting(assesmentInfo.validUntil))}
          message="Plesae select a future date for the assessment expiry."
        />
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mr: '5vh' }}>
          <CustomButton
            sx={{ ml: '15vh' }}
            type="submit"
            onClick={updateAssessment}
            endIcon={<Save />}>
            Save
          </CustomButton>
        </Stack>
      </Stack>

      {openLoader && <Loader open={openLoader} />}
    </>
  );
};

EditAssessmentDetailsPopup.propTypes = {
  info: PropTypes.object,
  closePopup: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  setAlertBar: PropTypes.func,
  setIsUpdateAssessmentList: PropTypes.func
};

export default EditAssessmentDetailsPopup;

import PropTypes from 'prop-types';
import Text from 'components/Text';
import { Grid } from '@mui/material';
import { style } from 'constant/constants';
import Table from 'components/Table';
import { tableOptionFilter } from 'utils/utils';
import useGetReport from './useGetReportHook';
import Loader from 'components/Loader';

const tableOptions = {
  ...tableOptionFilter,
  selectToolbarPlacement: 'none',
  tableBodyHeight: '64vh'
};

const AssessementReport = ({ title, columns, endPoint, options, components }) => {
  const { assesmentsList, showLoader } = useGetReport(endPoint);

  return (
    <>
      {showLoader && <Loader open={showLoader} />}
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Text variant="h4" style={{ color: style.textColor }}>
          {title}
        </Text>
      </Grid>
      <Table
        data={assesmentsList}
        options={options ? options : tableOptions}
        columns={columns}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        components={components}
      />
    </>
  );
};

export default AssessementReport;

AssessementReport.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  endPoint: PropTypes.string,
  options: PropTypes.object,
  components: PropTypes.node
};

import OpenLayout from './OpenLayout';
import ShareAssessment from 'pages/assessments/ShareAssessment';

const OpenRoutes = {
  path: '/',
  element: <OpenLayout />,
  children: [
    {
      path: '/shareAssessment/:queryString',
      element: <ShareAssessment />
    }
  ]
};
export default OpenRoutes;

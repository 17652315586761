import { useState, useEffect } from 'react';
import Table from 'components/Table';
import { Grid, Tooltip } from '@mui/material';
import DialogBox from 'components/DialogBox';
import CustomButton from 'components/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { authPostRequest } from 'Api/api';
import { dateFormatter } from 'utils/dateFormatter';
import UpdateProfessionalInfo from './UpdateProfessionalInfo';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import AddProfessionalInfo from './AddProfessionalInfo';
import { trancateText } from 'utils/utils';
import SuccessError from 'components/SuccessError';
import CRUDActions from 'components/CRUDActions';
import DeleteSpan from 'components/DeleteSpan';
import { tableOptions } from 'utils/utils';

const ProfessionalInfo = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [profDetail, setProfDetail] = useState();
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [profDetails, setProfDetails] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [isFormSaved, setIsFormSaved] = useState(false);

  const { userData } = useSelector((store) => store.auth);

  //For Open Dialog for delete/Update
  const handleDialogBoxOpen = (profInfo, setOpen) => {
    const rowData = profDetails.find((row) => row.professionalDetailId === profInfo);
    setProfDetail(rowData);
    setOpen(true);
  };

  //Open Modal for Add
  const handleAddModalOpen = () => {
    setAddModal(true);
  };

  //confirm for delete
  const handleConfirm = async () => {
    setSuccessMessage([]);
    setErrorMessage([]);
    try {
      const params = {
        userId: userData.userId,
        professionalDetailId: profDetail.professionalDetailId,
        id: userData.userId
      };
      setShowLoader(true);
      setShowAlertBar(false);
      const del = await authPostRequest('deleteProfessionalDetails', params);
      setShowLoader(false);

      setOpenDialog(!openDialog);
      if (del.resStatus === 1) {
        setSuccessMessage([del.resMessage]);
        getInfo();
      } else {
        setErrorMessage([del.resMessage]);
      }

      setShowAlertBar(true);
    } catch (err) {
      setErrorMessage(['Error']);
      setShowLoader(false);
    }
  };

  //Open or close delete dialog
  const handleDialogBoxState = () => {
    setOpenDialog(!openDialog);
  };

  //Open or close update modal
  const handleModalState = () => {
    setOpenModal(!openModal);
  };

  //Open or close add modal
  const handleAddModalState = () => {
    setAddModal(!addModal);
  };

  const getInfo = async () => {
    try {
      const requestParams = {
        userId: userData.userId
      };

      setShowLoader(true);
      const profInfo = await authPostRequest('getProfessionalDetails', requestParams);
      setShowLoader(false);

      if (profInfo.resStatus === 1) {
        setProfDetails(profInfo.professionalDetailsList);
      }
    } catch (err) {
      console.debug(err);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getInfo();
  }, [isFormSaved]);

  const columns = [
    {
      name: 'professionalDetailId',
      label: 'ID',
      options: {
        display: false // Hide the column from the table
      }
    },
    {
      name: 'jobTitle',
      label: 'Job Title',
      options: {
        filter: true,
        sort: false
      }
    },

    {
      name: 'companyName',
      label: 'Company',
      options: {
        filter: true,
        sort: false
      }
    },

    {
      name: 'jobDescription',
      label: 'Job Description',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <Tooltip title={value}>
            <span>{trancateText(value)}</span>
          </Tooltip>
        )
      }
    },

    {
      name: 'startDate',
      label: 'Start Date',
      options: {
        filter: true,
        sortDirection: 'desc',
        sort: false,
        customBodyRender: (value) => {
          return dateFormatter(value);
        }
      }
    },

    {
      name: 'endDate',
      label: 'End Date',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return dateFormatter(value);
        }
      }
    },

    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,

        //Actions Delete, Update
        customBodyRender: (value, tableMeta) => {
          return (
            <CRUDActions
              onDelete={() => handleDialogBoxOpen(tableMeta.rowData[0], setOpenDialog)}
              onEdit={() => handleDialogBoxOpen(tableMeta.rowData[0], setOpenModal)}
            />
          );
        }
      }
    }
  ];

  return (
    <Grid sx={{ width: '100%' }}>
      <Grid container direction="row" justifyContent="flex-end" sx={12}>
        <CustomButton endIcon={<AddIcon />} onClick={handleAddModalOpen}>
          Add
        </CustomButton>
      </Grid>

      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <Table data={profDetails} options={tableOptions} columns={columns} />

      {showLoader && <Loader open={showLoader} />}
      {openDialog && (
        <DialogBox
          handleDialogBoxState={handleDialogBoxState}
          openDialogBox={openDialog}
          handleConfirmAction={handleConfirm}
          deleteMessage={<DeleteSpan message="information" toBeDel={profDetail.companyName} />}
        />
      )}

      {openModal && (
        <Modal open={openModal} setOpen={setOpenModal} header="Update Professional Information">
          <UpdateProfessionalInfo
            handleModalState={handleModalState}
            prof={profDetail}
            isFormSaved={isFormSaved}
            setIsFormSaved={setIsFormSaved}
            setSuccess={setSuccessMessage}
            setError={setErrorMessage}
            setAlertBar={setShowAlertBar}
          />
        </Modal>
      )}

      {addModal && (
        <Modal open={addModal} setOpen={setAddModal} header="Add Professional Information">
          <AddProfessionalInfo
            handleModalState={handleAddModalState}
            isFormSaved={isFormSaved}
            setIsFormSaved={setIsFormSaved}
            setSuccess={setSuccessMessage}
            setError={setErrorMessage}
            setAlertBar={setShowAlertBar}
          />
        </Modal>
      )}
    </Grid>
  );
};
export default ProfessionalInfo;

import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const NonAuthLayout = () => {
  const { authToken } = useSelector((store) => store.auth);
  const auth = !!authToken;
  if (auth) {
    const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
    if (redirectAfterLogin) {
      window.location.href = redirectAfterLogin;
    }
    return <Navigate to="/" />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default NonAuthLayout;

// has number
const hasNumber = (number) => new RegExp(/[0-9]/).test(number);

// has mix of small and capitals
const hasMixed = (number) => new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);

// has special chars
const hasSpecial = (number) => new RegExp(/[!#@$%^&*)(+=._-]/).test(number);

// password strength indicator
const strengthIndicator = (number) => {
  let strengths = 0;
  if (number.length > 5) strengths += 1;
  if (number.length > 7) strengths += 1;
  if (hasNumber(number)) strengths += 1;
  if (hasSpecial(number)) strengths += 1;
  if (hasMixed(number)) strengths += 1;
  return strengths;
};

// set color based on password strength
export const strengthColor = (password) => {
  const count = strengthIndicator(password);
  if (count < 3) return { label: 'Weak', color: 'red', count };
  if (count < 4) return { label: 'Normal', color: 'orange', count };
  if (count < 5) return { label: 'Good', color: 'skyBlue', count };
  if (count < 6) return { label: 'Strong', color: 'GREEN', count };
  return { label: 'Poor', color: 'red' };
};

export const passwordValidation = (password, name) => {
  if (password.trim().length <= 0 || !password) {
    return `Please enter ${name}`;
  } else if (password.length < 6) {
    return 'Must have at least 6 characters';
  } else {
    return '';
  }
};

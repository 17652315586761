import { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { style } from 'constant/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { isNotAdmin } from 'utils/utils';
import { useSelector } from 'react-redux';
import CustomButton from './Button';
import { ArrowBack } from '@mui/icons-material';
import { useEffect } from 'react';

export const TAB_HEADERS = [
  {
    name: 'Personal',
    path: '/profile-info/personal-info',
    pathUser: '/profileInfo/personalInfo'
  },

  {
    name: 'Professional',
    path: '/profile-info/professional-info',
    pathUser: '/profileInfo/professionalInfo'
  },

  {
    name: 'Rodgers',
    path: '/profile-info/rodgers-info',
    pathUser: '/profileInfo/rodgersInfo'
  }
];

export function ColorTabs() {
  const location = useLocation();

  const { userData } = useSelector((store) => store.auth);
  const navigate = useNavigate();

  const [value, setValue] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  //Enable tabs according to their path
  useEffect(() => {
    if (
      location.pathname === TAB_HEADERS[0].path ||
      location.pathname === TAB_HEADERS[0].pathUser
    ) {
      setValue('Personal');
    }
    if (
      location.pathname === TAB_HEADERS[1].path ||
      location.pathname === TAB_HEADERS[1].pathUser
    ) {
      setValue('Professional');
    }
    if (
      location.pathname === TAB_HEADERS[2].path ||
      location.pathname === TAB_HEADERS[2].pathUser
    ) {
      setValue('Rodgers');
    }
  }, [location]);

  return (
    <Box sx={{ width: '100%' }}>
      {isNotAdmin(userData.roles) && (
        <Grid container alignItems="left">
          <CustomButton onClick={goToDashboard} endIcon={<ArrowBack />}>
            Dashboard
          </CustomButton>
        </Grid>
      )}
      <Tabs value={value} onChange={handleChange} variant="fullWidth">
        {TAB_HEADERS.map(({ name, path, pathUser }) => (
          <Tab
            value={name}
            label={name}
            key={name}
            component={Link}
            to={isNotAdmin(userData.roles) ? pathUser : path}
            style={{ fontFamily: style.fontFamily }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

const ProfileLayout = () => {
  return (
    <Grid>
      <ColorTabs />
      <Outlet />
    </Grid>
  );
};

export default ProfileLayout;

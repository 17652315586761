import Loader from 'components/Loader';
import { Divider, Paper, Stack } from '@mui/material';
import Text from 'components/Text';
import { style } from 'constant/constants';
import PropTypes from 'prop-types';
import AssessmentQuestions from 'pages/assessments/AssessmentQuestions';
import { dateFormatter } from 'utils/dateFormatter';
import { useGetAssessmentDataWithReviews } from 'pages/assessments/useGetAssessmentDataWithReviews';

const ViewPopup = ({ assessment }) => {
  const reqParams = {
    userId: assessment[1],
    assessmentId: assessment[0]
  };

  const { assessmentQuestions, empAssessmentData, assessmentReviews, showLoader } =
    useGetAssessmentDataWithReviews(reqParams);

  return (
    <div>
      {showLoader && <Loader open={showLoader} />}
      <Stack sx={{ my: '2vh' }} direction="row" justifyContent="space-between">
        <div>
          <Text style={{ color: style.textGreen }}>Employee: {assessment[2]}</Text>
          <Text style={{ color: style.textGreen, marginBottom: '1vh' }}>
            Submit Date: {dateFormatter(empAssessmentData.submittedDate)}
          </Text>
        </div>
        <div className="text-center text-menu-color-teal" style={{ maxWidth: 600 }}>
          <Text variant="h4">{empAssessmentData.name}</Text>
          <Text>{empAssessmentData.description}</Text>
          <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mr: '5vh' }}>
            <Text sx={{ mr: 15 }}>Valid Until:&nbsp;</Text>
            <Text sx={{ mr: 15 }}>{dateFormatter(empAssessmentData.validUntil)}</Text>
          </Stack>
        </div>
        <Text style={{ color: style.textGreen, marginBottom: '1vh' }}>
          Score: {empAssessmentData.score}%
        </Text>
      </Stack>
      <AssessmentQuestions assessmentQuestions={assessmentQuestions} />
      {assessmentReviews.length > 0 && (
        <Paper sx={{ p: 4, border: `1px solid ${style.textColor}`, mt: 6 }}>
          <div className="text-menu-color-teal">
            <Text variant="h5">Reviews</Text>
          </div>
          {assessmentReviews.map((review) => {
            return (
              <Stack key={review.reviewerId} sx={{ mx: 4, mb: 2 }}>
                <Text style={{ color: style.textColor }}>
                  <b>Reviews:</b> {review.reviews}
                </Text>
                <Stack direction="row" spacing={2}>
                  <Text style={{ fontSize: 12, color: style.textColor }}>
                    Reviewer: <i>{review.reviewerName}</i>
                  </Text>
                  <Text style={{ fontSize: 12, color: style.textColor }}>
                    Review Date: <i>{dateFormatter(review.reviewDate)}</i>
                  </Text>
                </Stack>
                <Divider sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }} />
              </Stack>
            );
          })}
        </Paper>
      )}
    </div>
  );
};

ViewPopup.propTypes = {
  assessment: PropTypes.array
};

export default ViewPopup;

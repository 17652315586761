import { Grid, Typography } from '@mui/material';
import SuccessPopup from 'components/SuccessPopup';
import CenteredWrapper from './CenteredWrapper';
import Input from 'components/Input';
import CustomButton from 'components/Button';
import OutlinedCard from 'components/OutlinedCard';
import LockResetIcon from '@mui/icons-material/LockReset';
import ClearIcon from '@mui/icons-material/Clear';
import { useResetPassword } from './useResetPassword';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import SuccessError from 'components/SuccessError';
import Loader from 'components/Loader';
import ModalComp from 'components/Modal';

const ResetPassword = () => {
  const {
    message,
    showNewPassword,
    showOldPassword,
    errorMessage,
    success,
    successPopup,
    setSuccessPopup,
    errors,
    showLoader,
    showAlertBar,
    setShowConfirmPassword,
    showConfirmPassword,
    setShowAlertBar,
    handleError,
    handleSubmit,
    setShowOldPassword,
    setShowNewPassword,
    valueChangeHandler,
    handleClose,
    passwordInfo
  } = useResetPassword();

  const { authToken } = useSelector((store) => store.auth);
  const auth = !!authToken;
  if (!auth) {
    return <Navigate to="/login" />;
  }
  return (
    <CenteredWrapper>
      {successPopup && (
        <ModalComp open={successPopup} setOpen={setSuccessPopup} sx={{ maxHeight: '50%' }}>
          <SuccessPopup successMessage={success} setSuccessPopup={setSuccessPopup} />
        </ModalComp>
      )}
      <OutlinedCard
        sx={{
          maxWidth: 400
        }}>
        <Typography
          variant="h4"
          className="text-menu-color-teal"
          justifyContent="center"
          textAlign="center"
          sx={{ fontFamily: 'serif', marginBottom: '4vh' }}>
          Reset Password
        </Typography>
        <Grid container spacing={3}>
          {showAlertBar && (
            <SuccessError
              sx={{ ml: '8vh', mt: '2vh' }}
              errorMessage={errorMessage}
              setShowAlertBar={setShowAlertBar}
            />
          )}

          <Input
            id="-password-login"
            name="oldPassword"
            placeholder="Enter old password"
            label="Old Password"
            value={passwordInfo.oldPassword}
            error={errors.oldPassword}
            required={true}
            changehandler={valueChangeHandler}
            onClickHandler={() => {
              setShowOldPassword(!showOldPassword);
            }}
            onFocus={() => handleError('oldPassword', null)}
            showPassword={showOldPassword}
          />

          <Input
            id="-password-login"
            name="newPassword"
            placeholder="Enter new password"
            label="New Password"
            value={passwordInfo.newPassword}
            error={errors.newPassword}
            required={true}
            changehandler={(e) => valueChangeHandler(e, passwordInfo.newPassword)}
            onClickHandler={() => {
              setShowNewPassword(!showNewPassword);
            }}
            onFocus={() => handleError('newPassword', null)}
            showPassword={showNewPassword}
          />
          {message?.label && (
            <Typography style={{ marginLeft: '9vh', marginTop: '2vh', color: message?.color }}>
              {message.label}
              <CheckCircleIcon style={{ color: message?.color }} />
            </Typography>
          )}
          <Input
            id="-password-login"
            name="confirmPassword"
            placeholder="Enter new password again"
            label="Confirm New Password"
            value={passwordInfo.confirmPassword}
            error={errors.confirmPassword}
            required={true}
            changehandler={valueChangeHandler}
            onClickHandler={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
            onFocus={() => handleError('confirmPassword', null)}
            showPassword={showConfirmPassword}
          />

          <Grid container justifyContent="end" alignItems="center" sx={{ mr: '5vh' }}>
            <CustomButton endIcon={<LockResetIcon />} onClick={handleSubmit}>
              Reset Password
            </CustomButton>
            <CustomButton endIcon={<ClearIcon />} onClick={handleClose}>
              Cancel
            </CustomButton>
          </Grid>
        </Grid>
        {showLoader && <Loader open={showLoader} />}
      </OutlinedCard>
    </CenteredWrapper>
  );
};

export default ResetPassword;

import BasePage from 'basePage/BasePage';
import ProfileLayout from 'components/ProfileLayout';
import PersonalInfo from 'pages/ProfileInfo/PersonalInfo/PersonalInfo';
import ProfessionalInfo from 'pages/ProfileInfo/ProfessionalInfo/ProfessionalInfo';
import RodgersInfo from 'pages/ProfileInfo/RodgersInfo/RodgersInfo';
import AssignReviewers from 'pages/assessments/AssignReviewers';
import AssignAssessment from 'pages/assign Assessment/AssignAssessment';
import Assessments from 'pages/assessments/Assesments';
import CreateAssement from 'pages/assessments/CreateAssement';
import Categories from 'pages/categories/Categories';
import AdminDashboard from 'pages/dashboard-admin/AdminDashboard';
import Questions from 'pages/questions/Questions';
import SubCategories from 'pages/subCategory/SubCategories';
import CreateUser from 'pages/userPages/CreateUser';
import Users from 'pages/userPages/Users';
import UpdateAssessment from 'pages/assessments/UpdateAssessment';
import ManageAssessments from 'pages/summary/ManageAssessments';
import DummyReport from 'pages/reports/DummyReport';

const AuthRoutes = {
  path: '/',
  element: <BasePage />,
  children: [
    {
      path: '/',
      element: <AdminDashboard />
    },
    {
      path: 'create-user',
      element: <CreateUser />
    },
    {
      path: '/report',
      element: <DummyReport />
    },
    {
      path: 'users',
      element: <Users />
    },
    {
      path: 'profile-info',
      element: <ProfileLayout />,
      children: [
        {
          path: 'personal-info',
          element: <PersonalInfo />
        },
        {
          path: 'professional-info',
          element: <ProfessionalInfo />
        },
        {
          path: 'rodgers-info',
          element: <RodgersInfo />
        }
      ]
    },
    {
      path: 'categories',
      element: <Categories />
    },
    {
      path: 'sub-categories',
      element: <SubCategories />
    },
    {
      path: 'questions',
      element: <Questions />
    },
    {
      path: 'assignAssessment',
      element: <AssignAssessment />
    },
    {
      path: 'assign-reviewers',
      element: <AssignReviewers />
    },
    {
      path: 'assesments',
      element: <Assessments />
    },
    {
      path: 'create-assesment',
      element: <CreateAssement />
    },
    {
      path: 'update-assesment/:id',
      element: <UpdateAssessment />
    },
    {
      path: 'manage-assessments',
      element: <ManageAssessments />
    }
  ]
};

export default AuthRoutes;

import { InputLabel, Select, MenuItem, Stack } from '@mui/material';
import { style } from '../constant/constants';
import PropTypes from 'prop-types';

const CustomDropdown = ({ value, onChange, list, name, label, isId, disabled = false }) => {
  return (
    <Stack justifyContent="center" sx={{ mt: '3vh', mx: '5vh', mb: '4vh' }}>
      <InputLabel
        htmlFor="demo-simple-select-label"
        sx={{
          fontFamily: style.fontFamily,
          color: style.textGreen,
          marginBottom: 1
        }}
        className="text-menu-color-green">
        {label}
      </InputLabel>

      <Select
        value={value}
        onChange={onChange}
        style={{ height: '40px' }}
        name={name}
        disabled={disabled}>
        {list.length > 0 &&
          list.map(({ name, id }) => {
            return (
              <MenuItem value={isId ? id : name} key={id}>
                {name}
              </MenuItem>
            );
          })}
      </Select>
    </Stack>
  );
};

CustomDropdown.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  list: PropTypes.array,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  isId: PropTypes.bool, // to use id in place of name as value it should be set to true
  disabled: PropTypes.bool
};

export default CustomDropdown;

import * as React from 'react';
// import Divider from '@mui/material/Divider';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import { makeStyles } from '@material-ui/core/styles';
import Text from './Text';
import { style } from 'constant/constants';

const steps = ['Almost Never', 'Rarely', 'Sometimes', 'Often', 'Almost Always'];
const stepperStyle = { marginRight: 10, color: style.textColor };

const useStyles = makeStyles(() => ({
  divider: {
    width: '100px', // Width of the divider
    height: '2px', // Height of the divider
    backgroundColor: style.textColor, // Customize divider color
    margin: '0 10px', // Adjust margin between icons and dividers
    marginBottom: '10px'
  }
}));

const AssesmentScale = () => {
  const classes = useStyles();
  return (
    <div className="grid h-16 place-items-center">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ color: style.textGreen, marginRight: '18px' }}>(Left)</Text>
        {steps.map((step, index) => (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
              {index == 0 && <LooksOneIcon style={stepperStyle} />}
              {index == 1 && <LooksTwoIcon style={stepperStyle} />}
              {index == 2 && <Looks3Icon style={stepperStyle} />}
              {index == 3 && <Looks4Icon style={stepperStyle} />}
              {index == 4 && <Looks5Icon style={stepperStyle} />}
              <Text variant="body1" component="span">
                {step}
              </Text>
            </div>
            {index + 1 < steps.length && <div className={classes.divider}></div>}
          </>
        ))}
        <Text style={{ color: style.textGreen, marginLeft: '18px' }}>(Right)</Text>
      </div>
    </div>
  );
};

export default AssesmentScale;

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getParamsFromUrl } from 'utils/DecodeToken';
import { useState } from 'react';
import { useEffect } from 'react';
import { postRequest, authPostRequest } from 'Api/api';
import { useSelector } from 'react-redux';
import { checkTextLength } from 'utils/utils';

export const useReviewAssessmentHook = () => {
  const { queryString } = useParams();

  const decodedParams = atob(queryString);
  const params = getParamsFromUrl(decodedParams);

  const [showLoader, setShowLoader] = useState(false);
  const [assessmentReviews, setAssessmentReviews] = useState([]);
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [empAssessmentData, setEmpAssessmentData] = useState({});
  const [reviews, setReviews] = useState('');
  const [errors, setErrors] = useState({});
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const { userData } = useSelector((store) => store.auth);

  const navigate = useNavigate();

  //   to handle the reviews
  const onReviewsChange = (e) => {
    setReviews(e.target.value);
  };

  //   fetching the reviews of reviewer when done.
  const getReveiews = async () => {
    const reqParams = {
      userId: params.userId,
      assessmentId: params.assessment,
      reviewerId: params.reviewerId
    };

    try {
      setShowLoader(true);
      const assessmentReviews = await postRequest(
        'getEmployeeAssessmentInHierarchyWithReviews',
        reqParams
      );

      if (assessmentReviews.resStatus === 1) {
        setReviews(
          assessmentReviews.reviewsList !== null ? assessmentReviews.reviewsList[0]?.reviews : ''
        );
        setAssessmentReviews(assessmentReviews?.reviewsList);
        setEmpAssessmentData(assessmentReviews);
        setAssessmentQuestions(assessmentReviews.categoriesList);
      }
      setShowLoader(false);
      localStorage.removeItem('redirectAfterLogin'); // Remove the stored URL after using it

      // to redirect if Assessment is unpublished
      if (!assessmentReviews?.publish) {
        navigate('/not-available');
      }
    } catch (err) {
      return err;
    }
  };

  //   to handle the review assessment Submission
  const handleReviewSubmission = async () => {
    if (!reviews) {
      handleError('reviews', 'Please provide feedback');
    }

    const isReviewsLengthValid = checkTextLength(reviews, 500);
    if (isReviewsLengthValid) {
      handleError('reviews', 'Reviews range must be less than 500 ');
    }

    const trimWhiteSpace = reviews.trim();
    if (trimWhiteSpace.length === 0) {
      handleError('reviews', 'Please provide feedback');
    }

    if (reviews && !isReviewsLengthValid && trimWhiteSpace.length > 0) {
      setErrors({});
      const reqParams = {
        userId: params.userId,
        assessmentId: params.assessment,
        reviewer: params.reviewerId,
        reviews: reviews.trim()
      };

      try {
        setShowLoader(true);
        const saveReviews = await authPostRequest('saveReviews', reqParams);
        if (saveReviews.resStatus === 1) {
          navigate('/success');
        } else {
          setErrorMessage([saveReviews.resMessage]);
          setShowAlertBar(true);
        }
        setShowLoader(false);
      } catch (err) {
        return err;
      }
    }
  };

  //   to handle the reviews error
  const handleError = (input, errorMessage) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  // to redirect to invalid user incase the assessment is not for the logged in user.
  useEffect(() => {
    getReveiews();
    if (!isNaN(parseInt(params.reviewerId)) && parseInt(params.reviewerId) !== userData.userId) {
      navigate('/invalid-user');
    }
  }, []);

  return {
    showLoader,
    assessmentReviews,
    reviews,
    handleReviewSubmission,
    onReviewsChange,
    handleError,
    errors,
    empAssessmentData,
    assessmentQuestions,
    showAlertBar,
    errorMessage,
    setShowAlertBar
  };
};

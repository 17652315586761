import { useNavigate } from 'react-router-dom';
import Text from './Text';
import CustomButton from './Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessMessage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen text-menu-color-green">
      <CheckCircleIcon style={{ fontSize: 64 }} size="large" />
      <br />
      <Text variant="h3">Thank You</Text>
      <Text variant="h5">Your response has been submitted successfully</Text>
      <CustomButton
        onClick={() => {
          navigate('/');
        }}
        endIcon={<CheckCircleIcon />}>
        OK
      </CustomButton>
    </div>
  );
};
export default SuccessMessage;

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getParamsFromUrl } from 'utils/DecodeToken';
import { useState } from 'react';
import { useEffect } from 'react';
import { authPostRequest } from 'Api/api';
import { Paper, Stack, Rating, Grid } from '@mui/material';
import Text from 'components/Text';
import { style } from 'constant/constants';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CategoryIcon from '@mui/icons-material/Category';
import ClassIcon from '@mui/icons-material/Class';
import CustomButton from 'components/Button';
import SaveIcon from '@mui/icons-material/Save';
import Loader from 'components/Loader';
import { useSelector } from 'react-redux';
import SuccessError from 'components/SuccessError';
import { removeTimeZoneOffset, stringDateFormatting } from 'utils/dateFormatter';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AssesmentScale from 'components/AssessmentScale';
import { stepperText } from 'utils/utils';

const categoryColor = [style.textColor, style.textGreen, '#bf9001'];

const Fill_Assessment = () => {
  const { queryString } = useParams();

  const decodedParams = atob(queryString);

  const params = getParamsFromUrl(decodedParams);

  const [showLoader, setShowLoader] = useState(false);
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [empAssessmentData, setEmpAssessmentData] = useState({});
  const [answers, setAnswers] = useState([]);
  const [missingAnswer, setMissingAnswer] = useState({});
  const [errorMessage, setErrorMessage] = useState([]);

  const { userData } = useSelector((store) => store.auth);

  const allQuestionIds = [];

  const navigate = useNavigate();

  const handleRatingChange = (e, extraArgument) => {
    // Find the index of the question in answers array
    const existingAnswerIndex = answers.findIndex((answer) => answer.questionId === extraArgument);

    if (existingAnswerIndex !== -1) {
      // If the same question is already in answers
      const existingAnswer = answers[existingAnswerIndex];

      // Check if the clicked value is empty
      if (e.target.value === '') {
        // If the user emptied the rating, remove the existing answer
        setAnswers((prevAns) => [
          ...prevAns.slice(0, existingAnswerIndex),
          ...prevAns.slice(existingAnswerIndex + 1)
        ]);
        setMissingAnswer((prevMissing) => ({ ...prevMissing, [extraArgument]: true }));
      }
      // Check if the clicked value is the same as the existing answer
      else if (existingAnswer.answer === e.target.value) {
        // If the same value is clicked twice, remove the existing answer
        setAnswers((prevAns) => [
          ...prevAns.slice(0, existingAnswerIndex),
          ...prevAns.slice(existingAnswerIndex + 1)
        ]);
        setMissingAnswer((prevMissing) => ({ ...prevMissing, [extraArgument]: true }));
      } else {
        // If a different value is clicked, update the existing answer
        const updatedAnswers = [...answers];
        updatedAnswers[existingAnswerIndex] = { ...existingAnswer, answer: e.target.value };
        setAnswers(updatedAnswers);
        setMissingAnswer((prevMissing) => ({ ...prevMissing, [extraArgument]: false }));
      }
    } else {
      // If the question is not in answers, add a new answer
      const newAnswer = { questionId: extraArgument, answer: e.target.value };
      setAnswers((prevAns) => [...prevAns, newAnswer]);
      setMissingAnswer((prevMissing) => ({ ...prevMissing, [extraArgument]: false }));
    }
  };

  const handleSubmit = async () => {
    const uniqueAnswersMap = new Map();
    answers.forEach((answer) => {
      uniqueAnswersMap.set(answer.questionId, answer);
    });

    assessmentQuestions.forEach((category) => {
      category.subCategoryList.forEach((subCategory) => {
        subCategory.questionsList.forEach((question) => {
          allQuestionIds.push(question.questionId);
        });
      });
    });

    const missingQuestionIds = allQuestionIds.filter(
      (id) => !answers.some((answer) => answer.questionId === id)
    );

    const missingQuestionMap = missingQuestionIds.reduce((map, id) => {
      map[id] = false;
      return map;
    }, {});

    setMissingAnswer(missingQuestionMap);

    if (missingQuestionIds.length > 0) {
      // Update missingAnswer state to mark unanswered questions
      const newMissingAnswer = {};
      missingQuestionIds.forEach((id) => {
        newMissingAnswer[id] = true;
      });
      setMissingAnswer(newMissingAnswer);
      return; // Don't proceed if there are unanswered questions
    }

    const reqParams = {
      userId: params.userId,
      userAssessmentRequest: { assessmentId: params.assessment, userId: params.userId },
      assessmentAnswersRequestList: Array.from(uniqueAnswersMap.values())
    };

    try {
      setShowLoader(true);
      const saveAssessment = await authPostRequest('saveEmployeeAssessment', reqParams);
      if (saveAssessment.resStatus === 1) {
        navigate('/success');
      } else {
        setErrorMessage([saveAssessment.resMessage]);
        setShowAlertBar(true);
      }
      setShowLoader(false);
    } catch (err) {
      return err;
    }
  };

  const questionFunc = (question, color) => {
    return (
      <li
        style={{
          marginLeft: '30px',
          color: color,
          fontSize: '16px'
        }}
        key={question.questionId}>
        <Grid container spacing={12}>
          <Grid item xs={8} display="flex" direction="row">
            <div>
              <QuestionAnswerIcon sx={{ mr: 2 }} />
            </div>
            {question.question}
          </Grid>
          <Grid item xs={2}>
            {question.answer !== null ? (
              <Rating
                size="large"
                icon={<RadioButtonCheckedIcon fontSize="inherit" />}
                emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
                readOnly={question.answer !== null}
                value={parseInt(question.answer)}
                onChange={(e) => handleRatingChange(e, question.questionId)}
                sx={{
                  '& .MuiRating-iconFilled': {
                    color: style.textColor // Change the color as needed
                  }
                }}
              />
            ) : (
              <>
                <Rating
                  size="large"
                  icon={<RadioButtonCheckedIcon fontSize="inherit" />}
                  emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
                  onChange={(e) => handleRatingChange(e, question.questionId)}
                  sx={{
                    '& .MuiRating-iconFilled': {
                      color: style.textColor // Change the color as needed
                    },
                    // Change colors as needed
                    '& .MuiRating-iconEmpty': {
                      color: missingAnswer[question.questionId] ? 'red' : 'grey'
                    }
                  }}
                />
              </>
            )}
          </Grid>
          <Grid item xs={2}>
            {missingAnswer[question.questionId] && <p style={{ color: 'red' }}>* Required</p>}
          </Grid>
        </Grid>
      </li>
    );
  };

  const subCategoryFunc = (subCategory, color) => (
    <li
      style={{
        marginLeft: '22px',
        fontSize: '18px',
        color: color
      }}
      key={subCategory.name}>
      <ClassIcon sx={{ mr: 2 }} />
      {subCategory.name}
      <ul>{subCategory.questionsList.map((question) => questionFunc(question, color))}</ul>
    </li>
  );

  const categoryFunc = (category, color) => (
    <li
      style={{
        marginLeft: '10px',
        color: color,
        fontFamily: style.fontFamily,
        fontSize: '22px'
      }}
      key={category.categoryId}>
      <CategoryIcon sx={{ mr: 2 }} />
      {category.name}
      <ul>{category.subCategoryList.map((subCategory) => subCategoryFunc(subCategory, color))}</ul>
    </li>
  );

  const nestedOrderedList = (data) => (
    <div className="mx-12">
      <ul style={{ marginLeft: 15, marginTop: 10 }}>
        {data.map((category) =>
          categoryFunc(category, categoryColor[category.categoryId % categoryColor.length])
        )}
      </ul>
    </div>
  );

  const getEmployeeAssessmentInHierarchy = async () => {
    try {
      setShowLoader(true);
      const empAssessment = await authPostRequest('getEmployeeAssessmentInHierarchy', {
        assessmentId: params.assessment,
        userId: params.userId
      });

      const date = new Date();

      if (empAssessment.resStatus === 1) {
        setEmpAssessmentData(empAssessment);
        setAssessmentQuestions(empAssessment.categoriesList);
      }
      setShowLoader(false);
      localStorage.removeItem('redirectAfterLogin'); // Remove the stored URL after using it

      // to riedirect to the expiry page if the validty of assessment,
      // is expired and user hasnt filled the assessment yet.
      if (
        Date.parse(date.toDateString()) >
          Date.parse(stringDateFormatting(empAssessment.validUntil)) &&
        empAssessment.categoriesList[0].subCategoryList[0].questionsList[0].answer === null
      ) {
        navigate('/expiry');
      }

      // to redirect if Assessment is unpublished
      if (!empAssessment?.publish) {
        navigate('/not-available');
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getEmployeeAssessmentInHierarchy();
  }, []);

  // to redirect to invalid user incase the assessment is not for the logged in user.
  useEffect(() => {
    if (userData.userId !== parseInt(params.userId)) {
      navigate('/invalid-user');
    }
  }, []);

  return (
    <Paper
      sx={{
        p: 2,
        margin: 4,
        maxWidth: '100%',
        flexGrow: 1
      }}>
      {showAlertBar && (
        <SuccessError errorMessage={errorMessage} setShowAlertBar={setShowAlertBar} />
      )}
      {assessmentQuestions.length > 0 &&
        assessmentQuestions[0].subCategoryList[0].questionsList[0].answer !== null && (
          <Text variant="h4" className="text-center mb-12 text-menu-color-green">
            You have filled this assessment.
          </Text>
        )}
      <div className="text-center text-menu-color-teal">
        <Text variant="h4">{empAssessmentData.name}</Text>
        <Text>{empAssessmentData.description}</Text>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mr: '5vh' }}>
          <Text sx={{ mr: 15 }}>Valid Until:&nbsp;</Text>
          <Text sx={{ mr: 15 }}>
            {
              new Date(removeTimeZoneOffset(empAssessmentData.validUntil))
                ?.toString()
                .split('GMT')[0]
            }
          </Text>
        </Stack>
      </div>

      <AssesmentScale />

      <Text variant="p" style={{ color: style.textColor, marginTop: 10 }}>
        {stepperText}
      </Text>

      <Text style={{ color: 'red' }} sx={{ textAlign: 'center' }}>
        *All questions are mandatory.
      </Text>

      {assessmentQuestions.length > 0 && nestedOrderedList(assessmentQuestions)}

      {assessmentQuestions.length > 0 &&
        assessmentQuestions[0].subCategoryList[0].questionsList[0].answer === null && (
          <CustomButton endIcon={<SaveIcon />} onClick={handleSubmit}>
            Submit
          </CustomButton>
        )}

      {showLoader && <Loader open={showLoader} />}
    </Paper>
  );
};

export default Fill_Assessment;

import { useState, useEffect } from 'react';
import Table from '../../components/Table';
import { Grid } from '@mui/material';
import DialogBox from '../../components/DialogBox';
import CustomButton from 'components/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { authPostRequest, getAuthRequest } from 'Api/api';
import Loader from 'components/Loader';
import Text from 'components/Text';
import { style } from 'constant/constants';
import Modal from 'components/Modal';
import AddQuestion from './AddQuestion';
import UpdateQuestion from './UpdateQuestion';
import { columOptions } from 'utils/utils';
import CRUDActions from 'components/CRUDActions';
import { tableOptionFilter } from 'utils/utils';
import SuccessError from 'components/SuccessError';
import DeleteSpan from 'components/DeleteSpan';
import TextEllipsize from 'utils/TextEllipsize';

const Questions = () => {
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [question, setQuestion] = useState();
  const [questions, setQuestions] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);

  const { userData } = useSelector((store) => store.auth);

  //For Open Dialog for delete
  const handleOpenDialogBox = (subCat) => {
    setQuestion(questions[subCat]);
    setOpenDialogBox(true);
  };

  const handleOpenUpdateModal = (rowIndex) => {
    setQuestion(questions[rowIndex]);
    setOpenUpdateModal(true);
  };

  //Open or close delete dialog
  const handleDialogBoxState = () => {
    setOpenDialogBox(!openDialogBox);
  };

  const handleUpdateModalState = () => {
    setOpenUpdateModal(!openUpdateModal);
  };

  const handleAddModalState = () => {
    setOpenAddModal(!openAddModal);
  };

  //confirm for delete
  const handleConfirm = async () => {
    setSuccessMessage([]);
    setErrorMessage([]);
    try {
      const params = {
        userId: userData.userId,
        questionId: question.questionId
      };

      setShowLoader(true);
      setShowAlertBar(false);
      const subCat = await authPostRequest('deleteQuestion', params);
      setShowLoader(false);

      if (subCat.resStatus === 1) {
        setSuccessMessage([subCat.resMessage]);
      } else {
        setErrorMessage([subCat.resMessage]);
      }
      setShowAlertBar(true);

      setOpenDialogBox(!openDialogBox);
    } catch (err) {
      setShowLoader(false);
    }
  };

  const getQuestionInfo = async () => {
    try {
      setShowLoader(true);
      const question = await getAuthRequest('getQuestions');
      setShowLoader(false);

      if (question.resStatus === 1) {
        setQuestions(question.questionsList);
        setShowLoader(false);
      }
    } catch (err) {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getQuestionInfo();
  }, [isFormSaved]);

  const columns = [
    {
      name: 'question',
      label: 'Question',
      options: {
        ...columOptions,
        customBodyRender: (value) => <TextEllipsize value={value} />
      }
    },

    {
      name: 'categoryName',
      label: 'Category',
      options: { ...columOptions, customBodyRender: (value) => <TextEllipsize value={value} /> }
    },

    {
      name: 'subCategoryName',
      label: 'Sub-Category',
      options: { ...columOptions, customBodyRender: (value) => <TextEllipsize value={value} /> }
    },

    {
      name: 'actions',
      label: 'Actions',
      options: {
        ...columOptions,
        filter: false,
        //Actions Delete, Update
        customBodyRender: (value, tableMeta) => {
          return (
            <CRUDActions
              onDelete={() => handleOpenDialogBox(tableMeta.rowIndex)}
              onEdit={() => handleOpenUpdateModal(tableMeta.rowIndex)}
            />
          );
        }
      }
    }
  ];

  return (
    <Grid sx={{ width: '100%' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Text variant="h4" style={{ color: style.textColor }}>
          Questions
        </Text>
        <CustomButton endIcon={<AddIcon />} onClick={() => setOpenAddModal(true)}>
          Add
        </CustomButton>
      </Grid>

      {showAlertBar && (
        <SuccessError
          successMessage={successMessage}
          errorMessage={errorMessage}
          setShowAlertBar={setShowAlertBar}
        />
      )}

      <Table
        data={questions}
        options={tableOptionFilter}
        columns={columns}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      />

      {showLoader && <Loader open={showLoader} />}

      {openDialogBox && (
        <DialogBox
          handleDialogBoxState={handleDialogBoxState}
          openDialogBox={openDialogBox}
          handleConfirmAction={handleConfirm}
          deleteMessage={<DeleteSpan message="question" toBeDel={question.question} />}
        />
      )}

      {openAddModal && (
        <Modal open={openAddModal} setOpen={setOpenAddModal} header="Add Question">
          <AddQuestion
            closeModal={handleAddModalState}
            setIsFormSaved={setIsFormSaved}
            isFormSaved={isFormSaved}
            setSuccess={setSuccessMessage}
            setError={setErrorMessage}
            setAlertBar={setShowAlertBar}
          />
        </Modal>
      )}

      {openUpdateModal && (
        <Modal open={openUpdateModal} setOpen={setOpenUpdateModal} header="Update Question">
          <UpdateQuestion
            ques={question}
            closeModal={handleUpdateModalState}
            setIsFormSaved={setIsFormSaved}
            isFormSaved={isFormSaved}
            setSuccess={setSuccessMessage}
            setError={setErrorMessage}
            setAlertBar={setShowAlertBar}
          />
        </Modal>
      )}
    </Grid>
  );
};
export default Questions;

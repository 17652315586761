import PropTypes from 'prop-types';
import Text from './Text';

const ErrorForSelect = ({ children, ...props }) => (
  <Text {...props} className="text-red-600" style={{ fontSize: 14, marginTop: '2vh' }}>
    {children}
  </Text>
);

ErrorForSelect.propTypes = {
  children: PropTypes.node
};

export default ErrorForSelect;

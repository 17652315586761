import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch } from 'react-redux';
import { loggedOut } from 'reduxStore/authSlice';
import { style } from 'constant/constants';
import { isNotAdmin } from 'utils/utils';
import { useNavigate } from 'react-router-dom';

const styled = { color: style.textColor };

export const ACC_MENU_ITEMS = [
  {
    name: 'Profile',
    icon: <AccountCircleIcon style={styled} />,
    path: '/profile-info/personal-info',
    pathUser: '/profileInfo/personalInfo'
  },
  {
    name: 'Reset Password',
    icon: <LockResetIcon style={styled} />,
    path: '/reset-password/'
  },
  {
    name: 'Logout',
    icon: <Logout style={styled} />,
    path: '/login',
    onClick: true
  }
];

const AccountMenu = ({ openMenu, setOpenMenu }) => {
  const { userData } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //For logged out
  const logoutHandler = () => {
    dispatch(loggedOut());
    navigate('/login');
  };

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <Menu
      id="account-menu"
      open={openMenu}
      onClose={handleClick}
      onClick={handleClick}
      style={{ top: '30px' }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
      {ACC_MENU_ITEMS.map(({ name, icon, path, pathUser, onClick }) => (
        <MenuItem
          component={Link}
          to={
            name === 'Reset Password'
              ? `${path}${userData.userId}`
              : isNotAdmin(userData.roles)
              ? pathUser
              : path
          }
          key={name}
          style={{ fontFamily: style.fontFamily, color: style.textColor }}
          onClick={onClick && logoutHandler}>
          <ListItemIcon>{icon}</ListItemIcon>
          {name}
        </MenuItem>
      ))}
    </Menu>
  );
};

AccountMenu.propTypes = {
  openMenu: PropTypes.bool,
  setOpenMenu: PropTypes.func
};

export default AccountMenu;

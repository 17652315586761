import QuestionForm from './QuestionForm';
import PropTypes from 'prop-types';

const ques = {
  question: '',
  subCategoryName: '',
  categoryName: ''
};

const AddQuestion = ({
  closeModal,
  setIsFormSaved,
  setAlertBar,
  setSuccess,
  isFormSaved,
  setError
}) => (
  <QuestionForm
    buttonText="Save & Add More"
    buttonClose="Save & Close"
    setSuccess={setSuccess}
    setError={setError}
    setAlertBar={setAlertBar}
    isFormSaved={isFormSaved}
    question={ques}
    handleModalState={closeModal}
    setIsFormSaved={setIsFormSaved}
  />
);

AddQuestion.propTypes = {
  closeModal: PropTypes.func,
  setIsFormSaved: PropTypes.func,
  isFormSaved: PropTypes.func,
  setSuccess: PropTypes.func,
  setError: PropTypes.func,
  setAlertBar: PropTypes.func
};

export default AddQuestion;
